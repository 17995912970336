import axios from 'axios'
import {
  DateTimeString,
  NvResponse,
  RequestName,
  RequestURL
} from '../baseModels'
import { createG2Request } from '../createRequest'
import { AuthHeader } from '@toasttab/do-secundo-guest-authentication'
import { authHeaders, otherHeaders } from '../guest/constants'

export type PublicDepositRequest = {
  depositAmount: number
  depositBookableConfigGuid: string
}

export interface CreateReservationParams {
  partySize: number
  dateTime: DateTimeString
  bookingNotes?: string
  specialOccasion?: string
  externalServiceArea: string
  requestedServiceAreaGroups: string[]
  guest: Guest
  deposit: PublicDepositRequest | null
}

export interface Guest {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string // internationalized
}

export interface CreateReservationResponse {
  guid: string
}

export const createReservation = async (
  restaurantGuid: string,
  params: CreateReservationParams
) =>
  axios
    .request<NvResponse<CreateReservationResponse>>(
      createG2Request(
        RequestURL[RequestName.CREATE_RESERVATION],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        params
      )
    )
    .then((response) => response.data)

export const createAuthReservation = async (
  getAuthenticationHeader: () => Promise<AuthHeader | null>,
  restaurantGuid: string,
  params: CreateReservationParams
): Promise<NvResponse<CreateReservationResponse>> => {
  const authHeader = await getAuthenticationHeader()
  return axios
    .request<NvResponse<CreateReservationResponse>>(
      createG2Request(
        RequestURL[RequestName.CREATE_AUTH_RESERVATION],
        {
          ...otherHeaders(restaurantGuid),
          ...authHeaders(authHeader)
        },
        undefined,
        params
      )
    )
    .then((response) => response.data)
}

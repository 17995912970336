import React from 'react'
import { Reservation } from '../../api/bookings/getBooking'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useIsMobile } from '../../hooks/useIsMobile'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { ContentWrapper } from '../ContentWrapper'
import { Footer } from '../Footer'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { TextFooter } from '../TextFooter/TextFooter'
import { ReservationConfirmationCard } from './ReservationConfirmationCard'
import { GuestLoginReminderCard } from '../GuestAccount/GuestLoginReminderCard'
import { LoginModal } from '../GuestAccount/LoginModal'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useShowGuestAccounts } from '../GuestAccount/flags'

type ReservationConfirmationViewProps = {
  booking: Reservation
  restaurant: RestaurantInfo
}

export const ReservationConfirmationView = ({
  booking,
  restaurant
}: ReservationConfirmationViewProps) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant)
  const isMobile = useIsMobile()
  const { isAuthenticated } = useAuth()
  const [bookingPreviousState, setBookingPreviousState] =
    React.useState<Reservation | null>(null)
  const [openLoginModal, setOpenLoginModal] = React.useState(false)
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
  const [openCancelModal, setOpenCancelModal] = React.useState(false)
  const [fromModifyButton, setFromModifyButton] = React.useState(false)
  const [fromCancelButton, setFromCancelButton] = React.useState(false)
  const [openLoginReminderCard, setOpenLoginReminderCard] = React.useState(true)

  React.useEffect(() => {
    if (isAuthenticated) {
      setOpenLoginReminderCard(false)
    }
  }, [isAuthenticated, setOpenLoginReminderCard])

  return (
    <>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          {showGuestAccounts && openLoginReminderCard && (
            <GuestLoginReminderCard
              setOpenLoginReminderCard={setOpenLoginReminderCard}
              setOpenLoginModal={setOpenLoginModal}
            />
          )}
          <ReservationConfirmationCard
            booking={booking}
            restaurant={restaurant}
            bookingPreviousState={bookingPreviousState}
            setBookingPreviousState={setBookingPreviousState}
            setOpenLoginModal={setOpenLoginModal}
            openUpdateModal={openUpdateModal}
            setOpenUpdateModal={setOpenUpdateModal}
            openCancelModal={openCancelModal}
            setOpenCancelModal={setOpenCancelModal}
            setFromModifyButton={setFromModifyButton}
            setFromCancelButton={setFromCancelButton}
          />
          <div className='w-full mt-8'>
            <TextFooter
              restaurant={restaurant}
              booking={booking}
              bookingType={booking.bookingType}
              showLegal
              showDepositPolicy
            />
            <Footer restaurant={restaurant} />
          </div>
        </div>
        {!isMobile && (
          <div className='flex flex-col relative mt-4 lg:w-96 md:w-1/3'>
            <RestaurantLocation
              phone={restaurant.phoneNumber}
              site={restaurant.websiteUrl}
              address={addressWithoutRestaurantName}
            />
          </div>
        )}
        {showGuestAccounts && (
          <LoginModal
            onRequestClose={() => {
              setOpenLoginModal(false)
              setOpenLoginReminderCard(!isAuthenticated)
            }}
            isOpen={openLoginModal}
            setIsOpen={setOpenLoginModal}
            setIsUpdateModalOpen={setOpenUpdateModal}
            setIsCancelModalOpen={setOpenCancelModal}
            booking={booking}
            targetRoute={null}
            fromModifyButton={fromModifyButton}
            fromCancelButton={fromCancelButton}
            setBookingPreviousState={setBookingPreviousState}
            restaurant={restaurant}
          />
        )}
      </ContentWrapper>
    </>
  )
}

import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'

export interface GetRestaurantParams {
  slotTime: string | null
}

export type ServiceAreaGroup = {
  guid: string
  name: string
  enabled: boolean
  serviceAreas: string[]
}

export interface RestaurantInfo {
  guid: string
  name: string
  streetAddress: string
  city: string
  state: string
  country: CountryIsoCode
  latitude: number
  longitude: number
  zipCode: string
  phoneNumber: string
  timezone: string
  websiteUrl: string
  profilePicUrl: string
  primaryCuisine: string | null
  secondaryCuisine: string | null
  email: string | null
  description: string | null
  reservationPolicy: string | null
  waitlistPolicy: string | null
  serviceAreaGroups: ServiceAreaGroup[]
  instagramUrl: string | null
  facebookUrl: string | null
  twitterUrl: string | null
  closeOutHour: number
  onlineReservationsEnabled: boolean
  onlineWaitlistEnabled: boolean
  maxPartySize: number
  minPartySize: number
  waitlistMaxPartySize: number
  waitlistMinPartySize: number
  hours: BookableHours
  readOnlyMenuUrl: string | null
  loyaltyEnabled: boolean
  bookingMinHoursInAdvance: number
  bookingMaxHoursInAdvance: number
  hasFreePlan: boolean
  showWaitlistEstimate: boolean
  allowJoinOnlineNoWait: boolean
  toastRestaurantShortName: string
}

export interface BookableHours {
  sunday: DayHours[]
  monday: DayHours[]
  tuesday: DayHours[]
  wednesday: DayHours[]
  thursday: DayHours[]
  friday: DayHours[]
  saturday: DayHours[]
}

export interface DayHours {
  start: string
  end: string
}

export const getRestaurant = async (
  guid: string,
  params: GetRestaurantParams
) =>
  axios
    .request<NvResponse<RestaurantInfo>>(
      createG2Request<GetRestaurantParams>(
        RequestURL[RequestName.GET_RESTUARANT],
        { 'Toast-Restaurant-External-ID': guid },
        { guid },
        { slotTime: params.slotTime }
      )
    )
    .then((response) => response.data.results[0])

import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Reservation } from '../../api/bookings/getBooking'
import { ReservationUpdateModal } from '../ReservationUpdateModal/ReservationUpdateModal'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

interface ReservationModifyButtonProps {
  booking: Reservation
  restaurant: RestaurantInfo
  setPreviousState: Function
  authedToModify: boolean
  openUpdateModal: boolean
  setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>
  setOpenUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
  setFromModifyButton: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReservationModifyButton = ({
  booking,
  restaurant,
  setPreviousState,
  authedToModify,
  openUpdateModal,
  setOpenLoginModal,
  setOpenUpdateModal,
  setFromModifyButton
}: ReservationModifyButtonProps) => {
  return (
    <>
      {' '}
      <Button
        testId='reservation-modify-button'
        variant='secondary'
        size='lg'
        onClick={() => {
          if (authedToModify) {
            setOpenUpdateModal(true)
            setPreviousState(null)
          } else {
            setOpenLoginModal(true)
            setFromModifyButton(true)
          }
        }}
      >
        Modify reservation
      </Button>
      <ReservationUpdateModal
        booking={booking}
        restaurant={restaurant}
        isVisible={openUpdateModal}
        setIsVisible={setOpenUpdateModal}
        setPreviousState={setPreviousState}
      />
    </>
  )
}

export const getGuestProfileRequest = {
  operationName: 'getGuestProfile',
  query: `
    query getGuestProfile {
      guest {
        id
        contact {
          phone
          email
          firstName
          lastName
        }
      }
    }`,
  variables: {}
}

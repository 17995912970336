import { Reservation } from '../../api/bookings/getBooking'
import { Guest } from '../../api/guest/useGetGuestProfile'
import { PhoneNumber } from '@toasttab/buffet-pui-phone-utilities'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { mapCountry } from '../../utils/mapCountry'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

export interface ReservationDetailEntryViewFormValues {
  firstName: string
  lastName: string
  phoneNumber: PhoneNumber
  email: string
  specialRequests: string
  specialOccasion: string
}

const initialValues: ReservationDetailEntryViewFormValues = {
  firstName: '',
  lastName: '',
  phoneNumber: { countryIsoCode: 'US', countryCode: '', nationalNumber: '' },
  email: '',
  specialRequests: '',
  specialOccasion: 'NONE'
}

export const getInitialValues = (
  isAuthenticated: boolean,
  guest: Guest | undefined,
  booking?: Reservation | null,
  restaurant?: RestaurantInfo
): ReservationDetailEntryViewFormValues => {
  if (booking) {
    const { mainGuest } = booking

    const mainGuestPhone = parsePhoneNumber(mainGuest.phoneNumber ?? '')

    return {
      firstName: mainGuest.firstName,
      lastName: mainGuest.lastName,
      phoneNumber: {
        countryIsoCode: mapCountry(mainGuestPhone?.regionCode ?? 'US'),
        nationalNumber: mainGuestPhone?.number?.national ?? '',
        countryCode: mainGuestPhone?.countryCode?.toString() ?? '1'
      },
      email: mainGuest.email ?? '',
      specialRequests: booking.bookingNotes ?? '',
      specialOccasion: booking.specialOccasion ?? ''
    }
  }

  if (isAuthenticated && guest) {
    const guestPhone = parsePhoneNumber(guest.contact.phone || '')
    return {
      firstName: guest.contact.firstName,
      lastName: guest.contact.lastName,
      phoneNumber: {
        countryIsoCode: mapCountry(guestPhone?.regionCode ?? 'US'),
        nationalNumber: guestPhone?.number?.national ?? '',
        countryCode: guestPhone?.countryCode?.toString() ?? '1'
      },
      email: guest.contact.email,
      specialRequests: '',
      specialOccasion: 'NONE'
    }
  }

  return {
    ...initialValues,
    phoneNumber: {
      countryIsoCode: restaurant?.country ?? 'US',
      countryCode: '',
      nationalNumber: ''
    }
  }
}

export enum ReservationDetailEntryViewMode {
  EDIT = 'Edit',
  ADD = 'Add'
}

import { translations } from './locales/en-US'

export type Translations = typeof translations

export const translationLoader = async (language: string) => {
  const fallback = { translations }
  let local
  if (language !== 'en-US') {
    switch (language) {
      case 'en-CA':
        local = await import('./locales/en-CA')
        break
      default:
        local = undefined
    }
  }
  return { local, fallback }
}

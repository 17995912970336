import React from 'react'
import { Reservation } from '../../api/bookings/getBooking'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import {
  BirthdayCakeIcon,
  CelebrationIcon,
  DateIcon,
  GroupIcon,
  HeartIcon,
  WorkIcon
} from '../ReservationDetailEntryView/icons'
import { NotInterestedIcon } from '@toasttab/buffet-pui-icons'
import { FormikProps } from 'formik'

import { DateTime } from 'luxon'

export interface ReservationUpdateModalProps {
  booking: Reservation
  restaurant: RestaurantInfo
  isVisible: boolean
  setIsVisible: Function
  setPreviousState: Function
}

export interface ReservationUpdateFormProps {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  specialRequests: string
  specialOccasion: string
  bookingNotes: string
  datetime: string
  date: DateTime
  serviceAreaGroupName: string
  serviceAreaGroupGuid: string
  partySize: number
}

export interface SelectedAvailability {
  specialOccasion: string | null
  bookingNotes: string | null
  partySize: number
  datetime: string
  serviceAreaGroupName: string | null
  serviceAreaGroupGuid: string | undefined
}

export interface ModifySubComponentProps {
  formik: FormikProps<ReservationUpdateFormProps>
}

export const specialOccasions = [
  {
    label: 'None',
    value: 'NONE',
    iconLeft: <NotInterestedIcon />
  },
  {
    label: 'Birthday',
    value: 'BIRTHDAY',
    iconLeft: <BirthdayCakeIcon />
  },
  {
    label: 'Anniversary',
    value: 'ANNIVERSARY',
    iconLeft: <DateIcon />
  },
  {
    label: 'Date',
    value: 'DATE',
    iconLeft: <HeartIcon />
  },
  {
    label: 'Business',
    value: 'BUSINESS',
    iconLeft: <WorkIcon />
  },
  {
    label: 'Reunion',
    value: 'REUNION',
    iconLeft: <GroupIcon />
  },
  {
    label: 'Celebration',
    value: 'CELEBRATION',
    iconLeft: <CelebrationIcon />
  }
]

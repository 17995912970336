import React from 'react'
import { Label } from '@toasttab/buffet-pui-dropdowns'
import { SelectField } from '@toasttab/buffet-pui-forms'
import {
  BirthdayCakeIcon,
  CelebrationIcon,
  DateIcon,
  GroupIcon,
  HeartIcon,
  WorkIcon
} from '../ReservationDetailEntryView/icons'

export const SpecialOccasionField = () => {
  return (
    <div className='w-full'>
      <Label>
        Occasion{' '}
        <span className='type-subhead text-gray-75 pr-1 font-normal'>
          (optional)
        </span>
      </Label>
      <div className='h-10 w-full'>
        <SelectField
          aria-label=''
          options={[
            {
              label: 'Birthday',
              value: 'BIRTHDAY',
              iconLeft: <BirthdayCakeIcon />
            },
            {
              label: 'Anniversary',
              value: 'ANNIVERSARY',
              iconLeft: <DateIcon />
            },
            {
              label: 'Date',
              value: 'DATE',
              iconLeft: <HeartIcon />
            },
            {
              label: 'Business',
              value: 'BUSINESS',
              iconLeft: <WorkIcon />
            },
            {
              label: 'Reunion',
              value: 'REUNION',
              iconLeft: <GroupIcon />
            },
            {
              label: 'Celebration',
              value: 'CELEBRATION',
              iconLeft: <CelebrationIcon />
            }
          ]}
          name='specialOccasion'
        />
      </div>
    </div>
  )
}

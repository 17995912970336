import { ModifySubComponentProps } from './models'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { DateTime } from 'luxon'
import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SelectField, CardSelectorGroupField } from '@toasttab/buffet-pui-forms'
import { useScrollAlign } from '../../hooks/useScrollAlign'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import { useIntlProps } from 'banquet-runtime-modules'

interface ModifyTimePickerProps extends ModifySubComponentProps {
  serviceAreaGroupNameToTimes: Map<string, Set<string>>
  isMobile: boolean
  restaurant: RestaurantInfo
  setReservationError: Function
}

export const ModifyTimePicker = ({
  serviceAreaGroupNameToTimes,
  formik,
  isMobile,
  restaurant,
  setReservationError
}: ModifyTimePickerProps) => {
  const FIELD_NAME = 'datetime'

  const [shouldShowMore, setShowMore] = useState(false)
  const { language: locale } = useIntlProps()

  const isValid = (timesSet: Set<string> | undefined) => {
    if (timesSet === undefined) {
      return false
    }
    const reservationTime =
      formik.values.datetime === ''
        ? formik.values.date.toISO()
        : formik.values.datetime
    return [...timesSet]
      .map((dateTimeString) =>
        DateTime.fromISO(dateTimeString).setZone('utc').toISOTime()
      )
      .includes(DateTime.fromISO(reservationTime).setZone('utc').toISOTime())
  }

  const getLatestDateTime = () =>
    formik.values.datetime !== ''
      ? DateTime.fromISO(formik.values.datetime)
      : formik.values.date

  const filterTimes = (
    timesSet: Set<string> | undefined,
    surroundingElementsOfChoice: number = 4
  ) => {
    const times = Array.from(timesSet || [])
    if (times.length <= 0) {
      return []
    }
    if (shouldShowMore) {
      return times
    } else {
      if (times.length <= surroundingElementsOfChoice * 2 + 1) {
        setShowMore(true)
        return times
      }
    }

    const indexOfChoice = times.indexOf(
      getLatestDateTime().setZone('utc').toISO()
    )

    if (indexOfChoice != -1) {
      return times.filter(
        (_, index) =>
          Math.abs(index - indexOfChoice) <= surroundingElementsOfChoice
      )
    }
    const endOfWindowOrLast =
      times.length >= surroundingElementsOfChoice * 2
        ? surroundingElementsOfChoice * 2
        : times.length
    return times.slice(0, endOfWindowOrLast)
  }

  const serviceAreaGroupTimes = serviceAreaGroupNameToTimes.get(
    formik.values.serviceAreaGroupName || ''
  )

  const filteredTimes = useRef(filterTimes(serviceAreaGroupTimes))

  const datetimeContainerId = 'datetime-container'
  const timePickerLabel = 'modifyTimePickerLabel'

  useScrollAlign(
    datetimeContainerId,
    't' + DateTime.fromISO(formik.values.datetime).toMillis()
  )

  useEffect(() => {
    setReservationError(
      isValid(serviceAreaGroupTimes) ? '' : 'originalTimeUnavailable'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAreaGroupTimes, setReservationError])

  return (
    <div className='w-full'>
      {[serviceAreaGroupTimes].map((times, index, arr) => (
        <div key={'serviceAreaGroupName'} className='flex flex-col'>
          {isMobile ? (
            <>
              {times === undefined || times.size === 0 ? (
                <Alert variant='error' className='mb-3'>
                  Times are no longer available based on above selections.
                </Alert>
              ) : (
                <label
                  id={timePickerLabel}
                  className='inline-flex flex-row font-semibold type-default mb-1'
                >
                  Select an available time below
                </label>
              )}
              {times !== undefined &&
                !Array.from(times).includes(
                  getLatestDateTime().setZone('utc').toISO()
                ) && (
                  <Alert variant='error' className='mb-2'>
                    Previous time is no longer available based on above
                    selections, please select a new time.
                  </Alert>
                )}
              <div
                id={datetimeContainerId}
                className={cx(
                  'mt-1 flex md:gap-4 border-b whitespace-nowrap overflow-x-auto no-scrollbar sm:flex-wrap sm:overflow-x-visible sm:whitespace-normal items-center',
                  { 'mb-4 ': index < arr.length - 1 }
                )}
              >
                {times !== undefined && times.size > 0 ? (
                  <>
                    <CardSelectorGroupField
                      aria-labelledby={timePickerLabel}
                      optionsContainerClassName='flex flex-row whitespace-nowrap gap-2'
                      itemsContainerClassName='mt-0 mb-4'
                      name='datetime'
                      options={filteredTimes.current.sort().map((timeSlot) => {
                        // https://www.w3.org/TR/html4/types.html#type-id HTML IDs need to start with a letter 🙄
                        const id =
                          't' + DateTime.fromISO(timeSlot).toMillis().toString()
                        return {
                          value: timeSlot,
                          contents: (
                            <div id={id} key={id}>
                              {DateTime.fromISO(timeSlot, {
                                zone: restaurant.timezone
                              }).toFormat('h:mm a', { locale: locale })}
                            </div>
                          )
                        }
                      })}
                    />
                    {!shouldShowMore && (
                      <Button
                        className='mb-4'
                        variant='link'
                        size='sm'
                        onClick={() => {
                          filteredTimes.current = Array.from(times)
                          setShowMore(true)
                        }}
                      >
                        Show More
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              {times === undefined || times.size === 0 ? (
                <Alert variant='error'>
                  Times are no longer available based on above selections.
                </Alert>
              ) : (
                <>
                  <label
                    id='selectTimeDesktop'
                    className='inline-flex flex-row font-semibold type-default mb-1'
                  >
                    Select an available time below
                  </label>
                  {times !== undefined &&
                    !Array.from(times).includes(
                      getLatestDateTime().setZone('utc').toISO()
                    ) && (
                      <Alert variant='error' className='mb-2'>
                        Previous time is no longer available based on above
                        selections, please select a new time.
                      </Alert>
                    )}
                  <SelectField
                    aria-label=''
                    options={Array.from(times || [])
                      .map((time) => {
                        let newTime = format(
                          new Date(time),
                          Formats.time.short,
                          { locale: locale, timeZone: restaurant.timezone }
                        )
                        return {
                          label: newTime ? newTime : '',
                          value: time
                        }
                      })
                      .sort()}
                    name={FIELD_NAME as string}
                    value={getLatestDateTime().setZone('UTC').toISO()}
                    aria-describedby='selectTimeDesktop'
                  />
                </>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

import React from 'react'
import { BookingType } from '../../api/baseModels'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useIsMobile } from '../../hooks/useIsMobile'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { Accordion } from '../Accordion/Accordion'
import { ContentWrapper } from '../ContentWrapper'
import { Footer } from '../Footer'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { TextFooter } from '../TextFooter/TextFooter'

type PageContentWrapperProps = {
  children: React.ReactNode
  restaurant: RestaurantInfo
}

export const PageContentWrapper = ({
  children,
  restaurant
}: PageContentWrapperProps) => {
  const isMobile = useIsMobile()
  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant)

  return (
    <div className='md:my-4'>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          {children}{' '}
          <TextFooter
            restaurant={restaurant}
            bookingType={BookingType.RESERVATION}
          />
        </div>
        <div className='flex flex-col relative lg:w-96 md:w-1/3'>
          {!isMobile && (
            <Accordion title='Contact' isMobile={isMobile} withoutTitle>
              <RestaurantLocation
                phone={restaurant.phoneNumber}
                site={restaurant.websiteUrl}
                address={addressWithoutRestaurantName}
              />
            </Accordion>
          )}
        </div>
      </ContentWrapper>
      <Footer restaurant={restaurant} />
    </div>
  )
}

import * as Yup from 'yup'
import 'yup-phone'

export const validatePhoneNumber = () =>
  Yup.string()
    .test(
      'country validation',
      'Only US and Canadian phone numbers are accepted at this time.',
      (value, context) => {
        const countryCodes = [
          'US', // 'Murica
          'CA', // Canada
          'PR', // Puerto Rico
          'GU', // Guam
          'VI', // US Virgin Islands
          'AS' // American Samoa
        ]
        const validPhoneNumerSchema = Yup.string().phone('US', false)
        if (!validPhoneNumerSchema.isValidSync(value)) {
          return context.createError({
            message: 'A valid phone number is required'
          })
        }
        for (let i = 0; i < countryCodes.length; i++) {
          const schema = Yup.string().phone(countryCodes[i], true)
          if (schema.isValidSync(value)) {
            return true
          }
        }
        return false
      }
    )
    .required('A phone number is required to create an account')

export const phoneEntryValidationSchema = Yup.object({
  phoneNumber: validatePhoneNumber()
})

export const dataEntryValidationSchema = Yup.object({
  firstName: Yup.string().required(
    'A first name is required to create an account'
  ),
  lastName: Yup.string().required(
    'A last name is required to create an account'
  ),
  email: Yup.string()
    .email()
    .required('A valid email is required to create an account')
})

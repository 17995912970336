import * as yup from 'yup'
import { phoneNumberValidationOverride } from '../../utils/phoneNumberCustomValidation'

export const validationSchema = yup.object({
  partySize: yup.number().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: phoneNumberValidationOverride,
  email: yup.string().email('Please enter a valid email or leave blank.')
})

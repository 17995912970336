import { useMutation } from 'react-query'
import { useBookingContext } from './BookingContext'
import {
  UpdateReservationParams,
  updateAuthReservation
} from './updateReservation'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'

export const useUpdateReservation = (isAuthenticated: boolean) => {
  const { updateReservation } = useBookingContext()
  const { getAuthenticationHeader } = useAuth()

  return useMutation(
    ({
      params,
      bookingId
    }: {
      params: UpdateReservationParams
      bookingId: string
    }) =>
      isAuthenticated
        ? updateAuthReservation(getAuthenticationHeader, bookingId, params)
        : updateReservation(params, bookingId)
  )
}

export const updateGuestContactRequest = (
  firstName: string,
  lastName: string
) => {
  return {
    operationName: 'updateGuestContact',
    query: `
      mutation updateGuestContact($input: UpdateGuestContactInput!) {
        updateGuestContact(input: $input) {
          __typename
          ...on UpdateGuestContactSuccess {
            guest {
              contact {
                phone
                email
                firstName
                lastName
              }
            }
          }
        }
      }`,
    variables: { input: { firstName: firstName, lastName: lastName } }
  }
}

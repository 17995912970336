import { Reservation } from '../../api/bookings/getBooking'

export interface GuestAccountPhoneEntryFormValues {
  phoneNumber: string
}

const phoneEntryInitialValues: GuestAccountPhoneEntryFormValues = {
  phoneNumber: ''
}

export interface GuestAccountDataEntryFormValues {
  firstName: string
  lastName: string
  email: string
}

const dataEntryInitialValues: GuestAccountDataEntryFormValues = {
  firstName: '',
  lastName: '',
  email: ''
}

export const getPhoneEntryInitialValues = (
  booking?: Reservation | null
): GuestAccountPhoneEntryFormValues => {
  if (booking) {
    const { mainGuest } = booking

    return {
      phoneNumber: mainGuest.phoneNumber ?? ''
    }
  }

  return phoneEntryInitialValues
}

export const getDataEntryInitialValues = (
  booking?: Reservation | null
): GuestAccountDataEntryFormValues => {
  if (booking) {
    const { mainGuest } = booking

    return {
      firstName: mainGuest.firstName,
      lastName: mainGuest.lastName,
      email: mainGuest.email ?? ''
    }
  }

  return dataEntryInitialValues
}

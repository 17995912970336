import { useMutation } from 'react-query'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { authHeaders, otherHeaders } from './constants'

export const useLinkGuestProfile = () => {
  const { getAuthenticationHeader } = useAuth()

  const linkGuestProfile = async (
    phoneNumber: string,
    restaurantGuid: string
  ) => {
    const authHeader = await getAuthenticationHeader()
    axios
      .request<NvResponse<{}>>(
        createG2Request(
          RequestURL[RequestName.LINK_GUEST_PROFILE],
          { ...authHeaders(authHeader), ...otherHeaders(restaurantGuid) },
          undefined,
          { phoneNumber }
        )
      )
      .then((response) => response.data)
  }

  return useMutation(
    ({
      phoneNumber,
      restaurantGuid
    }: {
      phoneNumber: string
      restaurantGuid: string
    }) => linkGuestProfile(phoneNumber, restaurantGuid)
  )
}

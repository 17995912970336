import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ReservationTimeSelectionView } from '../ReservationTimeSelectionView/ReservationTimeSelectionView'
import { LoadingView } from '../LoadingView/LoadingView'
import { FindReserveContextProvider } from '../../contexts/find-reserve-context'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { ReservationDetailEntryView } from '../ReservationDetailEntryView/ReservationDetailEntryView'
import { ReservationConfirmationContainer } from '../ReservationConfirmationView/ReservationConfirmationContainer'
import { ReservationDepositContainer } from '../ReservationDepositView/ReservationDepositContainer'
import { WaitlistDetailEntryContainer } from '../WaitlistDetailEntryView/WaitlistDetailEntryContainer'
import { GuestBookingsView } from '../GuestAccount/GuestBookingsView'
import { GuestProfileView } from '../GuestAccount/GuestProfileView'

export interface RouteParams {
  restaurantGuid: string
}

export function AppContainer() {
  const { restaurantGuid } = window.TT_GLOBALS
  const { isError, data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })

  if (!restaurant || isError) {
    return <LoadingView />
  }

  return (
    <FindReserveContextProvider>
      <Routes>
        <Route
          path={`findTime`}
          element={<ReservationTimeSelectionView restaurant={restaurant} />}
        />
        <Route
          path={`:restaurantGuid/reserve`}
          element={<ReservationDetailEntryView restaurant={restaurant} />}
        />
        <Route
          path={`reserve`}
          element={<ReservationDetailEntryView restaurant={restaurant} />}
        />
        <Route
          path={`joinWaitlist`}
          element={
            <WaitlistDetailEntryContainer restaurantGUID={restaurantGuid!} />
          }
        />
        <Route
          path={`:restaurantGuid/bookings/:bookingGuid/`}
          element={<ReservationConfirmationContainer />}
        />
        <Route
          path={`bookings/:bookingGuid/`}
          element={<ReservationConfirmationContainer />}
        />
        <Route
          path={`payDeposit/:bookingGuid/`}
          element={<ReservationDepositContainer restaurant={restaurant} />}
        />
        <Route
          path={`account/profile`}
          element={<GuestProfileView restaurant={restaurant} />}
        />
        <Route
          path={`account/bookings`}
          element={<GuestBookingsView restaurant={restaurant} />}
        />
        {/* <Route path={`account/payments`} /> */}
      </Routes>
    </FindReserveContextProvider>
  )
}

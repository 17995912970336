import axios from 'axios'
import {
  BookingType,
  DateTimeString,
  NvResponse,
  RequestName,
  RequestURL
} from '../baseModels'
import { createG2Request } from '../createRequest'
import { DepositBookableConfigSnapshot, DepositStatus } from '../deposits/model'

export enum BookingStatus {
  W_WAITING = 'W_WAITING',
  W_NOTIFIED = 'W_NOTIFIED',
  W_ARRIVED = 'W_ARRIVED',
  W_SEATED = 'W_SEATED',
  W_CANCELLED = 'W_CANCELLED',
  W_NO_SHOW = 'W_NO_SHOW',
  W_DONE = 'W_DONE',
  W_CHECKED_IN = 'W_CHECKED_IN',
  W_LATE_ARRIVAL = 'W_LATE_ARRIVAL',
  W_PARTIALLY_ARRIVED = 'W_PARTIALLY_ARRIVED',
  W_CONFIRMED = 'W_CONFIRMED',
  R_CREATED = 'R_CREATED',
  R_NOTIFIED = 'R_NOTIFIED',
  R_CONFIRMED = 'R_CONFIRMED',
  R_ARRIVED = 'R_ARRIVED',
  R_SEATED = 'R_SEATED',
  R_CANCELLED = 'R_CANCELLED',
  R_NO_SHOW = 'R_NO_SHOW',
  R_DONE = 'R_DONE',
  R_CHECKED_IN = 'R_CHECKED_IN',
  R_LATE_ARRIVAL = 'R_LATE_ARRIVAL',
  R_PARTIALLY_ARRIVED = 'R_PARTIALLY_ARRIVED'
}

export interface ServiceAreaGroup {
  guid: string
  name: string
}

export interface Reservation {
  guid: string
  mainGuest: Guest
  expectedStartTime: DateTimeString
  partySize: number
  bookingType: BookingType
  bookingStatus: BookingStatus
  bookingNotes: string | null
  requestedServiceAreaGroups: ServiceAreaGroup[]
  serviceAreaGroup: ServiceAreaGroup
  placeInLine: number | null
  specialOccasion: string | null
  depositOrderId: string | null
  depositCheckId: string | null
  depositStatus: DepositStatus | null
  depositAmount: number | null
  depositPaymentExpirationDatetime: string | null
  depositRefundableCancellationDatetime: string | null
  depositBookableConfigSnapshot: DepositBookableConfigSnapshot
  createdByAuthenticatedUser: boolean | null
  externalServiceArea: string | null
}

export interface Guest {
  firstName: string
  lastName: string
  phoneNumber?: string
  email?: string
  guestProfilesGuid?: string
}

export const getBooking = async (
  reservationGuid: string,
  restaurantGuid: string
) =>
  axios
    .request<NvResponse<Reservation>>(
      createG2Request<never>(
        RequestURL[RequestName.GET_BOOKING],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        {
          reservationGuid
        }
      )
    )
    .then((response) => response.data.results[0])

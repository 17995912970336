/* eslint-disable  react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

export const HeartIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.28 23.781A.75.75 0 0 1 3 23.25V21H.75a.75.75 0 0 1-.531-1.281l2.25-2.25a.749.749 0 0 1 .53-.219h2.69l.795-.795c-1.156-1.203-1.992-2.37-2.556-3.48-1.197-2.362-1.162-4.494-.32-6.138C5.139 3.847 9.27 2.714 12 4.85c1.875-1.47 4.416-1.392 6.318-.228L19.938 3 18.22 1.281A.75.75 0 0 1 18.75 0h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.281.531l-1.72-1.72-1.508 1.508c.354.372.66.797.9 1.268.843 1.644.877 3.776-.32 6.138-1.19 2.345-3.592 4.932-7.657 7.62l-.414.273-.414-.273a32.777 32.777 0 0 1-4.028-3.093l-.808.81V21a.75.75 0 0 1-.22.531l-2.25 2.25Zm4.34-7.341A30.628 30.628 0 0 0 12 19.065c3.684-2.499 5.74-4.81 6.733-6.768 1.019-2.01.91-3.63.323-4.778-1.226-2.392-4.63-3.08-6.52-1.141L12 6.93l-.537-.552c-1.889-1.94-5.295-1.251-6.52 1.141-.587 1.15-.695 2.768.323 4.778.485.954 1.223 1.995 2.279 3.098l2.799-2.801a.75.75 0 0 1 1.062 1.062L8.62 16.44Z'
      fill='#666'
    />
  </svg>
)
export const DateIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={26}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.75 3.05a.75.75 0 0 0-1.5 0V4.2H5a2.756 2.756 0 0 0-2.75 2.75v12A2.756 2.756 0 0 0 5 21.7h7.821a17.862 17.862 0 0 1-1.373-1.5H5c-.686 0-1.25-.564-1.25-1.25V11.2h16.5v1.804c.501.013 1.01.12 1.5.335V6.95A2.756 2.756 0 0 0 19 4.2h-2.25V3.05a.75.75 0 0 0-1.5 0V4.2h-6.5V3.05ZM5 5.7h2.25v1.35a.75.75 0 0 0 1.5 0V5.7h6.5v1.35a.75.75 0 0 0 1.5 0V5.7H19c.686 0 1.25.564 1.25 1.25V9.7H3.75V6.95c0-.686.564-1.25 1.25-1.25Z'
      fill='#666'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.003 14.97c1.026-1.202 2.928-1.692 4.468-.642a3.19 3.19 0 0 1 1.365 2.497c.076 2.263-1.925 4.077-4.987 6.854l-.065.058a1.164 1.164 0 0 1-1.569.006l-.058-.053-.035-.032c-3.043-2.764-5.034-4.573-4.953-6.828a3.192 3.192 0 0 1 1.365-2.502c1.54-1.056 3.442-.56 4.469.642Zm-.077 7.76.055-.055c2.615-2.368 4.34-3.934 4.34-5.522 0-1.099-.824-1.923-1.923-1.923-.846 0-1.67.544-1.956 1.297h-1.027c-.291-.753-1.115-1.297-1.962-1.297-1.099 0-1.923.824-1.923 1.923 0 1.588 1.726 3.154 4.34 5.522l.056.055Z'
      fill='#666'
    />
  </svg>
)
export const WorkIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.05 10.9h18M18.95 21h-14c-1.1 0-2-.9-2-2V8.2c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2V19c.1 1.1-.8 2-2 2Zm-6.6-6.6h-.7c-.8 0-1.5-.7-1.5-1.5v-2h3.8v2c-.1.8-.7 1.5-1.6 1.5ZM15.05 3h-6c-.8 0-1.5.7-1.5 1.5v1.7h9V4.4c0-.8-.7-1.4-1.5-1.4Z'
      stroke='#666'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinejoin='round'
    />
  </svg>
)
export const GroupIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.8 10.7c-.9-1.1-2.2-1.8-3.8-1.8-1.5 0-2.8.7-3.7 1.8m2.3 9.8c-.9-1.1-2.2-1.8-3.8-1.8-1.5 0-2.9.7-3.8 1.8m18 0c-.9-1.1-2.2-1.8-3.8-1.8-1.5 0-2.9.7-3.8 1.8m1.4-14.3a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Zm-5.3 9.7a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Zm10.5 0a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z'
      stroke='#666'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const CelebrationIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#a)' stroke='#666'>
      <path
        d='M12.93 10.296a8.255 8.255 0 0 1 1.356-2.706c.499-.662 1.145-1.41.965-2.307-.185-.926-1.205-1.108-2-1.119-.367-.005-.966.082-1.134.465-.22.502.399.922.827.951a1.507 1.507 0 0 0 1.298-.616c.444-.668.5-1.662.232-2.406-.361-1.006-.87-1.816-2.03-1.803-.572.007-1.65.44-1.459 1.182.273 1.055 1.933.826 2.67.577a3.754 3.754 0 0 0 2.338-2.24M13.81 11.413a8.253 8.253 0 0 0 2.516-1.682c.596-.578 1.257-1.312 2.17-1.246.941.069 1.249 1.058 1.359 1.845.05.364.04.97-.32 1.184-.47.28-.965-.281-1.047-.702a1.507 1.507 0 0 1 .45-1.365c.608-.524 1.587-.702 2.358-.53 1.043.233 1.91.637 2.042 1.79.065.568-.232 1.692-.99 1.594-1.081-.14-1.061-1.815-.906-2.576a3.752 3.752 0 0 1 1.931-2.6'
        strokeWidth={0.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m4.825 9.157 1.915 2.818a16.233 16.233 0 0 0 4.682 4.55l2.598 1.66M3.483 13.934l1.124 1.506a15.12 15.12 0 0 0 3.442 3.338l1.18.826M2.097 17.837l.768 1a10.677 10.677 0 0 0 2.31 2.217M18.883 16.445c.81-.811-1.264-4.2-4.633-7.57-3.37-3.369-6.759-5.443-7.57-4.632-.81.81 1.264 4.2 4.633 7.569 3.37 3.37 6.759 5.444 7.57 4.633Z'
        strokeWidth={1.5}
      />
      <path
        d='M18.777 16.55 9.61 19.617l-7.742 2.59a.75.75 0 0 1-.95-.949l2.59-7.742 3.068-9.168'
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v23.158H0z' />
      </clipPath>
    </defs>
  </svg>
)
export const BirthdayCakeIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3 10a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9Z'
      stroke='#666'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m3 12 2.914 2.331c1.187.95 2.9.855 3.975-.22a2.985 2.985 0 0 1 4.222 0v0a2.985 2.985 0 0 0 3.975.22L21 12'
      stroke='#666'
      strokeWidth={2}
      strokeLinecap='round'
    />
    <path
      d='M12 7a2 2 0 0 1-2-2c0-.876.677-1.576 1.273-2.217L12 2l.727.783C13.323 3.424 14 4.124 14 5a2 2 0 0 1-2 2Z'
      stroke='#666'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

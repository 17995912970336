import React from 'react'
import { TimeNotFound } from '../../assets/icons/TimeNotFound'

const DateTimeError = ({ subtext }: { subtext: string }) => (
  <div className='flex flex-col items-center my-4 w-full'>
    <TimeNotFound />
    <span className='text-default font-semibold mt-3'>Sorry!</span>
    <span className='type-subhead text-gray-100 text-center'>{subtext}</span>
  </div>
)

export default DateTimeError

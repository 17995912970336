import { useMutation } from 'react-query'
import { useBookingContext } from './BookingContext'
import {
  CreateReservationParams,
  createAuthReservation
} from './createReservation'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'

export const useCreateReservation = (isAuthenticated: boolean) => {
  const { createReservation } = useBookingContext()
  const { getAuthenticationHeader } = useAuth()

  return useMutation(
    ({
      restaurantGuid,
      params
    }: {
      restaurantGuid: string
      params: CreateReservationParams
    }) =>
      isAuthenticated
        ? createAuthReservation(getAuthenticationHeader, restaurantGuid, params)
        : createReservation(restaurantGuid, params)
  )
}

import * as React from 'react'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { GuestAccountMenu } from '../GuestAccount/GuestAccountMenu'
import { FrameProps } from './Frame'

export function PartialFrame({ children, restaurant }: FrameProps) {
  const [openLoginModal, setOpenLoginModal] = React.useState(false)
  const [targetRoute, setTargetRoute] = React.useState<string | null>(null)

  return (
    <>
      <div className='max-w-6xl mx-auto flex flex-row justify-between md:space-x-2 lg:space-x-3 pl-4 pr-1 py-3.5 md:pl-6 md:pr-3'>
        <div className='flex items-center'>
          <ToastBrand size='xxs' strokeColor='text-brand-50' />
        </div>
        <GuestAccountMenu
          restaurant={restaurant}
          openLoginModal={openLoginModal}
          setOpenLoginModal={setOpenLoginModal}
          targetRoute={targetRoute}
          setTargetRoute={setTargetRoute}
        />
      </div>
      <div className='max-w-6xl mx-auto p-4 md:px-6 bg-[#F8F5F2]'>
        {children}
      </div>
    </>
  )
}

import React, { useState } from 'react'
import classNames from 'classnames'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ForkKnifeIcon } from '@toasttab/buffet-pui-icons'
import { Menu as MenuIcon } from '../../assets/icons/Menu'
import { BookingStatus, Reservation } from '../../api/bookings/getBooking'
import { useCancelBooking } from '../../api/bookings/useCancelBooking'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { queryClient } from '../../App'
import { GetBookingQueryKey } from '../../api/bookings/useGetBooking'
import { getAbsoluteUrl } from '../../utils/getAbsoluteUrl'
import { Link } from 'react-router-dom'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

type CTAButtonsProps = {
  booking: Reservation
  restaurant: RestaurantInfo
}

const ViewMenuButton = ({ restaurant, booking }: CTAButtonsProps) => {
  if (!restaurant.readOnlyMenuUrl) {
    return null
  }

  const menuClass = classNames({
    'w-full lg:w-auto lg:text-base': booking.bookingStatus === 'W_SEATED',
    'order-1': booking.bookingStatus === BookingStatus.R_CANCELLED
  })
  const menuVariant =
    booking.bookingStatus === BookingStatus.R_CANCELLED
      ? 'secondary'
      : 'primary'

  return (
    <Button
      as='a'
      size='lg'
      variant={menuVariant}
      className={menuClass}
      href={getAbsoluteUrl(restaurant.readOnlyMenuUrl)}
      target='_blank'
      rel={
        restaurant.readOnlyMenuUrl.includes('toasttab.com')
          ? undefined
          : 'noopener noreferrer'
      }
    >
      <div className='flex gap-2 place-items-center'>
        <MenuIcon />
        View Menu
      </div>
    </Button>
  )
}

export const CTAButtons = ({ booking, restaurant }: CTAButtonsProps) => {
  const [isCancelling, setIsCancelling] = useState(false)
  const { isAuthenticated } = useAuth()
  const cancelBooking = useCancelBooking(isAuthenticated)
  const { shortUrl } = useRestaurantContext()

  const handleCancel = () => {
    setIsCancelling(true)

    cancelBooking.mutate(
      { bookingId: booking ? booking.guid : '' },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([GetBookingQueryKey])
          window.scrollTo(0, 0)
        }
      }
    )
  }

  const showViewMenuButton =
    restaurant.readOnlyMenuUrl &&
    booking.bookingStatus !== BookingStatus.W_DONE &&
    booking.bookingStatus !== BookingStatus.R_NO_SHOW &&
    booking.bookingStatus !== BookingStatus.R_DONE

  switch (booking.bookingStatus) {
    case BookingStatus.W_CANCELLED:
      return (
        <>
          {showViewMenuButton && (
            <ViewMenuButton restaurant={restaurant} booking={booking} />
          )}
          <Button
            as='a'
            size='lg'
            variant='secondary'
            href={`tel:${restaurant?.phoneNumber}`}
          >
            Contact Us
          </Button>
        </>
      )
    case BookingStatus.R_CANCELLED:
    case BookingStatus.R_NO_SHOW:
    case BookingStatus.R_DONE:
      return (
        <>
          <Button
            as={Link}
            to={`/${shortUrl}/findTime`}
            variant='primary'
            iconLeft={<ForkKnifeIcon accessibility='decorative' />}
            size='lg'
          >
            Make a New Reservation
          </Button>
        </>
      )
    case BookingStatus.W_DONE:
      return (
        <>
          {showViewMenuButton && (
            <ViewMenuButton restaurant={restaurant} booking={booking} />
          )}
          <Button
            as={Link}
            to={`/${shortUrl}/joinWaitlist`}
            variant='primary'
            size='lg'
          >
            Join the waitlist for today
          </Button>
        </>
      )
    case BookingStatus.R_CREATED:
    case BookingStatus.R_CONFIRMED:
    case BookingStatus.R_ARRIVED:
    case BookingStatus.R_CHECKED_IN:
    case BookingStatus.R_LATE_ARRIVAL:
    case BookingStatus.R_PARTIALLY_ARRIVED:
      return (
        <>
          {showViewMenuButton && (
            <ViewMenuButton restaurant={restaurant} booking={booking} />
          )}
        </>
      )
    case BookingStatus.W_WAITING:
    case BookingStatus.W_NOTIFIED:
    case BookingStatus.W_ARRIVED:
    case BookingStatus.W_CHECKED_IN:
    case BookingStatus.W_LATE_ARRIVAL:
    case BookingStatus.W_PARTIALLY_ARRIVED:
    case BookingStatus.W_CONFIRMED:
      return (
        <>
          {showViewMenuButton && (
            <ViewMenuButton restaurant={restaurant} booking={booking} />
          )}
          <Button
            variant='secondary'
            onClick={handleCancel}
            disabled={isCancelling}
            size='lg'
          >
            Leave Waitlist
          </Button>
        </>
      )
    default:
      return showViewMenuButton ? (
        <ViewMenuButton restaurant={restaurant} booking={booking} />
      ) : null
  }
}

import { createTranslationUtilities } from '@toasttab/buffet-pui-translation-utilities'
import { translations } from './locales/en-US'
import { translationLoader } from './translationLoader'

export type AppStrings = typeof translations

const namespace = 'capman-guest-spa'

// Call in module scope to cache loaded translations
// across single spa mount / unmount cycles
export const { useTranslation, TranslationProvider } =
  createTranslationUtilities<AppStrings>({
    spaName: namespace,
    translationLoader
  })

import { useQuery } from 'react-query'
import { useAvailabilitiesContext } from './AvailabilitiesContext'

export const getAvailabilitiesQueryKey = 'availabilities'

export const useGetAvailabilities = (
  restaurantGuid: string,
  startTime: string,
  endTime: string,
  partySize: number,
  enabled: boolean,
  onSettled?: () => void
) => {
  const { getAvailabilities } = useAvailabilitiesContext()

  return useQuery(
    [getAvailabilitiesQueryKey, restaurantGuid, startTime, endTime, partySize],
    () => getAvailabilities(restaurantGuid, startTime, endTime, partySize),
    {
      enabled,
      onSettled
    }
  )
}

import * as yup from 'yup'
import { validationSchema as phoneValidationSchema } from '@toasttab/buffet-pui-phone-input'
import { phone } from 'phone'

const countryCodeValidation = yup
  .string()
  .required('error.required.phone')
  .test('isCountryValid', 'error.match.country_and_phone', function (value) {
    const { countryIsoCode, nationalNumber } = this.parent
    const phoneInfo = buildPhoneInfo(
      `+${value || ''}${nationalNumber || ''}`,
      countryIsoCode
    )
    if (phoneInfo.countryIsoCode && countryIsoCode) {
      return ['US', 'CA'].includes(countryIsoCode)
        ? ['US', 'CA'].includes(phoneInfo.countryIsoCode)
        : phoneInfo.countryIsoCode === countryIsoCode
    } else {
      return true
    }
  })

export const phoneNumberValidationOverride = yup.object().shape(
  Object.entries(phoneValidationSchema.fields).reduce(
    (acc, [key, schema]) => ({
      ...acc,
      [key]: key === 'countryCode' ? countryCodeValidation : schema
    }),
    {}
  )
)

// overrides getPhoneInfo under node_modules/@toasttab/buffet-pui-phone-input/dist/esm/getPhoneInfo.js
const buildPhoneInfo = function (number: string, country?: string) {
  let phoneInfo = phone(number, { country: country })
  if (!phoneInfo.isValid) {
    phoneInfo = phone(number, { validateMobilePrefix: false, country: country })
  }
  return {
    countryCode: phoneInfo.countryCode,
    isValid: phoneInfo.isValid,
    countryIsoCode: phoneInfo.countryIso2,
    nationalNumber: number
  }
}

export const getDepositRefundLegalText = (timeFrame: number) => {
  switch (timeFrame) {
    case -1:
      return 'This deposit is non-refundable.'
    case 0:
      return 'This deposit is refundable until the time of your reservation.'
    default:
      return `Refundable if you cancel up to
          ${timeFrame} hours before your
          reservation time.`
  }
}

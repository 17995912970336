import React from 'react'

type PhoneProps = {
  width?: number
  height?: number
}

export const Phone = ({ width = 24, height = 24 }: PhoneProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.7002 18.45H18.3002M5.7002 5.24999H18.3002M12.7002 11.25L15.8002 8.14999M14.4002 13.55L15.6002 12.35M16.3002 22.05H7.7002C6.6002 22.05 5.7002 21.15 5.7002 20.05V4.04999C5.7002 2.94999 6.6002 2.04999 7.7002 2.04999H16.4002C17.5002 2.04999 18.4002 2.94999 18.4002 4.04999V20.05C18.3002 21.15 17.4002 22.05 16.3002 22.05Z'
      stroke='#666666'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

import { useState } from 'react'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import { GuestAccountLogin } from './GuestAccountLogin'
import { Reservation } from '../../api/bookings/getBooking'
import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

type LoginModalProps = {
  onRequestClose: () => void
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsUpdateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setIsCancelModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  targetRoute: string | null
  booking?: Reservation
  fromModifyButton: boolean
  fromCancelButton: boolean
  setBookingPreviousState?: Function
  restaurant: RestaurantInfo
}

export const LoginModal = ({
  onRequestClose,
  isOpen,
  setIsOpen,
  setIsUpdateModalOpen,
  setIsCancelModalOpen,
  targetRoute,
  booking,
  fromModifyButton,
  fromCancelButton,
  setBookingPreviousState,
  restaurant
}: LoginModalProps) => {
  const [, setLoginStepCount] = useState(1)

  const handleClose = () => {
    setLoginStepCount(1)
    onRequestClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        parentSelector={() =>
          document.getElementById('banquetPortalsContainer')
        }
        overflowBehavior='body'
        onRequestClose={handleClose}
        size='md'
        noHeader
        noFooter
      >
        <ModalHeader></ModalHeader>
        <Modal.Body className='mt-2'>
          <div className='flex flex-col text-default'>
            <GuestAccountLogin
              setLoginStepCount={setLoginStepCount}
              setIsLoginModalOpen={setIsOpen}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
              setIsCancelModalOpen={setIsCancelModalOpen}
              booking={booking}
              targetRoute={targetRoute}
              fromBookingStep={false}
              fromModifyButton={fromModifyButton}
              fromCancelButton={fromCancelButton}
              setBookingPreviousState={setBookingPreviousState}
              restaurant={restaurant}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

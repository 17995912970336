import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-cga',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '7a2920ddbc92446f93f6b1281eb8fbbb@o37442',
    projectId: '6691616'
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'capman-guest-spa'

import { useQuery } from 'react-query'
import { getRestaurant, GetRestaurantParams } from './getRestaurant'

export const useGetRestaurant = (
  guid: string,
  slotTime: GetRestaurantParams
) => {
  return useQuery(['restaurant', guid], () => getRestaurant(guid, slotTime), {
    refetchOnWindowFocus: false
  })
}

import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'

export interface UpdateWaitlistParams {
  bookingNotes?: string
  partySize: number
}

export type UpdateWaitlistResponse = string[]

export const updateWaitlist = (
  params: UpdateWaitlistParams,
  bookingId: string
) =>
  axios
    .request<NvResponse<UpdateWaitlistResponse>>(
      createG2Request<UpdateWaitlistParams>(
        RequestURL[RequestName.UPDATE_WAITLIST],
        undefined,
        { bookingId },
        params
      )
    )
    .then((response) => response.data.results[0])
    .catch(() => bookingId)

import { useQuery } from 'react-query'
import { fetchRestaurantV2 } from '../utilities/api'

export const useFetchRestaurantV2 = (restaurantGuid: string) => {
  return useQuery(
    ['fetchRestaurantV2', restaurantGuid],
    () => fetchRestaurantV2(restaurantGuid),
    {
      refetchOnWindowFocus: false
    }
  )
}

import React from 'react'
import { DepositStrategyType } from '../../api/deposits/model'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'

export const DepositPolicyInfo = () => {
  const formatCurrency = useFormatCurrency()
  const { selectedAvailability } = useFindReserveContext()
  if (!selectedAvailability) {
    return null
  }

  if (selectedAvailability.deposit?.depositPolicy) {
    return (
      <>
        <div className='text-secondary'>
          <NewLineFormatter text={selectedAvailability.deposit.depositPolicy} />
        </div>
        <div className='border-b pt-4 mb-4' />
      </>
    )
  } else if (
    selectedAvailability?.deposit?.strategy?.type ===
    DepositStrategyType.ByPartySize
  ) {
    return (
      <>
        <p className='text-secondary'>
          We require a{' '}
          <span className='font-semibold text-default'>
            {formatCurrency(
              selectedAvailability?.deposit.strategy.amountPerGuest || 0
            )}{' '}
            deposit per person
          </span>{' '}
          for all parties of {selectedAvailability?.partySize} or more for this
          time in {selectedAvailability?.serviceAreaGroupName}.
        </p>
        <div className='border-b pt-4 mb-4' />
      </>
    )
  } else if (
    selectedAvailability?.deposit?.strategy?.type ===
    DepositStrategyType.ByBooking
  ) {
    return (
      <>
        <p className='text-secondary'>
          We require{' '}
          <span className='font-semibold text-default'>
            {formatCurrency(
              selectedAvailability?.deposit.strategy.actualAmount || 0
            )}{' '}
            per booking
          </span>{' '}
          for all parties of {selectedAvailability?.partySize} or more in{' '}
          {selectedAvailability?.serviceAreaGroupName}.
        </p>
        <div className='border-b pt-4 mb-4' />
      </>
    )
  }

  return null
}

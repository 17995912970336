import React from 'react'
import { DateTime } from 'luxon'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { WaitlistDetailEntryView } from './WaitlistDetailEntryView'
import { Frame } from '../Frame/Frame'

export interface WaitlistDetailEntryContainerProps {
  restaurantGUID: string
}

export const WaitlistDetailEntryContainer = ({
  restaurantGUID
}: WaitlistDetailEntryContainerProps) => {
  const { data: restaurant } = useGetRestaurant(restaurantGUID, {
    slotTime: DateTime.now().toISO()
  })

  if (!restaurant) {
    return null
  }

  return (
    <Frame restaurant={restaurant}>
      <WaitlistDetailEntryView restaurant={restaurant} />
    </Frame>
  )
}

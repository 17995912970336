import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  getInitialFormValues,
  ReservationTimeSelectionViewFormValues
} from './models'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { Footer } from '../Footer'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { ReservationTimeSelectionViewForm } from './ReservationTimeSelectionViewForm/ReservationTimeSelectionViewForm'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { ContentWrapper } from '../ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { useGetAvailabilities } from '../../api/availabilities/useGetAvailabilities'
import { DateTime } from 'luxon'
import { DateTimeError } from '../DateTimeError'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { TextFooter } from '../TextFooter/TextFooter'
import { BookingType } from '../../api/baseModels'
import { Frame } from '../Frame/Frame'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

interface ReservationTimeSelectionViewProps {
  restaurant: RestaurantInfo
}

export function ReservationTimeSelectionView({
  restaurant
}: ReservationTimeSelectionViewProps) {
  const [shouldFetchAvailabilities, setShouldFetchAvailabilities] =
    useState(false)
  const { shortUrl } = useRestaurantContext()
  const { timeFinderValues, setTimeFinderValues } = useFindReserveContext()
  const initialValues = getInitialFormValues(timeFinderValues, restaurant)
  const onSubmit = ({
    partySize,
    date,
    time
  }: ReservationTimeSelectionViewFormValues) => {
    setTimeFinderValues!({
      partySize,
      date,
      time
    })
    setShouldFetchAvailabilities(true)
  }
  const formik = useFormik({
    initialValues,
    onSubmit
  })
  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant)
  const navigate = useNavigate()
  const [
    hasInitiallyFetchedAvailabilities,
    setHasInitiallyFetchedAvailabilities
  ] = useState(false)

  const utcDateTime = DateTime.fromISO(timeFinderValues?.time || '').toUTC()
  const startTime = utcDateTime.minus({ hours: 2, minutes: 30 })
  const endTime = utcDateTime.plus({ hours: 2, minutes: 30 })
  const startTimeString = startTime.toString().replace('Z', '+00:00')
  const endTimeString = endTime.toString().replace('Z', '+00:00')

  const {
    data: availabilities,
    isLoading,
    isError
  } = useGetAvailabilities(
    restaurant.guid,
    startTimeString,
    endTimeString,
    timeFinderValues?.partySize!,
    shouldFetchAvailabilities,
    () => {
      setShouldFetchAvailabilities(false)
      setHasInitiallyFetchedAvailabilities(true)
      formik.setSubmitting(false)
    }
  )

  // We're checking availabilities here first, showing any errors
  // and then only redirecting to /reserve once we have availabiities
  useEffect(() => {
    if (
      hasInitiallyFetchedAvailabilities &&
      availabilities?.length &&
      timeFinderValues?.partySize &&
      timeFinderValues.date &&
      timeFinderValues.time
    ) {
      //encoding so that it works for all timezones
      const dateTime = encodeURIComponent(timeFinderValues.time)
      navigate(
        `/${shortUrl}/reserve?partySize=${timeFinderValues.partySize}&dateTime=${dateTime}`
      )
    }
  }, [
    hasInitiallyFetchedAvailabilities,
    navigate,
    shortUrl,
    timeFinderValues?.partySize,
    timeFinderValues?.date,
    timeFinderValues?.time,
    availabilities
  ])

  return (
    <Frame restaurant={restaurant}>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          <div className='flex flex-col gap-4 md:mb-12 md:gap-8'>
            {restaurant.onlineReservationsEnabled ? (
              <div className='flex flex-col gap-4'>
                <h1 className='type-large text-dark-gray font-semibold md:mt-4'>
                  Book a reservation
                </h1>
                <div className='shadow-xl rounded-lg p-4'>
                  <FormikProvider value={formik}>
                    <>
                      <ReservationTimeSelectionViewForm
                        restaurant={restaurant}
                        updateTimeFieldOnMount
                      >
                        {isError ||
                          (availabilities?.length === 0 && (
                            <DateTimeError subtext='No reservations available near this time. Try selecting a different time slot.' />
                          ))}
                        {isLoading && (
                          <div className='h-16 flex justify-center items-center w-full'>
                            <MerryGoRound />
                          </div>
                        )}
                      </ReservationTimeSelectionViewForm>
                    </>
                  </FormikProvider>
                </div>
              </div>
            ) : (
              <div className='type-default text-left font-semibold md:mt-4'>
                {restaurant.name} is not accepting reservations at the moment.{' '}
              </div>
            )}
            <TextFooter
              restaurant={restaurant}
              bookingType={BookingType.RESERVATION}
            />
          </div>
        </div>
        <div className='flex flex-col relative mt-4 md:w-1/3 lg:w-96'>
          <RestaurantLocation
            phone={restaurant.phoneNumber}
            site={restaurant.websiteUrl}
            address={addressWithoutRestaurantName}
          />
        </div>
      </ContentWrapper>
      <Footer restaurant={restaurant} />
    </Frame>
  )
}

import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import { Disclosure } from '@headlessui/react'
import { AddCircleIcon, SubtractCircleIcon } from '@toasttab/buffet-pui-icons'

interface AccordionProps {
  title: null | string
  isMobile?: boolean
  isViewMode?: boolean
  withoutTitle?: boolean
  className?: string | null
  childrenClassName?: string | null
}

export function Accordion({
  title,
  children,
  isMobile,
  className,
  childrenClassName,
  withoutTitle,
  isViewMode
}: PropsWithChildren<AccordionProps>) {
  const renderChildren = (propClass: string) =>
    typeof children === 'string' ? (
      <p className={propClass}>{children}</p>
    ) : (
      children
    )

  if (!isMobile || isViewMode) {
    return (
      <div className={cn('flex flex-col w-full', className)}>
        {!withoutTitle ? (
          <span className='text-default font-semibold w-full border-b border-gray-30 pb-4'>
            {title}
          </span>
        ) : null}
        {renderChildren(
          cn('pt-4 type-default text-secondary', childrenClassName)
        )}
      </div>
    )
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`text-left mt-6 py-4 flex justify-between type-large font-semibold ${
              open ? '' : 'border-b border-gray-30'
            }`}
          >
            <span className='text-default'>{title}</span>
            {open ? (
              <SubtractCircleIcon className='text-primary-75' />
            ) : (
              <AddCircleIcon className='text-primary-75' />
            )}
          </Disclosure.Button>
          <div className={open ? 'border-b border-gray-30 pb-4' : 'none'}>
            <Disclosure.Panel>
              {renderChildren('type-default text-secondary')}
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
}

Accordion.defaultProps = {
  className: null,
  childrenClassName: null,
  isMobile: false,
  withoutTitle: false,
  isViewMode: false
}

import React from 'react'

type InfoProps = {
  width?: number
  height?: number
}

export const Info = ({ width = 16, height = 16 }: InfoProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.03333 14.0333C11.347 14.0333 14.0333 11.347 14.0333 8.03333C14.0333 4.71962 11.347 2.03333 8.03333 2.03333C4.71962 2.03333 2.03333 4.71962 2.03333 8.03333C2.03333 11.347 4.71962 14.0333 8.03333 14.0333Z'
      stroke='#666666'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.03333 7.83337V11.1667'
      stroke='#666666'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.03337 6.16671C8.40156 6.16671 8.70003 5.86823 8.70003 5.50004C8.70003 5.13185 8.40156 4.83337 8.03337 4.83337C7.66518 4.83337 7.3667 5.13185 7.3667 5.50004C7.3667 5.86823 7.66518 6.16671 8.03337 6.16671Z'
      fill='#666666'
    />
  </svg>
)

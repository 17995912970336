import { AxiosRequestConfig } from 'axios'
import {
  HeaderParams,
  NvRequestMetadata,
  PathParams,
  RequestType
} from './baseModels'
import { substituteParams } from './util/substituteParams'

export const createG2Request = <QueryParamsType>(
  request: NvRequestMetadata,
  headerParams?: HeaderParams,
  pathParams?: PathParams,
  queryParams?: QueryParamsType
): AxiosRequestConfig => ({
  method: request.type,
  url: substituteParams(
    `${window.TT_GLOBALS?.bookingApiUrl}${request.url}`,
    pathParams || {}
  ),
  data: request.type !== RequestType.GET ? queryParams : undefined,
  headers: headerParams,
  params: request.type === RequestType.GET ? queryParams : undefined
})

import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { useIntlProps } from 'banquet-runtime-modules'

const isRoundNumber = (amount: number) => amount % 1 === 0

export const useFormatCurrency = () => {
  const { language: locale, currency } = useIntlProps()

  return (amount: number, opts = { trailingZeros: false }) =>
    formatCurrency({ amount, currency }, locale, {
      trailingZeros: opts.trailingZeros || !isRoundNumber(amount)
    })
}

import { FormikProps, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { ReservationTimeSelectionViewFormValues } from '../components/ReservationTimeSelectionView/models'
import { usePartySizes } from './usePartySizes/usePartySizes'
import { SelectOption } from '@toasttab/buffet-pui-select'

export interface TimeFinderFormPropsConfig {
  restaurant: RestaurantInfo
  onDatePickerValueFn?: (val: DateTime | null) => DateTime | null
}

export interface TimeFinderFormConfigValues {
  formProps: FormikProps<ReservationTimeSelectionViewFormValues>
  partySizes: SelectOption[]
  minDate: DateTime
  onDatePickerValue: (newDate: DateTime | null) => void
  handleDateChange: (
    date: Date | undefined,
    setFieldValue: (name: string, value: DateTime | string) => void
  ) => void
}

export const useTimeFinderForm = ({
  restaurant,
  onDatePickerValueFn
}: TimeFinderFormPropsConfig): TimeFinderFormConfigValues => {
  const formProps = useFormikContext<ReservationTimeSelectionViewFormValues>()
  const partySizes = usePartySizes(restaurant)
  const minDate = DateTime.now()

  let onDatePickerValue: (val: DateTime | null) => void

  if (onDatePickerValueFn) {
    onDatePickerValue = (newValue) =>
      formProps.setFieldValue('date', onDatePickerValueFn(newValue))
  } else {
    onDatePickerValue = (newValue) => formProps.setFieldValue('date', newValue)
  }

  const handleDateChange = (
    date: Date | undefined,
    setFieldValue: (name: string, value: DateTime | string) => void
  ) => {
    if (date) {
      const luxonDate = DateTime.fromJSDate(date)
      setFieldValue('date', luxonDate)
    }
  }

  return {
    formProps,
    partySizes,
    minDate,
    onDatePickerValue,
    handleDateChange
  }
}

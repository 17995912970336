import React from 'react'

export const TableReady = () => (
  <svg
    width='251'
    height='117'
    viewBox='0 0 251 117'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M68.3759 29.1309H23.8281' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M68.3759 21.7344H23.8281' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M116.852 92.9148V74.8984' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M135.799 93.5469V74.8984' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path
      d='M34.6632 6.94202H27.3125'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M30.987 21.6084V14.8444'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M34.6641 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M27.3125 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M30.9878 14.6546C28.9601 14.6546 27.3125 13.0742 27.3125 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M30.987 14.6546C33.0148 14.6546 34.6623 13.0742 34.6623 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M49.5547 6.94202H42.1406'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M45.8151 21.6084V14.8444'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M49.5547 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M42.1406 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M45.816 14.6546C43.7882 14.6546 42.1406 13.0742 42.1406 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M45.8151 14.6546C47.8429 14.6546 49.4905 13.0742 49.4905 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M64.3833 6.94202H57.0326'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M60.707 21.6084V14.8444'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M64.3815 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M57.0326 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M60.7079 14.6546C58.6801 14.6546 57.0326 13.0742 57.0326 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M60.707 14.6546C62.7348 14.6546 64.3824 13.0742 64.3824 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M115.076 2.26428H107.725'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M111.402 16.93V10.166'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M115.077 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M107.725 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M111.401 9.97684C109.373 9.97684 107.725 8.39646 107.725 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M111.402 9.97684C113.43 9.97684 115.078 8.39646 115.078 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M129.968 2.26428H122.617'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M126.23 16.93V10.166'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M129.969 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M122.555 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M126.23 9.97684C124.202 9.97684 122.555 8.39646 122.555 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M126.23 9.97684C128.258 9.97684 129.906 8.39646 129.906 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M209.877 6.94202H202.526'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M206.201 21.6084V14.8444'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M209.875 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M202.526 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M206.201 14.6546C204.174 14.6546 202.526 13.0742 202.526 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M206.201 14.6546C208.228 14.6546 209.876 13.0742 209.876 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M144.796 2.26428H137.445'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M141.122 16.93V10.166'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M144.797 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M137.445 6.31006V2.26428'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M141.121 9.97684C139.093 9.97684 137.445 8.39646 137.445 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M141.122 9.97684C143.15 9.97684 144.798 8.39646 144.798 6.5'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M224.704 6.94202H217.353'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M221.027 21.6084V14.8444'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M224.704 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M217.353 10.9878V6.94202'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
    />
    <path
      d='M221.028 14.6546C219 14.6546 217.353 13.0742 217.353 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M221.027 14.6546C223.055 14.6546 224.703 13.0742 224.703 11.1777'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M68.375 29.1306V21.7344'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='square'
    />
    <path d='M148.283 24.4531H103.736' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M228.507 29.1309H198.598' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M148.283 17.0566H103.736' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path d='M228.507 21.7344H198.598' stroke='#E5E5E5' strokeWidth='2.66667' />
    <path
      d='M148.283 24.4528V17.0566'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='square'
    />
    <path
      d='M103.797 24.4528V17.0566'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='square'
    />
    <path
      d='M198.533 29.1306V21.7344'
      stroke='#E5E5E5'
      strokeWidth='2.66667'
      strokeLinecap='square'
    />
    <path
      d='M62.1645 115.735V104.989M33.7122 115.735V104.989V115.735Z'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M84.6615 104.989H24.2083V56.5664C32.066 56.5664 38.1493 62.9511 38.1493 70.7898'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M52.7231 85.0128C44.6754 85.0128 38.1484 78.5016 38.1484 70.4733'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M76.7387 85.0128C68.691 85.0128 62.1641 78.5016 62.1641 70.4733'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M62.1658 70.4738C62.1658 62.8247 56.1458 56.5664 48.3516 56.5664H24.2083'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M52.724 85.0129C60.5816 85.0129 66.9818 91.3977 66.9818 99.2364V105.558'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M218.304 104.989V115.735M189.852 104.989V115.735'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M213.869 70.4733C213.869 78.5016 207.342 85.0128 199.294 85.0128'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M189.852 70.4733C189.852 78.5016 183.325 85.0128 175.277 85.0128'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M227.809 56.5664H203.666C195.872 56.5664 189.852 62.7615 189.852 70.4738'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M213.869 70.7898C213.869 62.9511 219.952 56.5664 227.81 56.5664V104.926H167.357'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M185.1 105.495V99.1733C185.1 91.3346 191.5 84.9498 199.358 84.9498'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M76.1068 85.0129C84.1545 85.0129 90.6814 92.8516 90.6814 100.817'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      d='M161.083 100.754C161.083 92.7885 167.61 84.9498 175.658 84.9498'
      stroke='#C0C0C0'
      strokeWidth='2.66667'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M106.839 54.9168H110.766V47.1471H113.433V54.9168H117.421V55.934H118.184V74.1972H134.464V55.934H135.228V54.9168H139.217V47.1471H141.884V54.9168H145.874V55.934H146.637V74.1972H151.88C151.29 73.359 150.94 72.3552 150.94 71.2324V66.0488V64.7154H152.273H153.607H171.284L179.454 64.7155H180.726H182.121V66.0488V71.2324C182.121 72.3552 181.771 73.359 181.182 74.1972H197.583C198.32 74.1972 198.917 74.7942 198.917 75.5305C198.917 76.2669 198.32 76.8639 197.583 76.8639H175.805C175.776 76.8642 175.747 76.8644 175.718 76.8644H174.385V76.8639H158.676V76.8644H157.343C157.328 76.8644 157.313 76.8643 157.298 76.8643C157.285 76.8642 157.271 76.864 157.257 76.8639H127.372V85.2362L164.568 114.498C165.147 114.953 165.247 115.791 164.792 116.37C164.337 116.949 163.498 117.049 162.92 116.594L127.372 88.6291V115.735H124.706V88.5794L90.1256 116.582C89.5533 117.045 88.7137 116.957 88.2503 116.385C87.7869 115.813 87.8751 114.973 88.4474 114.51L124.706 85.148V76.8639H94.8863C94.8577 76.8642 94.8291 76.8644 94.8005 76.8644H93.4672V76.8639H77.8208V76.8644H76.4874C76.4588 76.8644 76.4302 76.8642 76.4016 76.8639H54.4349C53.6985 76.8639 53.1016 76.2669 53.1016 75.5305C53.1016 74.7942 53.6985 74.1972 54.4349 74.1972H71.0241C70.4344 73.359 70.0846 72.3552 70.0846 71.2324V66.0488V64.7154H71.418H72.7513H90.3653H90.4284H98.5366H99.8705H101.203V66.0488V71.2324C101.203 72.3552 100.854 73.359 100.264 74.1972H106.013V55.934H106.839V54.9168ZM108.679 74.1972H115.517V57.5834H108.679V74.1972ZM94.8733 74.1972C97.1062 74.1655 98.5366 72.678 98.5366 71.2324V67.3821H90.4284H90.3653H72.7513V71.2324C72.7513 72.678 74.1817 74.1655 76.4147 74.1972H94.8733ZM143.971 74.1972H137.131V57.5834H143.971V74.1972ZM175.792 74.1972H157.27C155.037 74.1655 153.607 72.678 153.607 71.2324V67.3821L171.221 67.3821H179.454V71.2324C179.454 72.6778 178.024 74.1652 175.792 74.1972Z'
      fill='#FF4C00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M106.839 54.9168H110.766V47.1471H113.433V54.9168H117.421V55.934H118.184V74.1972H134.464V55.934H135.228V54.9168H139.217V47.1471H141.884V54.9168H145.874V55.934H146.637V74.1972H151.88C151.29 73.359 150.94 72.3552 150.94 71.2324V66.0488V64.7154H152.273H153.607H171.284L179.454 64.7155H180.726H182.121V66.0488V71.2324C182.121 72.3552 181.771 73.359 181.182 74.1972H197.583C198.32 74.1972 198.917 74.7942 198.917 75.5305C198.917 76.2669 198.32 76.8639 197.583 76.8639H175.805C175.776 76.8642 175.747 76.8644 175.718 76.8644H174.385V76.8639H158.676V76.8644H157.343C157.328 76.8644 157.313 76.8643 157.298 76.8643C157.285 76.8642 157.271 76.864 157.257 76.8639H127.372V85.2362L164.568 114.498C165.147 114.953 165.247 115.791 164.792 116.37C164.337 116.949 163.498 117.049 162.92 116.594L127.372 88.6291V115.735H124.706V88.5794L90.1256 116.582C89.5533 117.045 88.7137 116.957 88.2503 116.385C87.7869 115.813 87.8751 114.973 88.4474 114.51L124.706 85.148V76.8639H94.8863C94.8577 76.8642 94.8291 76.8644 94.8005 76.8644H93.4672V76.8639H77.8208V76.8644H76.4874C76.4588 76.8644 76.4302 76.8642 76.4016 76.8639H54.4349C53.6985 76.8639 53.1016 76.2669 53.1016 75.5305C53.1016 74.7942 53.6985 74.1972 54.4349 74.1972H71.0241C70.4344 73.359 70.0846 72.3552 70.0846 71.2324V66.0488V64.7154H71.418H72.7513H90.3653H90.4284H98.5366H99.8705H101.203V66.0488V71.2324C101.203 72.3552 100.854 73.359 100.264 74.1972H106.013V55.934H106.839V54.9168ZM108.679 74.1972H115.517V57.5834H108.679V74.1972ZM94.8733 74.1972C97.1062 74.1655 98.5366 72.678 98.5366 71.2324V67.3821H90.4284H90.3653H72.7513V71.2324C72.7513 72.678 74.1817 74.1655 76.4147 74.1972H94.8733ZM143.971 74.1972H137.131V57.5834H143.971V74.1972ZM175.792 74.1972H157.27C155.037 74.1655 153.607 72.678 153.607 71.2324V67.3821L171.221 67.3821H179.454V71.2324C179.454 72.6778 178.024 74.1652 175.792 74.1972Z'
      fill='url(#paint0_linear_2003_49881)'
    />
    <path d='M0.5 115.736H250.5' stroke='#C0C0C0' strokeWidth='2.66667' />
    <defs>
      <linearGradient
        id='paint0_linear_2003_49881'
        x1='62.4613'
        y1='74.1972'
        x2='235.851'
        y2='116.622'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset='1' stopColor='#2B4FB9' />
      </linearGradient>
    </defs>
  </svg>
)

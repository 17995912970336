import axios from 'axios'
import {
  DateTimeString,
  NvResponse,
  RequestName,
  RequestURL
} from '../baseModels'
import { createG2Request } from '../createRequest'
import { AuthHeader } from '@toasttab/do-secundo-guest-authentication'
import { authHeaders, otherHeaders } from '../guest/constants'

export interface UpdateReservationParams {
  restaurantGuid: string
  partySize: number
  expectedStartTime: DateTimeString
  bookingNotes?: string
  specialOccasion?: string
  externalServiceArea: string
  requestedServiceAreaGroups: string[]
}

export interface UpdateReservationResponse {
  guid: string
}

export const updateReservation = (
  params: UpdateReservationParams,
  bookingId: string
) =>
  axios
    .request<NvResponse<UpdateReservationResponse>>(
      createG2Request<UpdateReservationParams>(
        RequestURL[RequestName.UPDATE_RESERVATION],
        { 'Toast-Restaurant-External-ID': params.restaurantGuid },
        { bookingId },
        params
      )
    )
    .then((response) => response.data)

export const updateAuthReservation = async (
  getAuthenticationHeader: () => Promise<AuthHeader | null>,
  bookingId: string,
  params: UpdateReservationParams
): Promise<NvResponse<UpdateReservationResponse>> => {
  const authHeader = await getAuthenticationHeader()
  return axios
    .request<NvResponse<UpdateReservationResponse>>(
      createG2Request(
        RequestURL[RequestName.UPDATE_AUTH_RESERVATION],
        {
          ...otherHeaders(params.restaurantGuid),
          ...authHeaders(authHeader)
        },
        { bookingId },
        params
      )
    )
    .then((response) => response.data)
}

import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { AuthHeader } from '@toasttab/do-secundo-guest-authentication'
import { authHeaders, otherHeaders } from '../guest/constants'

export const cancelBooking = async (
  bookingId: string,
  restaurantGuid: string
) =>
  axios
    .request<NvResponse<{}>>(
      createG2Request(
        RequestURL[RequestName.CANCEL_BOOKING],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        {
          bookingId
        }
      )
    )
    .then((response) => response.data)

export const cancelAuthReservation = async (
  getAuthenticationHeader: () => Promise<AuthHeader | null>,
  bookingId: string,
  restaurantGuid: string
) => {
  const authHeader = await getAuthenticationHeader()
  return axios
    .request<NvResponse<{}>>(
      createG2Request(
        RequestURL[RequestName.CANCEL_AUTH_BOOKING],
        {
          ...otherHeaders(restaurantGuid),
          ...authHeaders(authHeader)
        },
        { bookingId },
        undefined
      )
    )
    .then((response) => response.data)
}

import { RestaurantInfo } from '../api/restaurant/getRestaurant'

export const getCompiledGMAdress = ({
  streetAddress,
  city,
  state,
  zipCode
}: RestaurantInfo) => {
  const addressWithoutRestaurantName = `${streetAddress}, ${city}, ${state} ${zipCode}`
  return { addressWithoutRestaurantName }
}

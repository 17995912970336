import classNames from 'classnames'
import React, { useLayoutEffect, useRef, useState } from 'react'

/** An animated tray that displays legal info on focus of a form input. */
const LegalTray = ({
  children,
  isVisible
}: {
  children: React.ReactNode
  isVisible: boolean
}) => {
  const elemRef = useRef<HTMLDivElement>(null)
  const [initialHeight, setInitialHeight] = useState(0)

  useLayoutEffect(() => {
    // get the initial height of the tray element based on its content
    if (elemRef.current) {
      const { height } = elemRef.current.getBoundingClientRect()
      setInitialHeight(height)
    }
  }, [])

  const classes = classNames({
    'text-color-secondary text-xs leading-relaxed overflow-hidden': true,
    'h-0': initialHeight
  })

  // adding a transition delay to opacity when we hide the tray
  // so it doesn't hide before the height animation finishes
  const transition = isVisible
    ? 'height .35s, opacity 0s 0s'
    : 'height .35s, opacity 0s .35s'

  const style = {
    transition,
    opacity: isVisible ? '100%' : 0,
    height: isVisible ? `${initialHeight}px` : undefined
  }

  return (
    <div className={classes} ref={elemRef} style={style}>
      <p className='pt-2'>{children}</p>
    </div>
  )
}

export default LegalTray

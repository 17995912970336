import * as React from 'react'
import { useNavigate } from 'react-router'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  CheckCircleSelectedIcon,
  EditIcon,
  LockLockedIcon
} from '@toasttab/buffet-pui-icons'
import { Suffix } from '@toasttab/buffet-pui-text-base'
import { useUpdateGuestContact } from '../../api/guest/useUpdateGuestContact'
import { PartialFrame } from '../Frame/PartialFrame'
import { useLinkGuestProfile } from '../../api/guest/useLinkGuestProfile'
import { useAuthOnMount } from '../../api/guest/constants'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useShowGuestAccounts } from './flags'
import { parsePhoneNumber } from '../../utils/parsePhoneNumber'
import { GuestProfileSkeletonView } from './GuestProfileSkeletonView'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

export const GuestProfileView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const navigate = useNavigate()
  const { isAuthenticatedConditional, guestData, isLoading } =
    useAuthOnMount(showGuestAccounts)
  const updateGuestContact = useUpdateGuestContact()
  const linkGuestProfile = useLinkGuestProfile()
  const { showErrorSnackBar } = useSnackBar()
  const { shortUrl } = useRestaurantContext()

  const redirect = React.useCallback(() => {
    const findTimeUrl = `/${shortUrl}/findTime`
    navigate(findTimeUrl, { replace: true })
  }, [navigate, shortUrl])

  React.useEffect(() => {
    if (!isLoading && !isAuthenticatedConditional) {
      redirect()
    }
  }, [isAuthenticatedConditional, isLoading, redirect])

  const [firstNameEditable, setFirstNameEditable] = React.useState(false)
  const [firstNameEntry, setFirstNameEntry] = React.useState('')
  const [firstNameSaved, setFirstNameSaved] = React.useState('')
  const [lastNameEditable, setLastNameEditable] = React.useState(false)
  const [lastNameEntry, setLastNameEntry] = React.useState('')
  const [lastNameSaved, setLastNameSaved] = React.useState('')

  React.useEffect(() => {
    if (guestData) {
      setFirstNameEntry(guestData.contact.firstName)
      setLastNameEntry(guestData.contact.lastName)
      setFirstNameSaved(guestData.contact.firstName)
      setLastNameSaved(guestData.contact.lastName)
    }
  }, [guestData])

  if (!guestData || isLoading) {
    return <GuestProfileSkeletonView restaurant={restaurant} />
  }

  const { phone, email } = guestData.contact

  return (
    <PartialFrame restaurant={restaurant}>
      <h1 className='type-large font-medium'>Account Info</h1>
      <div className='w-full gap-6 my-4'>
        <div className='w-full flex flex-col rounded-[12px] font-medium bg-white pl-4 pt-4 pr-2 pb-2 my-4'>
          <div className='flex items-center justify-start font-normal type-overline text-secondary truncate'>
            First Name
          </div>
          <div className='w-full'>
            {!firstNameEditable ? (
              <div className='flex items-center justify-between'>
                {firstNameEntry}
                <IconButton
                  testId='edit-account-first-name-button'
                  icon={<EditIcon aria-label='Edit' />}
                  iconColor='secondary'
                  onClick={() => {
                    setFirstNameEditable(true)
                    setLastNameEditable(false)
                    setLastNameEntry(lastNameSaved)
                  }}
                ></IconButton>
              </div>
            ) : (
              <TextInput
                suffix={
                  <IconButton
                    testId='save-account-first-name-button'
                    icon={<CheckCircleSelectedIcon aria-label='Confirm' />}
                    iconColor='secondary'
                    size='sm'
                    onClick={() => {
                      updateGuestContact.mutate(
                        {
                          firstName: firstNameEntry,
                          lastName: lastNameSaved
                        },
                        {
                          onSuccess: () => {
                            linkGuestProfile.mutate({
                              phoneNumber: phone || '',
                              restaurantGuid: restaurant.guid
                            })
                          },
                          onError: () => {
                            showErrorSnackBar(
                              'Something went wrong, please try again.'
                            )
                          },
                          onSettled: () => {
                            setFirstNameEditable(false)
                            setFirstNameSaved(firstNameEntry)
                          }
                        }
                      )
                    }}
                  />
                }
                suffixVariant={Suffix.Variant.iconButton}
                value={firstNameEntry}
                disabled={!firstNameEditable}
                onChange={(e) => {
                  setFirstNameEntry(e.target.value)
                }}
              />
            )}
          </div>
        </div>
        <div className='w-full flex flex-col rounded-[12px] font-medium bg-white pl-4 pt-4 pr-2 pb-2 my-4'>
          <div className='flex items-center justify-start font-normal type-overline text-secondary truncate'>
            Last Name
          </div>
          <div className='w-full'>
            {!lastNameEditable ? (
              <div className='flex items-center justify-between'>
                {lastNameEntry}
                <IconButton
                  testId='edit-account-last-name-button'
                  icon={<EditIcon aria-label='Edit' />}
                  iconColor='secondary'
                  onClick={() => {
                    setLastNameEditable(true)
                    setFirstNameEditable(false)
                    setFirstNameEntry(firstNameSaved)
                  }}
                ></IconButton>
              </div>
            ) : (
              <TextInput
                suffix={
                  <IconButton
                    testId='save-account-last-name-button'
                    icon={<CheckCircleSelectedIcon aria-label='Confirm' />}
                    iconColor='secondary'
                    size='sm'
                    onClick={() => {
                      updateGuestContact.mutate(
                        {
                          firstName: firstNameSaved,
                          lastName: lastNameEntry
                        },
                        {
                          onSuccess: () => {
                            linkGuestProfile.mutate({
                              phoneNumber: phone || '',
                              restaurantGuid: restaurant.guid
                            })
                          },
                          onError: () => {
                            showErrorSnackBar(
                              'Something went wrong, please try again.'
                            )
                          },
                          onSettled: () => {
                            setFirstNameEditable(false)
                            setFirstNameSaved(firstNameEntry)
                          }
                        }
                      )
                    }}
                  />
                }
                suffixVariant={Suffix.Variant.iconButton}
                value={lastNameEntry}
                disabled={!lastNameEditable}
                onChange={(e) => {
                  setLastNameEntry(e.target.value)
                }}
              />
            )}
          </div>
        </div>
        <div className='w-full flex flex-col rounded-[12px] font-medium bg-[#F8F5F2] p-4 my-4 border border-gray-500'>
          <div className='flex items-center justify-start font-normal type-overline text-secondary truncate pb-2'>
            Phone
          </div>
          <div className='w-full'>
            <div className='flex items-center justify-between'>
              {formatPhone(
                parsePhoneNumber(phone),
                restaurant.country,
                'international'
              )}
              <LockLockedIcon accessibility='decorative' />
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col rounded-[12px] font-medium bg-[#F8F5F2] p-4 my-4 border border-gray-500'>
          <div className='flex items-center justify-start font-normal type-overline text-secondary truncate pb-2'>
            Email
          </div>
          <div className='w-full'>
            <div className='flex items-center justify-between'>
              {email}
              <LockLockedIcon accessibility='decorative' />
            </div>
          </div>
        </div>
      </div>
    </PartialFrame>
  )
}

import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { queryClient } from '../../App'
import { getAvailabilitiesQueryKey } from '../../api/availabilities/useGetAvailabilities'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Countdown } from '../Countdown/Countdown'
import { useFormikContext } from 'formik'
import { ReservationDetailEntryViewFormValues } from '../ReservationDetailEntryView/models'
import { AutorenewIcon, ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { formId } from './ReservationBookModal'
import { useCancelBooking } from '../../api/bookings/useCancelBooking'
import { useNavigate } from 'react-router'
import { useCreditCardForm } from '../CreditCardForm/CreditCardFormProvider'
import { useGetBooking } from '../../api/bookings/useGetBooking'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { getDepositRefundLegalText } from '../../utils/getDepositRefundLegalText'
import { BookingCreationLegalText } from '../BookingCreationLegalText'
import { useShowGuestAccounts } from '../GuestAccount/flags'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

const buttonText = 'Complete booking'

type ReservationBookModalFooterProps = {
  reservationError: string | null
  restaurant: RestaurantInfo
  onRequestClose: () => void
  stepCount: number
  setStepCount: React.Dispatch<React.SetStateAction<number>>
  bookingGuid: string
  createReservationLoading: boolean
  setDepositReservationCreated: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReservationBookModalFooter = ({
  reservationError,
  restaurant,
  onRequestClose,
  stepCount,
  setStepCount,
  bookingGuid,
  createReservationLoading,
  setDepositReservationCreated
}: ReservationBookModalFooterProps) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const formik = useFormikContext<ReservationDetailEntryViewFormValues>()
  const navigate = useNavigate()
  const { selectedAvailability } = useFindReserveContext()
  const { shortUrl } = useRestaurantContext()
  const { isAuthenticated } = useAuth()
  const cancelBooking = useCancelBooking(
    showGuestAccounts ? isAuthenticated : false
  )
  const { paymentLoading, ccData } = useCreditCardForm()
  const { data: booking, isLoading } = useGetBooking(bookingGuid)

  const completeStepCount = showGuestAccounts ? 3 : 2
  const isCompleteBookingButtonDisabled =
    formik.isSubmitting ||
    !((formik.isValid && formik.dirty) || isAuthenticated)

  if (!booking && stepCount === completeStepCount) {
    return <MerryGoRound />
  }

  return (
    <>
      {reservationError ? (
        <Button
          size='lg'
          className='w-full'
          onClick={() => {
            queryClient.invalidateQueries([getAvailabilitiesQueryKey])
            onRequestClose()
          }}
        >
          Try again
        </Button>
      ) : selectedAvailability?.deposit && stepCount !== completeStepCount ? (
        // Deposit bookings - Step 1
        <div className='flex flex-col gap-2 w-full'>
          <SubmitButton
            form={formId}
            size='base'
            className='w-full'
            disabled={
              formik.isSubmitting ||
              !((formik.isValid && formik.dirty) || isAuthenticated) ||
              isLoading
            }
            iconLeft={
              createReservationLoading ? (
                <AutorenewIcon
                  className='animate-spin'
                  accessibility='decorative'
                />
              ) : null
            }
          >
            Continue to payment
          </SubmitButton>
        </div>
      ) : booking?.depositPaymentExpirationDatetime &&
        stepCount === completeStepCount ? (
        // Deposit bookings - Step 2
        <div className='flex flex-col gap-2 w-full text-center'>
          <p className='text-secondary'>
            Holding reservation for{' '}
            <Countdown
              dateInUtcString={booking.depositPaymentExpirationDatetime}
              restaurantGuid={restaurant.guid}
              onExpiration={() => {
                // when the timer expires, cancel the booking
                // regardless of if the cancellation succeeds
                // redirect to confirmation page with expired banner
                cancelBooking.mutate(
                  { bookingId: booking.guid },
                  {
                    onSettled: () => {
                      const currentQueryParams = new URLSearchParams(
                        window.location.search
                      )
                      currentQueryParams.set('expired', 'true')
                      const queryString = currentQueryParams.toString()
                      const redirectLink = `/${shortUrl}/reserve?${queryString}`

                      navigate(redirectLink)
                      onRequestClose()
                    }
                  }
                )
              }}
            />
          </p>
          {ccData &&
            booking.depositBookableConfigSnapshot
              ?.cancellationRefundableTimeframe != null && (
              <p className='text-secondary type-subhead text-left'>
                By clicking &ldquo;{buttonText},&rdquo; you authorize us to
                charge you for the Amount Due on the above date and apply it to
                your bill, with any balance greater than your bill returned to
                you.{' '}
                {getDepositRefundLegalText(
                  booking.depositBookableConfigSnapshot
                    ?.cancellationRefundableTimeframe
                )}
              </p>
            )}
          <div className='grid grid-cols-3 gap-2'>
            <Button
              variant='text-link'
              size='lg'
              onClick={() => {
                cancelBooking.mutate(
                  { bookingId: booking.guid },
                  {
                    onSettled: () => {
                      setStepCount(1)
                      setDepositReservationCreated(false)
                    }
                  }
                )
              }}
              className='col-span-1'
              iconLeft={
                cancelBooking.isLoading ? (
                  <AutorenewIcon
                    className='animate-spin'
                    accessibility='decorative'
                  />
                ) : (
                  <ChevronLeftIcon accessibility='decorative' />
                )
              }
            >
              Back
            </Button>
            <Button
              testId={`complete-deposit-booking-button${
                isAuthenticated ? '-auth' : ''
              }`}
              type='submit'
              form='paymentForm'
              size='lg'
              className='col-span-2'
              disabled={!ccData || paymentLoading}
              iconLeft={
                paymentLoading ? (
                  <AutorenewIcon
                    className='animate-spin'
                    accessibility='decorative'
                  />
                ) : undefined
              }
            >
              {buttonText}
            </Button>
          </div>
        </div>
      ) : (
        // Regular bookings (no deposits)
        <div className='flex flex-col gap-4 w-full'>
          {!isCompleteBookingButtonDisabled && (
            <div className='text-secondary type-subhead'>
              <BookingCreationLegalText
                isAuthenticated={isAuthenticated}
                restaurant={restaurant}
                buttonText={buttonText}
              />
            </div>
          )}
          <SubmitButton
            testId={`complete-regular-booking-button${
              isAuthenticated ? '-auth' : ''
            }`}
            form={formId}
            size='base'
            className='w-full'
            disabled={isCompleteBookingButtonDisabled}
            iconLeft={
              createReservationLoading ? (
                <AutorenewIcon
                  className='animate-spin'
                  accessibility='decorative'
                />
              ) : undefined
            }
          >
            {buttonText}
          </SubmitButton>
        </div>
      )}
    </>
  )
}

import React, { createContext, ReactElement, useContext } from 'react'
import { getAvailabilities } from './getAvailabilities'

export const AvailabilitiesContext = createContext({ getAvailabilities })
export const useAvailabilitiesContext = () => useContext(AvailabilitiesContext)

interface AvailiabilitiesProviderProps {
  getAvailabilitiesInput: (
    restaurantGuid: string,
    startTimeString: string,
    endTimeString: string,
    partySize: number
  ) => Promise<any>
  children: ReactElement<any> | Array<ReactElement<any>>
}

export function AvailabilitiesProvider({
  children,
  getAvailabilitiesInput
}: AvailiabilitiesProviderProps) {
  return (
    <AvailabilitiesContext.Provider
      value={{ getAvailabilities: getAvailabilitiesInput }}
    >
      {children}
    </AvailabilitiesContext.Provider>
  )
}

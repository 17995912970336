import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import {
  AutorenewIcon,
  CalendarTodayIcon,
  GuestIcon,
  SparkleIcon,
  SpecialRequestIcon
} from '@toasttab/buffet-pui-icons'
import { useIntlProps } from 'banquet-runtime-modules'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Reservation } from '../../api/bookings/getBooking'
import { DepositStatus, DepositStrategyType } from '../../api/deposits/model'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { getDepositRefundLegalText } from '../../utils/getDepositRefundLegalText'
import CreditCardForm from '../CreditCardForm/CreditCardForm'
import { useCreditCardForm } from '../CreditCardForm/CreditCardFormProvider'
import { DepositRefundText } from '../DepositRefundText/DepositRefundText'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { PageContentWrapper } from '../PageContentWrapper/PageContentWrapper'
import { ReservationCancelButton } from '../ReservationConfirmationView/ReservationCancelButton'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

type ReservationDepositViewProp = {
  booking: Reservation
  restaurant: RestaurantInfo
}

export const ReservationDepositView = ({
  booking,
  restaurant
}: ReservationDepositViewProp) => {
  const formatCurrency = useFormatCurrency()
  const navigate = useNavigate()
  const { shortUrl } = useRestaurantContext()
  const { ccData, paymentLoading, paymentError } = useCreditCardForm()
  const { language: locale } = useIntlProps()
  const [openCancelModal, setOpenCancelModal] = React.useState(false)

  const redirectToConfirmationPage = useCallback(() => {
    navigate(`/${shortUrl}/bookings/${booking.guid}`)
  }, [booking.guid, shortUrl, navigate])

  useEffect(() => {
    if (booking.depositStatus !== DepositStatus.NOT_PAID) {
      redirectToConfirmationPage()
    }
  }, [booking, redirectToConfirmationPage])

  return (
    <PageContentWrapper restaurant={restaurant}>
      <div className='flex flex-col gap-4 items-center text-default'>
        <div className='w-full pb-8'>
          <div className='flex flex-col gap-2'>
            <h3 className='type-large font-semibold'>{restaurant.name}</h3>
            <p className='font-semibold'>
              Table for {booking.partySize} in {booking.serviceAreaGroup.name}.
            </p>
            <div className='flex gap-2'>
              <GuestIcon />
              <p>
                {booking.mainGuest.firstName} {booking.mainGuest.lastName}
              </p>
            </div>
            {booking.expectedStartTime && (
              <>
                <div className='flex gap-2'>
                  <CalendarTodayIcon size='sm' />
                  {format(
                    new Date(booking.expectedStartTime),
                    Formats.date.long,
                    { locale: locale, timeZone: restaurant.timezone }
                  )}{' '}
                  @{' '}
                  {format(
                    new Date(booking.expectedStartTime),
                    Formats.time.short,
                    { locale: locale, timeZone: restaurant.timezone }
                  )}
                </div>
              </>
            )}
            {booking.specialOccasion && booking.specialOccasion !== 'NONE' && (
              <div className='flex gap-2'>
                <SparkleIcon />
                <p>{booking.specialOccasion}</p>
              </div>
            )}
            {booking.bookingNotes && (
              <div className='flex gap-2'>
                <SpecialRequestIcon />
                <p>{booking.bookingNotes}</p>
              </div>
            )}
          </div>
          <div className='border-b pt-4 mb-4' />

          {booking.depositAmount && (
            <>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-col gap-1'>
                  {booking.depositBookableConfigSnapshot?.depositPolicy && (
                    <>
                      <div className='text-secondary'>
                        <NewLineFormatter
                          text={
                            booking.depositBookableConfigSnapshot.depositPolicy
                          }
                        />
                      </div>

                      <div className='border-b pt-4 mb-4' />
                    </>
                  )}
                  <h4 className='type-large font-semibold'>
                    Reservation Deposit
                  </h4>
                  {booking.depositBookableConfigSnapshot?.strategy.type ===
                    DepositStrategyType.ByPartySize && (
                    <p className='text-secondary'>
                      {formatCurrency(
                        Number(booking.depositAmount / booking.partySize)
                      )}{' '}
                      deposit per person.
                    </p>
                  )}
                  {booking.depositBookableConfigSnapshot?.strategy.type ===
                    DepositStrategyType.ByBooking && (
                    <p className='text-secondary'>
                      {formatCurrency(booking.depositAmount)} deposit per
                      booking.
                    </p>
                  )}
                </div>
                <div className='flex justify-between'>
                  <p>
                    Reservation
                    {booking.depositBookableConfigSnapshot?.strategy.type ===
                    DepositStrategyType.ByPartySize
                      ? ` x ${booking.partySize}`
                      : ' x 1'}
                  </p>
                  <p>{formatCurrency(booking.depositAmount)}</p>
                </div>
              </div>
              <div className='border-b pt-2 mb-2' />
              <div className='flex flex-col gap-2'>
                <div className='flex justify-between type-headline-5 font-bold'>
                  <p>Amount Due</p>
                  <p>
                    {formatCurrency(booking.depositAmount, {
                      trailingZeros: true
                    })}
                  </p>
                </div>
                {booking.depositBookableConfigSnapshot
                  ?.cancellationRefundableTimeframe != null && (
                  <DepositRefundText
                    timeFrame={
                      booking.depositBookableConfigSnapshot
                        .cancellationRefundableTimeframe
                    }
                  />
                )}
                {paymentError && (
                  <Alert
                    variant='error'
                    title='Payment Unsuccessful'
                    className='my-4'
                  >
                    We were unable to run the payment method you provided.
                    Please contact your bank or try using a different card.
                  </Alert>
                )}
                <CreditCardForm
                  email={booking.mainGuest.email || ''}
                  restaurant={restaurant}
                  onSuccess={() => {
                    redirectToConfirmationPage()
                  }}
                  checkGuid={booking.depositCheckId || ''}
                  orderGuid={booking.depositOrderId || ''}
                />
                {ccData &&
                  booking.depositBookableConfigSnapshot
                    ?.cancellationRefundableTimeframe != null && (
                    <p className='text-secondary type-subhead -mt-2'>
                      By clicking &ldquo;Submit Payment,&rdquo; you authorize us
                      to charge you for the Amount Due on the above date and
                      apply it to your bill, with any balance greater than your
                      bill returned to you.{' '}
                      {getDepositRefundLegalText(
                        booking.depositBookableConfigSnapshot
                          .cancellationRefundableTimeframe
                      )}
                    </p>
                  )}
                <div className='flex flex-col md:flex-row gap-4'>
                  <Button
                    type='submit'
                    form='paymentForm'
                    size='lg'
                    disabled={!ccData || paymentLoading}
                    iconLeft={
                      paymentLoading ? (
                        <AutorenewIcon className='animate-spin' />
                      ) : undefined
                    }
                  >
                    Submit Payment
                  </Button>
                  <ReservationCancelButton
                    booking={booking}
                    onCancel={() => {
                      navigate(`/${shortUrl}/bookings/${booking.guid}`)
                      window.scrollTo(0, 0)
                    }}
                    authedToModify={true}
                    openCancelModal={openCancelModal}
                    setOpenCancelModal={setOpenCancelModal}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </PageContentWrapper>
  )
}

import React from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router'
import { CreateWaitlistParams } from '../../api/bookings/createWaitlist'
import { useCreateWaitlist } from '../../api/bookings/useCreateWaitlist'
import { useIsMobile } from '../../hooks/useIsMobile'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { Footer } from '../Footer'
import {
  getInitialFormValues,
  WaitlistDetailEntryViewFormValues
} from './models'
import { validationSchema } from './validationSchema'
import { WaitlistDetailEntryViewForm } from './WaitlistDetailEntryViewForm/WaitlistDetailEntryViewForm'
import { ContentWrapper } from '../ContentWrapper'
import { LoadingView } from '../LoadingView/LoadingView'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { makeIntlPhoneNumberString } from '@toasttab/buffet-pui-phone-utilities'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

export interface WaitlistDetailEntryViewProps {
  restaurant: RestaurantInfo
}

export function WaitlistDetailEntryView({
  restaurant
}: WaitlistDetailEntryViewProps) {
  const initialValues = getInitialFormValues(restaurant)
  const createWaitlist = useCreateWaitlist()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { shortUrl } = useRestaurantContext()

  if (!restaurant) {
    return <LoadingView />
  }
  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant!)

  const onSubmit = (values: WaitlistDetailEntryViewFormValues) => {
    const createWaitlistParams: CreateWaitlistParams = {
      partySize: values.partySize.toString(),
      bookingNotes: values.specialRequests,
      requestedServiceAreaGroups: values.requestedServiceAreaGroups,
      specialOccasion: values.specialOccasion,
      guest: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email ?? '',
        phoneNumber: makeIntlPhoneNumberString(values.phoneNumber)
      }
    }
    createWaitlist.mutate(
      { restaurantGuid: restaurant!.guid, params: createWaitlistParams },
      {
        onSuccess: (resp) =>
          navigate(`/${shortUrl}/bookings/${resp.results[0].guid}`)
      }
    )
  }

  return (
    <>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          <div className='bg-white shadow-xl rounded-md relative p-4 md:p-6 md:mt-4'>
            <div className='type-large text-dark-gray border-b border-bg-darken-8 font-semibold pb-3 md:pb-3.5'>
              Join Waitlist
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <WaitlistDetailEntryViewForm
                restaurant={restaurant!}
                isLoading={createWaitlist.isLoading}
              />
            </Formik>
          </div>
          <div className='mt-8'>
            <div className='text-default font-semibold border-b border-bg-darken-8'>
              Waitlist Policy
            </div>
            <div className='text-secondary my-4'>
              <NewLineFormatter text={restaurant.waitlistPolicy} />
            </div>
          </div>
          <div className='mt-8'>
            <div className='text-default font-semibold border-b border-bg-darken-8'>
              About the Restaurant
            </div>
            <div className='text-secondary my-4'>
              <NewLineFormatter text={restaurant.description} />
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className='flex flex-col relative mt-4 lg:w-96 md:w-1/3'>
            <RestaurantLocation
              phone={restaurant!.phoneNumber}
              site={restaurant!.websiteUrl}
              address={addressWithoutRestaurantName}
            />
          </div>
        )}
      </ContentWrapper>
      <Footer restaurant={restaurant} />
    </>
  )
}

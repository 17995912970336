import { useNavigate, useLocation } from 'react-router-dom'

export const useUpdateQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // Function to update query parameters
  const updateQueryParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(location.search)

    // Update the query parameters
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if (value !== undefined) {
        searchParams.set(key, value)
      } else {
        searchParams.delete(key)
      }
    })

    // Update the URL with the new query parameters
    navigate(
      {
        pathname: location.pathname,
        search: decodeURIComponent(searchParams.toString())
      },
      { replace: true }
    )
  }

  return updateQueryParams
}

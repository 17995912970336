import { useEffect, useRef } from 'react'

// Scrolls a parent container element with containerId so that the first
// child element on the left is the element with the currentItemId
export const useScrollAlign = (containerId: string, currentItemId: string) => {
  const selectionLeft = useRef<number | undefined>()

  useEffect(() => {
    const container = document.querySelector(`#${containerId}`)
    const containerLeft = container?.getBoundingClientRect().left
    if (selectionLeft.current === undefined) {
      selectionLeft.current = document
        .querySelector(`#${currentItemId}`)
        ?.getBoundingClientRect().left
    }
    if (containerLeft && selectionLeft.current) {
      container.scrollLeft = selectionLeft.current - containerLeft * 2
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

import { SelectOption } from '@toasttab/buffet-pui-select'
import { useMemo } from 'react'

export const usePartySizes = (restaurant: any): SelectOption[] =>
  useMemo(() => {
    const arr: SelectOption[] = []
    const minPartySize = restaurant.minPartySize ?? 2
    const maxPartySize = restaurant.maxPartySize ?? 2

    for (let i = minPartySize; i <= maxPartySize; i++) {
      arr.push({
        label: `${i}`,
        value: i
      })
    }

    return arr
  }, [restaurant])

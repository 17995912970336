export interface PathParams {
  [key: string]: string
}

export interface HeaderParams {
  [key: string]: string
}

export interface NvResponse<T> {
  message: string
  results: T[]
  errorCodes: String[]
}

export enum BookingType {
  RESERVATION = 'RESERVATION',
  WAITLIST = 'WAITLIST'
}

export enum BookingSource {
  WEB_CLIENT = 'WEB_CLIENT',
  HOST_APP = 'HOST_APP',
  UNKNOWN = 'UNKNOWN'
}

export type DateTimeString = string

export enum RequestName {
  CREATE_RESERVATION = 'Create Reservation',
  CREATE_DEPOSIT_RESERVATION = 'Create Deposit Reservation',
  CREATE_WAITLIST = 'Create Waitlist',
  GET_BOOKING = 'Get Booking',
  CANCEL_BOOKING = 'Cancel Booking',
  UPDATE_RESERVATION = 'Update Reservation',
  UPDATE_WAITLIST = 'Update Waitlist',
  GET_RESTUARANT = 'Get Restaurant',
  GET_AVAILABILITIES = 'Get Availabilities',
  GET_SERVICE_PERIOD = 'Get Service Period',
  GET_URL = 'Get Url',
  GET_WAITLIST_PREVIEW_AND_ESTIMATE = 'Get Waitlist Preview',
  GET_WAITLIST_OPEN = 'Get Waitlist Open',
  GET_ONLINE_RESERVATION_HOURS = 'Get Online Reservation Hours',
  GET_DAY_INFO = 'Get Day Info',
  LINK_GUEST_PROFILE = 'Link Guest Profile',
  GET_ALL_BOOKINGS_FOR_GUEST_PROFILE = 'Get All Bookings for Guest Profile',
  CREATE_AUTH_RESERVATION = 'Create Auth Reservation',
  UPDATE_AUTH_RESERVATION = 'Update Auth Reservation',
  CANCEL_AUTH_BOOKING = 'Cancel Auth Booking'
}

export enum RequestType {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PATCH = 'patch'
}

export interface NvRequestMetadata {
  url: string
  type: RequestType
}

export const RequestURL: Record<RequestName, NvRequestMetadata> = {
  // bookings
  [RequestName.CREATE_RESERVATION]: {
    url: 'v1/public/booking/reservation',
    type: RequestType.POST
  },
  [RequestName.CREATE_DEPOSIT_RESERVATION]: {
    url: 'v1/public/booking/depositReservation',
    type: RequestType.POST
  },
  [RequestName.CREATE_WAITLIST]: {
    url: 'v1/public/booking/waitlist',
    type: RequestType.POST
  },
  [RequestName.GET_BOOKING]: {
    url: 'v1/public/booking/{reservationGuid}',
    type: RequestType.GET
  },
  [RequestName.CANCEL_BOOKING]: {
    url: 'v1/public/booking/{bookingId}',
    type: RequestType.POST
  },
  [RequestName.UPDATE_RESERVATION]: {
    url: 'v1/public/booking/{bookingId}/reservation',
    type: RequestType.POST
  },
  [RequestName.UPDATE_WAITLIST]: {
    url: 'v1/public/booking/{bookingId}/waitlist',
    type: RequestType.POST
  },
  // restuarant
  [RequestName.GET_RESTUARANT]: {
    url: 'v1/public/restaurant',
    type: RequestType.GET
  },
  // Waitlist Estimate
  [RequestName.GET_WAITLIST_PREVIEW_AND_ESTIMATE]: {
    url: 'v1/public/booking/previewEstimateV2',
    type: RequestType.GET
  },
  // availabilities
  [RequestName.GET_AVAILABILITIES]: {
    url: 'v1/public/availabilities',
    type: RequestType.GET
  },
  // servoice period
  [RequestName.GET_SERVICE_PERIOD]: {
    url: 'v1/public/servicePeriod',
    type: RequestType.GET
  },
  // short url
  [RequestName.GET_URL]: {
    url: 'v1/public/s/{shortUrlKey}/',
    type: RequestType.GET
  },
  // is waitlist open
  [RequestName.GET_WAITLIST_OPEN]: {
    url: 'v1/public/isWaitlistOpen',
    type: RequestType.GET
  },
  // get online reservation hours
  [RequestName.GET_ONLINE_RESERVATION_HOURS]: {
    url: 'v1/public/onlineReservationHours',
    type: RequestType.GET
  },
  [RequestName.GET_DAY_INFO]: {
    url: 'v1/public/dayInfo/{businessDate}',
    type: RequestType.GET
  },
  // guest profiles
  [RequestName.LINK_GUEST_PROFILE]: {
    url: 'v1/public/guest/linkGuestProfile',
    type: RequestType.POST
  },
  [RequestName.GET_ALL_BOOKINGS_FOR_GUEST_PROFILE]: {
    url: 'v1/public/guest/booking/reservation/all',
    type: RequestType.GET
  },
  [RequestName.CREATE_AUTH_RESERVATION]: {
    url: 'v1/public/guest/booking/reservation',
    type: RequestType.POST
  },
  [RequestName.UPDATE_AUTH_RESERVATION]: {
    url: 'v1/public/guest/booking/{bookingId}/reservation',
    type: RequestType.POST
  },
  [RequestName.CANCEL_AUTH_BOOKING]: {
    url: 'v1/public//guest/booking/{bookingId}',
    type: RequestType.POST
  }
}

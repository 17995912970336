import { UseQueryOptions, useQuery } from 'react-query'
import { WaitlistPlaceInLine, getWaitlistPreview } from './getWaitlistPreview'

export const useGetWaitlistPreview = (
  restaurantGuid: string,
  partySize: number,
  requestedServiceAreaGroups: string[] = [],
  opts?: UseQueryOptions<WaitlistPlaceInLine>
) => {
  return useQuery({
    queryKey: [
      'getWaitlistQueryKey',
      restaurantGuid,
      partySize,
      requestedServiceAreaGroups
    ],
    queryFn: () =>
      getWaitlistPreview({ restaurantGuid, bookingGuid: null, partySize }),
    keepPreviousData: true,
    ...opts
  })
}

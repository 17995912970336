import React from 'react'
import { createContext, useContext, useState } from 'react'
import { CCData } from './types/CCDataType'

export type CreditCardFormContextType = {
  ccData: CCData | null
  setCcData: React.Dispatch<React.SetStateAction<CCData | null>>
  paymentLoading: boolean
  setPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>
  paymentError: string | null
  setPaymentError: React.Dispatch<React.SetStateAction<string | null>>
}

const CreditCardFormContext = createContext<CreditCardFormContextType | null>(
  null
)

export const useCreditCardForm = () => {
  const ctx = useContext(CreditCardFormContext)

  if (!ctx) {
    throw new Error(
      'useCreditCardForm has to be used within <CreditCardFormProvider>'
    )
  }

  return ctx
}

export const CreditCardFormProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [ccData, setCcData] = useState<CCData | null>(null)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentError, setPaymentError] = useState<string | null>(null)
  return (
    <CreditCardFormContext.Provider
      value={{
        ccData,
        setCcData,
        paymentError,
        paymentLoading,
        setPaymentError,
        setPaymentLoading
      }}
    >
      {children}
    </CreditCardFormContext.Provider>
  )
}

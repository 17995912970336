import React from 'react'

export const DepositRefundText = ({ timeFrame }: { timeFrame: number }) => {
  switch (timeFrame) {
    case -1:
      return (
        <p className='text-secondary'>
          This deposit is non-refundable. Deposits will be applied to your bill
          on the date of your reservation.
        </p>
      )
    case 0:
      return (
        <p className='text-secondary'>
          This deposit is refundable until the time of your reservation.
        </p>
      )
    default:
      return (
        <p className='text-secondary'>
          Refundable if you cancel up to{' '}
          <span className='font-semibold'>{timeFrame} hours</span> before your
          reservation time. Deposits will be applied to your bill on the date of
          your reservation.
        </p>
      )
  }
}

import React from 'react'
import { PartialFrame } from '../Frame/PartialFrame'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

const BookingSkeletonCard = () => {
  return (
    <div className='w-full flex rounded-[12px] shadow-xl bg-white gap-4 px-4 py-2 items-center'>
      <Skeleton className='w-16 h-16 rounded-[12px]' />
      <div className='flex flex-col py-4'>
        <Skeleton className='h-6 w-40 mb-1' />
        <Skeleton className='h-5 w-72 mb-1' />
        <Skeleton className='h-5 w-96' />
      </div>
    </div>
  )
}

export const GuestBookingSkeletonView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  return (
    <PartialFrame restaurant={restaurant}>
      <div className='w-full justify-center flex flex-col gap-4'>
        <h1 className='type-large font-semibold'>My bookings</h1>
        <h2 className='flex items-baseline font-semibold'>Upcoming</h2>
        <div className='w-full flex flex-col gap-4'>
          {Array.from({ length: 1 }, (_, i) => (
            <BookingSkeletonCard key={`upcoming-${i}`} />
          ))}
        </div>
        <h2 className='font-semibold'>Past</h2>
        <div className='w-full flex flex-col gap-4'>
          {Array.from({ length: 10 }, (_, i) => (
            <BookingSkeletonCard key={`past-${i}`} />
          ))}
        </div>
      </div>
    </PartialFrame>
  )
}

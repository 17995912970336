import React from 'react'
import cx from 'classnames'

type NewLineFormatterProps = {
  text: string | null
}

export function NewLineFormatter({
  text
}: NewLineFormatterProps): React.JSX.Element {
  const regex = /[\n]{2,}/g
  const paragraphs = (text || '')
    .split(regex)
    .map((newLine) => newLine.trim())
    .filter(Boolean)
    .map((paragraph, index) => (
      <p
        key={index}
        className={cx({
          'mt-2': index !== 0
        })}
      >
        {paragraph.split(/\n/).map((singleLine, index, arr) => {
          const hasLineBreaks = arr.length > 1
          if (!hasLineBreaks) {
            return singleLine
          }

          return (
            <span key={index} className='block'>
              {singleLine}
            </span>
          )
        })}
      </p>
    ))
  return <div className='flex flex-col'>{paragraphs.map((p) => p)}</div>
}

export function getAbsoluteUrl(url: string) {
  const trimmedUrl = url.trim()
  if (
    !trimmedUrl.startsWith('//') &&
    !trimmedUrl.startsWith('http://') &&
    !trimmedUrl.startsWith('https://')
  ) {
    return '//' + trimmedUrl
  }
  return trimmedUrl
}

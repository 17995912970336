import { useMutation } from 'react-query'
import { useBookingContext } from './BookingContext'
import { CreateWaitlistParams } from './createWaitlist'

export const useCreateWaitlist = () => {
  const { createWaitlist } = useBookingContext()

  return useMutation(
    ({
      restaurantGuid,
      params
    }: {
      restaurantGuid: string
      params: CreateWaitlistParams
    }) => createWaitlist(restaurantGuid, params)
  )
}

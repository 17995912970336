import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import axios from 'axios'
import { baseAuthUrl, gatewayPath } from './gateway'
import { getGuestProfileRequest } from './getGuestProfileRequest'
import { useMutation } from 'react-query'
import { RequestType } from '../baseModels'
import { apolloHeaders, authHeaders } from './constants'

export interface Guest {
  id: string
  contact: {
    phone: string
    email: string
    firstName: string
    lastName: string
  }
}

export const useGetGuestProfile = () => {
  const { getAuthenticationHeader } = useAuth()

  const getGuestProfiles = async () => {
    const authHeader = await getAuthenticationHeader()
    const response = await axios.request({
      method: RequestType.POST,
      url: `${baseAuthUrl}${gatewayPath}`,
      data: getGuestProfileRequest,
      headers: {
        ...authHeaders(authHeader),
        ...apolloHeaders
      },
      params: undefined
    })

    return response.data.data.guest
  }

  return useMutation(() => getGuestProfiles())
}

import { PhoneNumber } from '@toasttab/buffet-pui-phone-utilities'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
export interface WaitlistDetailEntryViewFormValues {
  partySize: string
  firstName: string
  lastName: string
  phoneNumber: PhoneNumber
  email?: string
  specialRequests?: string
  specialOccasion?: string
  requestedServiceAreaGroups: string[]
}

export const getInitialFormValues = (
  restaurant?: RestaurantInfo
): WaitlistDetailEntryViewFormValues => ({
  partySize: '2',
  firstName: '',
  lastName: '',
  phoneNumber: {
    countryCode: '',
    nationalNumber: '',
    countryIsoCode: restaurant?.country ?? 'US'
  },
  email: '',
  specialRequests: '',
  specialOccasion: 'NONE',
  requestedServiceAreaGroups: []
})

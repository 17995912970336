import { DateTime } from 'luxon'
import { OnlineReservationHoursInterval } from '../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { SelectOption } from '../common/types'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'

export const calculateTimes = (
  hours: OnlineReservationHoursInterval[] | undefined,
  restaurant: RestaurantInfo,
  date: DateTime,
  locale: Locale
): SelectOption[] => {
  const res: SelectOption[] = []

  // if there are no hours, return an empty list
  if (!hours?.length) {
    return res
  }

  const firstTime = hours[0].start
  const firstTimeOnSelectedDate = DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: DateTime.fromISO(firstTime).hour,
    minute: DateTime.fromISO(firstTime).minute
  }).setZone(restaurant.timezone, { keepLocalTime: true })

  hours?.forEach((interval) => {
    let startTime = DateTime.fromISO(interval.start)
    let endTime = DateTime.fromISO(interval.end)
    if (endTime.hour < restaurant.closeOutHour) {
      endTime = endTime.plus({ days: 1 })
    }
    if (startTime.hour < restaurant.closeOutHour) {
      startTime = startTime.plus({ days: 1 })
    }
    while (startTime <= endTime) {
      let timeValue = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: startTime.hour,
        minute: startTime.minute
      }).setZone(restaurant.timezone, { keepLocalTime: true })

      // if the current time value is ever less than the first available time
      // we've reached the midnight (day+1) point, so add a day.
      if (timeValue < firstTimeOnSelectedDate) {
        timeValue = timeValue.plus({ days: 1 })
      }

      const value = timeValue.toISO()
      const overlappingHours =
        value && res.some((option) => option.value === value)
      if (!overlappingHours) {
        res.push({
          value,
          label: startTime
            .setZone(restaurant.timezone, {
              keepLocalTime: true
            })
            .toFormat('h:mm a', {locale: locale}),
          disabled: false
        })
      }
      const minutesToAdd =
        startTime.minute === 15 || startTime.minute === 45 ? 15 : 30
      startTime = startTime.plus({ minutes: minutesToAdd }).startOf('minute')
    }
  })
  return res
}

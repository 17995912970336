import React from 'react'
import cx from 'classnames'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon, LockLockedIcon } from '@toasttab/buffet-pui-icons'
import { PartialFrame } from '../Frame/PartialFrame'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

const fieldSkeletons = [
  { name: 'FIRST NAME', isEditable: true, className: 'w-14' },
  { name: 'LAST NAME', isEditable: true, className: 'w-20' },
  { name: 'PHONE', isEditable: false, className: 'w-24' },
  { name: 'EMAIL', isEditable: false, className: 'w-32' }
]

export const GuestProfileSkeletonView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  return (
    <PartialFrame restaurant={restaurant}>
      <h1 className='type-large font-medium'>Account Info</h1>
      <div className='w-full gap-6 my-4'>
        {fieldSkeletons.map(({ className, isEditable, name }) => (
          <div
            key={name}
            className={cx(
              'w-full flex flex-col rounded-[12px] font-medium my-4',
              {
                'bg-white pl-4 pt-4 pr-2 pb-2': isEditable,
                'p-4 border': !isEditable
              }
            )}
          >
            <div
              className={cx(
                'flex items-center justify-start font-normal type-overline text-secondary truncate',
                {
                  'pb-2': !isEditable
                }
              )}
            >
              {name}
            </div>
            <div className='flex justify-between items-center'>
              <Skeleton
                className={cx('h-6', {
                  [className]: className
                })}
              />
              {isEditable ? (
                <IconButton icon={<EditIcon aria-label='Edit' />} disabled />
              ) : (
                <IconButton
                  cropToIcon
                  icon={<LockLockedIcon aria-label='Edit' />}
                  disabled
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </PartialFrame>
  )
}

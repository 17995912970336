import * as Sentry from '@sentry/react'

export const infoLog = (message: string) => {
  Sentry.captureMessage(message, Sentry.Severity.Info)
}

export const errorLog = (message: string) => {
  Sentry.captureMessage(message, Sentry.Severity.Error)
}

export const exceptionLog = (exception: any) => {
  Sentry.captureException(exception)
}

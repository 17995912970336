import React from 'react'
import { specialOccasions } from './models'
import { SelectField, CardSelectorGroupField } from '@toasttab/buffet-pui-forms'
import { FormikProps } from 'formik'
import { ReservationUpdateFormProps } from './models'
import { useScrollAlign } from '../../hooks/useScrollAlign'

interface SpecialOccasionSelectorProps {
  isMobile: boolean
  formik: FormikProps<ReservationUpdateFormProps>
}

export const SpecialOccasionSelector = ({
  isMobile,
  formik
}: SpecialOccasionSelectorProps) => {
  const specialOccasionContainerId = 'special-occasion-container'
  const specialOccasionLable = 'specialOccasionPickerLabel'

  useScrollAlign(specialOccasionContainerId, formik.values.specialOccasion)

  return isMobile ? (
    <div className='flex flex-col w-full gap-2'>
      <label id={specialOccasionLable}>
        <div className='flex items-baseline'>
          <h2 className='font-semibold sm:px-0'>Special Occasion</h2>
          <span className='type-subhead text-gray-75 ml-1 pr-1 font-normal'>
            (optional)
          </span>
        </div>
      </label>

      <div
        id={specialOccasionContainerId}
        className='flex md:flex-nowrap gap-2 md:gap-4 whitespace-nowrap overflow-x-auto no-scrollbar sm:flex-wrap sm:whitespace-normal md:pl-0'
      >
        <CardSelectorGroupField
          aria-labelledby={specialOccasionLable}
          name={'specialOccasion'}
          onClick={(value) => {
            if (
              (value.target as HTMLInputElement).value ===
              formik.values.specialOccasion
            ) {
              formik.setFieldValue('specialOccasion', 'NONE')
            }
          }}
          optionsContainerClassName='flex flex-row whitespace-nowrap gap-2'
          itemsContainerClassName='my-0'
          options={specialOccasions
            .filter((occasion) => occasion.value !== 'NONE')
            .map((occasion) => {
              return {
                value: occasion.value,
                contents: (
                  <div id={occasion.value} className='flex gap-2'>
                    {occasion.iconLeft} {occasion.label}
                  </div>
                )
              }
            })}
        />
      </div>
    </div>
  ) : (
    <SelectField
      label={
        <div className='flex items-baseline'>
          <h2 className='font-semibold sm:px-0'>Special Occasion</h2>
          <span className='type-subhead text-gray-75 ml-1 pr-1 font-normal'>
            (optional)
          </span>
        </div>
      }
      name='specialOccasion'
      options={specialOccasions}
    />
  )
}

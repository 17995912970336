import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { HandWithPhone as HandWithPhoneImage } from '../../assets/icons/HandWithPhone'
import * as React from 'react'

type GuestLoginReminderCardProps = {
  setOpenLoginReminderCard: React.Dispatch<React.SetStateAction<boolean>>
  setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const GuestLoginReminderCard = ({
  setOpenLoginReminderCard,
  setOpenLoginModal
}: GuestLoginReminderCardProps) => {
  return (
    <div className='pb-6'>
      <div className='rounded-[12px] border border-gray-500 bg-[#F8F5F2] pl-4 pr-2 pt-3 md:pl-6 md:pt-5 md:pr-3 my-4'>
        <div className='flex flex-row relative justify-between'>
          <div className='flex flex-col gap-2 mb-4 w-2/3'>
            <ToastBrand
              height={'16px'}
              width={'60px'}
              size='xxs'
              strokeColor='text-brand-50'
              className='mt-1 inline-block'
            />
            <p className='type-subhead md:type-default'>
              Manage your bookings by logging in or creating an account with
              Toast.
            </p>
            <button
              data-testid='guest-account-login-reminder-button'
              className='w-fit flex h-8 md:h-10 type-subhead md:type-default py-2 px-3 md:px-4 items-center rounded-[9999px] bg-black text-white font-normal border-transparent'
              onClick={() => {
                setOpenLoginModal(true)
              }}
            >
              Sign in/Sign up
            </button>
          </div>
          <div className='flex relative items-end mx-4 md:ml-8'>
            <HandWithPhoneImage />
          </div>
          <IconButton
            testId='close-account-reminder-button'
            cropToIcon
            textClassName='text-gray-100'
            className='flex absolute right-2'
            icon={<CloseIcon aria-label='Close' />}
            onClick={() => setOpenLoginReminderCard(false)}
          />
        </div>
      </div>
    </div>
  )
}

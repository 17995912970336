import { DateTime } from 'luxon'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { useGetDayInfo } from '../api/restaurant/useGetDayInfo'
import { getSpecialHours } from '../components/ReservationTimeSelectionView/ReservationTimeSelectionViewForm/helpers'
import { calculateTimes } from '../utils/calculateTimes'
import { useGetOnlineReservationHours } from '../api/availabilities/onlineReservationHours/useGetOnlineReservationHours'
import { useFindReserveContext } from '../contexts/find-reserve-context'
import { OnlineReservationHoursInterval } from '../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import { useBanquetProps, useIntlProps } from 'banquet-runtime-modules'

export const useReservationInfo = (
  restaurant: RestaurantInfo,
  date: DateTime,
  onHoursSuccess?: (data: OnlineReservationHoursInterval[]) => void
) => {
  const { featureFlags } = useBanquetProps()
  const { hasDateChanged } = useFindReserveContext()

  const { data: dayInfo } = useGetDayInfo(restaurant.guid, date)
  const hoursData = useGetOnlineReservationHours(restaurant.guid, date, {
    enabled: hasDateChanged && date.isValid,
    onSuccess: (data) => {
      onHoursSuccess?.(data)
    }
  })
  const { language: locale } = useIntlProps()

  if (!dayInfo && !hoursData) {
    return {}
  }

  const hoursOptions = calculateTimes(hoursData.data, restaurant, date, locale)

  const isBlocked = dayInfo !== undefined && dayInfo.onlineReservationsBlocked

  const isRestaurantClosed =
    !isBlocked && hoursData?.data && !hoursData.data?.[0]?.start
  const isAfterHours =
    !isRestaurantClosed &&
    !isBlocked &&
    hoursOptions.length > 0 &&
    hoursOptions.every(({ value }) => new Date() > new Date(value))

  const specialHours = getSpecialHours(hoursData?.data)
  const hoursInAdvanced = restaurant.bookingMinHoursInAdvance

  const blockedReason =
    dayInfo?.blockedReason ||
    'The restaurant is not accepting online reservations for this date.'

  const isFlexConfigsEnabled = Boolean(featureFlags?.['nv1-create-schedules'])

  const scheduleMemoName = isFlexConfigsEnabled
    ? dayInfo?.scheduleDescription?.name
    : ''
  const scheduleMemoDescription = isFlexConfigsEnabled
    ? dayInfo?.scheduleDescription?.description
    : ''
  const specialDateName = (scheduleMemoName || dayInfo?.specialDateName) ?? ''
  const specialDateDescription =
    (scheduleMemoDescription || dayInfo?.specialDateDescription) ?? ''

  const hasSpecialDateInfo =
    !isRestaurantClosed &&
    !isBlocked &&
    dayInfo !== undefined &&
    (specialDateName || specialDateDescription)

  const shouldShowSpecialHours = !isBlocked && specialHours.length > 0

  const shouldShowHoursInAdvance =
    hoursInAdvanced != null && hoursInAdvanced !== 0

  const shouldShowInfoPanel =
    isBlocked ||
    hasSpecialDateInfo ||
    isRestaurantClosed ||
    isAfterHours ||
    shouldShowSpecialHours ||
    shouldShowHoursInAdvance

  return {
    blockedReason,
    dayInfo,
    hasSpecialDateInfo,
    hoursData,
    hoursInAdvanced,
    hoursOptions,
    isAfterHours,
    isBlocked,
    isRestaurantClosed,
    shouldShowHoursInAdvance,
    shouldShowInfoPanel,
    shouldShowSpecialHours,
    specialDateDescription,
    specialDateName,
    specialHours
  }
}

import React from 'react'

export const Reservation = () => (
  <svg
    width='134'
    height='94'
    viewBox='0 0 134 94'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M97.2915 49.5903C97.2915 49.5903 97.2915 58.567 106.252 58.567C106.252 58.567 97.2915 58.567 97.2915 67.5437C97.2915 67.5437 97.2915 58.567 88.3311 58.567C88.3756 58.567 97.2915 58.567 97.2915 49.5903Z'
      stroke='#FF4C00'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M49.3687 1C49.3687 1 49.3687 9.9767 58.3292 9.9767C58.3292 9.9767 49.3687 9.9767 49.3687 18.9534C49.3687 18.9534 49.3687 9.9767 40.4082 9.9767C40.4082 9.9767 49.3687 9.9767 49.3687 1Z'
      stroke='#FF4C00'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M46.248 33.468L64.6594 40.9262L73.6645 59.2369'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.2105 19.6232L37.5996 36.4601L60.1123 45.5708L71.1234 68.0349L85.2105 19.6232Z'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M60.1123 45.5708L85.2106 19.6232'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M133 37.7107C132.688 35.165 130.548 33.2 127.918 33.2C126.937 33.2 126.046 33.468 125.243 33.9592C124.798 28.1087 119.938 23.5087 114.009 23.5087C108.66 23.5087 104.202 27.2602 103.043 32.2621C101.928 31.7262 100.725 31.4136 99.4318 31.4136C95.5534 31.4136 92.2545 34.0932 91.3184 37.666H133V37.7107Z'
      stroke='#E5E5E5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M62.876 93C63.188 90.4544 65.3278 88.4894 67.958 88.4894C68.9388 88.4894 69.8304 88.7573 70.6328 89.2486C71.0786 83.3981 75.9378 78.7981 81.8668 78.7981C87.2164 78.7981 91.6743 82.5496 92.8334 87.5515C93.9479 87.0156 95.1515 86.703 96.4444 86.703C100.323 86.703 103.622 89.3826 104.558 92.9554H62.876V93Z'
      stroke='#E5E5E5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 28.4214H35.3708C34.4346 24.8039 31.1358 22.1243 27.2128 22.1243C25.92 22.1243 24.6717 22.4369 23.5572 22.9728C22.3982 17.8816 17.8956 14.1301 12.5015 14.1301C9.38095 14.1301 6.34954 15.4253 4.20973 17.7029C1.49037 20.6505 1.2229 24.5806 1 28.4214Z'
      stroke='#E5E5E5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M44.5537 51.5107C44.5537 51.5107 34.345 56.8699 24.8496 51.6447'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M54.2722 80.8524C54.2722 80.8524 48.9226 70.6252 54.1385 61.1126'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M50.7509 54.9048L31.5371 74.4214'
      stroke='#2B4FB9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

import { Button } from '@toasttab/buffet-pui-buttons'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import {
  CalendarTodayIcon,
  GuestIcon,
  LoyaltyIcon,
  SparkleIcon,
  SpecialRequestIcon
} from '@toasttab/buffet-pui-icons'
import { useIntlProps } from 'banquet-runtime-modules'
import cx from 'classnames'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { queryClient } from '../../App'
import { BookingType } from '../../api/baseModels'
import { BookingStatus, Reservation } from '../../api/bookings/getBooking'
import { GetBookingQueryKey } from '../../api/bookings/useGetBooking'
import { DepositStatus } from '../../api/deposits/model'
import { useAuthOnMount } from '../../api/guest/constants'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { useIsLarge } from '../../hooks/useIsLarge'
import { useIsMobile } from '../../hooks/useIsMobile'
import { capitalize } from '../../utils/capitalize'
import { isPastUtcDate } from '../../utils/isPastUtcDate'
import { useShowGuestAccounts } from '../GuestAccount/flags'
import { AddToCalendarButton } from './AddToCalendarButton/AddToCalendarButton'
import { CTAButtons } from './CTAButtons'
import { DepositSummary } from './DepositSummary'
import { ReservationCancelButton } from './ReservationCancelButton'
import { ReservationModifyButton } from './ReservationModifyButton'
import { cancelledStates, statusMessageMap } from './models'
import { SubheaderMessage } from './useGetSubheaderMessage'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

type ReservationConfirmationCardProps = {
  booking: Reservation
  restaurant: RestaurantInfo
  bookingPreviousState: Reservation | null
  setBookingPreviousState: Function
  setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>
  openUpdateModal: boolean
  setOpenUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
  openCancelModal: boolean
  setOpenCancelModal: React.Dispatch<React.SetStateAction<boolean>>
  setFromModifyButton: React.Dispatch<React.SetStateAction<boolean>>
  setFromCancelButton: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReservationConfirmationCard = ({
  booking,
  restaurant,
  bookingPreviousState,
  setBookingPreviousState,
  setOpenLoginModal,
  openUpdateModal,
  setOpenUpdateModal,
  openCancelModal,
  setOpenCancelModal,
  setFromModifyButton,
  setFromCancelButton
}: ReservationConfirmationCardProps) => {
  const formatCurrency = useFormatCurrency()
  const { shortUrl } = useRestaurantContext()
  const isMobile = useIsMobile()
  const isLarge = useIsLarge()
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const { isAuthenticatedConditional, guestData } =
    useAuthOnMount(showGuestAccounts)
  const [authorizedToModify, setAuthorizedToModify] = useState(false)

  React.useEffect(() => {
    setAuthorizedToModify(
      booking.createdByAuthenticatedUser
        ? isAuthenticatedConditional &&
            guestData &&
            guestData.id === booking.mainGuest.guestProfilesGuid
        : true
    )
  }, [
    booking.createdByAuthenticatedUser,
    booking.mainGuest.guestProfilesGuid,
    guestData,
    isAuthenticatedConditional
  ])
  const { language: locale } = useIntlProps()

  const isConfirmed =
    booking !== null &&
    !cancelledStates.includes(booking.bookingStatus) &&
    booking.bookingStatus !== BookingStatus.R_DONE

  let statusMessage = ''

  if (booking) {
    statusMessage = statusMessageMap[booking.bookingStatus].mainMessage
  }

  const guestInfoConditon =
    booking && isConfirmed && booking.bookingStatus !== BookingStatus.W_DONE
  const getTitle = () => {
    if (booking.bookingStatus === BookingStatus.R_CANCELLED) {
      return 'Reservation Cancelled'
    } else if (booking.bookingType === BookingType.RESERVATION) {
      if (
        [BookingStatus.R_DONE, BookingStatus.R_NO_SHOW].includes(
          booking.bookingStatus
        )
      ) {
        return 'Past Reservation'
      }
      return 'Confirmation'
    }

    return 'Waitlist Status'
  }

  const Icon = statusMessageMap[booking!.bookingStatus].mainIcon

  function waitlistSubtext() {
    switch (booking.bookingStatus) {
      case BookingStatus.W_NOTIFIED:
      case BookingStatus.W_ARRIVED:
        return (
          <p className='type-default text-secondary text-center type-subhead mt-2 mb-2'>
            Running Late?
            <a
              href={`tel:${restaurant?.phoneNumber.toString()}`}
              className='text-primary-100 font-medium'
            >
              {' '}
              Notify Us{' '}
            </a>
          </p>
        )
      default:
        return null
    }
  }

  const loyaltyUrl = `https://www.toasttab.com/${shortUrl}/rewardsSignup`

  const URLButtons = () => {
    const loyaltyClass = cx({
      'w-full text-sm !text-default text-bold shadow-md': !isLarge,
      'w-full mr-2 mt-4 text-sm !text-default text-bold shadow-md': loyaltyUrl,
      hidden: !restaurant.loyaltyEnabled
    })

    return (
      <div>
        <div className='grid gap-4 lg:grid-flow-col lg:auto-cols-fr'>
          <CTAButtons booking={booking} restaurant={restaurant} />
        </div>
        <Button
          as='a'
          size='lg'
          variant='text-link'
          className={loyaltyClass}
          href={loyaltyUrl}
          target='_blank'
        >
          <div className='flex gap-2 place-items-center'>
            <LoyaltyIcon />
            Join Our Loyalty Program
          </div>
        </Button>
      </div>
    )
  }

  const modificationDisabled =
    DateTime.fromISO(booking.expectedStartTime).diffNow('hours').hours <=
      restaurant.bookingMinHoursInAdvance || booking.depositAmount !== null

  const displayUpdatedField = (
    previousValue: string | number | undefined | null,
    currentValue: string | number | null
  ) => {
    if (bookingPreviousState === null) {
      return <span>{currentValue}</span>
    }

    if (previousValue !== undefined && currentValue !== previousValue) {
      return (
        <>
          <span className='line-through'>{previousValue}</span>{' '}
          <span className='text-brand-50'>{currentValue}</span>
        </>
      )
    } else if (previousValue === undefined) {
      return <span className='text-brand-50'>{currentValue}</span>
    }
    return <span>{currentValue}</span>
  }
  const showRefundableDeposit =
    booking.bookingStatus === BookingStatus.R_CANCELLED &&
    booking.depositRefundableCancellationDatetime &&
    !isPastUtcDate(new Date(booking.depositRefundableCancellationDatetime)) &&
    booking.depositAmount

  const showNonRefundableDeposit =
    booking.bookingStatus === BookingStatus.R_CANCELLED &&
    booking.depositRefundableCancellationDatetime &&
    isPastUtcDate(new Date(booking.depositRefundableCancellationDatetime))

  const getReservationDateAndTime = () => {
    const previousStartDate = bookingPreviousState
      ? format(
          new Date(bookingPreviousState.expectedStartTime),
          Formats.date.long,
          { locale: locale, timeZone: restaurant.timezone }
        )
      : undefined
    const modifiedStartDate = format(
      new Date(booking.expectedStartTime),
      Formats.date.long,
      { locale: locale, timeZone: restaurant.timezone }
    )

    const previousStartTime = bookingPreviousState
      ? format(
          new Date(bookingPreviousState.expectedStartTime),
          Formats.time.short,
          { locale: locale, timeZone: restaurant.timezone }
        )
      : undefined
    const modifiedStartTime = format(
      new Date(booking.expectedStartTime),
      Formats.time.short,
      { locale: locale, timeZone: restaurant.timezone }
    )

    const date = displayUpdatedField(previousStartDate, modifiedStartDate)
    const time = displayUpdatedField(previousStartTime, modifiedStartTime)
    return { date, time }
  }

  const reservationDateAndTime = getReservationDateAndTime()

  const hasDeposit = booking.depositOrderId

  const hasDepositBeenRefunded = Boolean(
    booking.depositStatus &&
      [DepositStatus.PENDING_REFUND, DepositStatus.REFUNDED].includes(
        booking.depositStatus
      )
  )

  const isPastStartTime = isPastUtcDate(
    DateTime.fromISO(booking.expectedStartTime).toJSDate()
  )

  return (
    <div className='relative md:my-4'>
      <div className='type-large text-dark-gray border-b border-bg-darken-8 font-semibold pb-3'>
        {getTitle()}
      </div>

      {hasDepositBeenRefunded && (
        <div className='bg-info-0 flex flex-col gap-0.5 rounded-md p-3 mt-4'>
          <p className='type-overline'>DEPOSIT REFUNDED</p>
          <div className='flex justify-between'>
            <p>Amount returned</p>
            <p className='font-semibold'>
              {formatCurrency(booking.depositAmount || 0)}
            </p>
          </div>
        </div>
      )}
      <div className='w-full flex justify-center mt-8 flex-col'>
        <div className='mt-2 mb-4 flex justify-center'>
          <Icon />
        </div>
        <div className='w-full flex flex-col mb-4'>
          <div className='type-large font-semibold text-center'>
            {statusMessage}
          </div>
          {showRefundableDeposit && (
            <div className='flex flex-col gap-2 md:text-center'>
              <p className='text-secondary'>
                You will be refunded the deposit of{' '}
                {formatCurrency(booking.depositAmount || 0)} for this
                reservation to your original payment method.
              </p>
              <p className='text-secondary'>
                Refunds are generally processed within 5-7 business days.
              </p>
            </div>
          )}
          {showNonRefundableDeposit && (
            <p className='text-center text-secondary'>
              Please note that your deposit was not refundable. Please refer to
              the restaurant’s deposit policy.
            </p>
          )}
          <SubheaderMessage
            isMobile={isMobile}
            booking={booking}
            restaurant={restaurant}
            subMessage={statusMessageMap[booking.bookingStatus].subMessage}
          />
        </div>
        {waitlistSubtext()}
      </div>
      <div>
        <URLButtons />
      </div>

      {guestInfoConditon && (
        <>
          {booking.bookingType === BookingType.RESERVATION ? (
            <div className='pt-4 flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <p className='font-semibold'>
                  Table for{' '}
                  {displayUpdatedField(
                    bookingPreviousState?.partySize,
                    booking.partySize
                  )}{' '}
                  reserved in{' '}
                  {displayUpdatedField(
                    bookingPreviousState?.serviceAreaGroup.name,
                    booking.serviceAreaGroup.name
                  )}
                  .
                </p>
                <div className='flex gap-2'>
                  <GuestIcon />
                  <p>
                    {booking.mainGuest.firstName} {booking.mainGuest.lastName}
                  </p>
                </div>
                <div className='flex gap-2'>
                  <CalendarTodayIcon />
                  <p>
                    {reservationDateAndTime.date} @{' '}
                    {reservationDateAndTime.time}
                  </p>
                </div>
                {booking.specialOccasion &&
                  booking.specialOccasion !== 'NONE' && (
                    <div className='flex gap-2'>
                      <SparkleIcon />
                      <p>
                        {displayUpdatedField(
                          bookingPreviousState?.specialOccasion
                            ? bookingPreviousState.specialOccasion !== 'NONE'
                              ? capitalize(bookingPreviousState.specialOccasion)
                              : null
                            : null,
                          capitalize(booking.specialOccasion)
                        )}
                      </p>
                    </div>
                  )}
                {booking.bookingNotes && (
                  <div className='flex gap-2'>
                    <SpecialRequestIcon />
                    <p>
                      {displayUpdatedField(
                        bookingPreviousState?.bookingNotes,
                        booking.bookingNotes
                      )}
                    </p>
                  </div>
                )}
                <div className='-mt-2'>
                  <AddToCalendarButton
                    booking={booking}
                    restaurant={restaurant}
                  />
                </div>
              </div>
              {hasDeposit && (
                <DepositSummary
                  booking={booking}
                  hasDepositBeenRefunded={hasDepositBeenRefunded}
                />
              )}
              {[
                BookingStatus.R_CREATED,
                BookingStatus.R_CONFIRMED,
                BookingStatus.R_ARRIVED,
                BookingStatus.R_CHECKED_IN,
                BookingStatus.R_LATE_ARRIVAL,
                BookingStatus.R_PARTIALLY_ARRIVED
              ].includes(booking.bookingStatus) &&
                (!modificationDisabled || !isPastStartTime) && (
                  <div className='flex flex-col gap-4'>
                    {!modificationDisabled && (
                      <ReservationModifyButton
                        booking={booking}
                        restaurant={restaurant}
                        setPreviousState={setBookingPreviousState}
                        authedToModify={authorizedToModify}
                        openUpdateModal={openUpdateModal}
                        setOpenUpdateModal={setOpenUpdateModal}
                        setFromModifyButton={setFromModifyButton}
                        setOpenLoginModal={setOpenLoginModal}
                      />
                    )}
                    {!isPastStartTime && (
                      <ReservationCancelButton
                        booking={booking}
                        onCancel={() => {
                          queryClient.invalidateQueries([GetBookingQueryKey])
                          window.scrollTo(0, 0)
                        }}
                        authedToModify={authorizedToModify}
                        openCancelModal={openCancelModal}
                        setOpenCancelModal={setOpenCancelModal}
                        setFromCancelButton={setFromCancelButton}
                        setOpenLoginModal={setOpenLoginModal}
                      />
                    )}
                  </div>
                )}
            </div>
          ) : (
            <div className='flex flex-col gap-2 pt-4'>
              <p className='font-semibold'>
                Waitlist details for {booking.mainGuest.firstName}{' '}
                {booking.mainGuest.lastName}
              </p>
              <div className='flex gap-2'>
                <GuestIcon />
                <p>
                  {booking.partySize}{' '}
                  {booking.partySize === 1 ? 'guest' : 'guests'}
                </p>
              </div>
              <div className='flex gap-2'>
                <CalendarTodayIcon />
                <p>
                  {format(
                    new Date(booking.expectedStartTime),
                    Formats.date.long,
                    { locale: locale, timeZone: restaurant.timezone }
                  )}
                </p>
              </div>
              {booking.specialOccasion &&
                booking.specialOccasion !== 'NONE' && (
                  <div className='flex gap-2'>
                    <SparkleIcon />
                    <p>
                      {displayUpdatedField(
                        bookingPreviousState?.specialOccasion
                          ? bookingPreviousState.specialOccasion !== 'NONE'
                            ? capitalize(bookingPreviousState.specialOccasion)
                            : null
                          : null,
                        capitalize(booking.specialOccasion)
                      )}
                    </p>
                  </div>
                )}
              {booking.bookingNotes && (
                <div className='flex gap-2'>
                  <SpecialRequestIcon />
                  <p>
                    {displayUpdatedField(
                      bookingPreviousState?.bookingNotes,
                      booking.bookingNotes
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber'

export const phoneNumberFormatter = (phone: string) => {
  let pn: string | ParsedPhoneNumber = ''

  if (phone.trim().charAt(0) === '+') {
    pn = parsePhoneNumber(phone)
  } else {
    pn = parsePhoneNumber(phone, { regionCode: 'US' })
  }

  return pn.number?.national //all instances of phoneNumberFormatter first checks if phone param is null
}

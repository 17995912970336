import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

type FooterProps = {
  restaurant: RestaurantInfo
}

const Footer = ({ restaurant }: FooterProps) => {
  const isCalifornia = restaurant.country === 'US' && restaurant.state === 'CA'

  return (
    <div className='type-subhead my-12 text-secondary'>
      <a
        className='text-secondary'
        target='_blank'
        rel='noreferrer'
        href='https://pos.toasttab.com/privacy'
      >
        Privacy Statement
      </a>
      {' | '}
      {isCalifornia && (
        <>
          <a
            className='text-secondary'
            target='_blank'
            rel='noreferrer'
            href='https://pos.toasttab.com/privacy#addendum-a'
          >
            California Privacy Statement
          </a>
          {' | '}
        </>
      )}
      <a
        className='text-secondary'
        target='_blank'
        rel='noreferrer'
        href='https://pos.toasttab.com/terms-of-service'
      >
        Terms of Service
      </a>
      {' | '}
      <a
        className='text-secondary'
        target='_blank'
        rel='noreferrer'
        href='https://pos.toasttab.com/cookie-policy'
      >
        Cookie Policy
      </a>
    </div>
  )
}

export default Footer

import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import * as React from 'react'
import { PhoneNumberEntry } from './PhoneNumberEntry'
import { GuestCodeEntry } from './GuestCodeEntry'
import { GuestDataEntry } from './GuestDataEntry'
import { useLinkGuestProfile } from '../../api/guest/useLinkGuestProfile'
import { Reservation } from '../../api/bookings/getBooking'
import { LoadingView } from '../LoadingView/LoadingView'
import { useNavigate } from 'react-router'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useShowGuestAccounts } from './flags'

enum SignInStep {
  PhoneEntry,
  CodeEntry,
  ContactEntry
}

type GuestAccountLoginProps = {
  setLoginStepCount: React.Dispatch<React.SetStateAction<number>>
  setIsLoginModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsUpdateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setIsCancelModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  booking?: Reservation
  targetRoute: string | null
  fromBookingStep: boolean
  fromModifyButton: boolean
  fromCancelButton: boolean
  setBookingPreviousState?: Function
  restaurant: RestaurantInfo
}

export const GuestAccountLogin = ({
  booking,
  setLoginStepCount,
  setIsLoginModalOpen,
  setIsUpdateModalOpen,
  setIsCancelModalOpen,
  targetRoute,
  fromBookingStep,
  fromModifyButton,
  fromCancelButton,
  setBookingPreviousState,
  restaurant
}: GuestAccountLoginProps) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const { restaurantGuid } = window.TT_GLOBALS
  const navigate = useNavigate()
  const [signInStep, setSignInStep] = React.useState(SignInStep.PhoneEntry)
  const [formattedPhoneNumber, setFormattedPhoneNumber] = React.useState<
    string | null
  >(null)
  const [guestDataToCollect, setGuestDataToCollect] = React.useState<string[]>(
    []
  )
  const { isAuthenticated, setIsAuthenticated } = useAuth()
  const linkGuestProfile = useLinkGuestProfile()

  if (isAuthenticated || !showGuestAccounts) {
    return <LoadingView />
  }

  return (
    <>
      {signInStep === SignInStep.PhoneEntry && (
        <PhoneNumberEntry
          // eslint-disable-next-line @typescript-eslint/no-shadow
          startPasswordlessSucceeded={(formattedPhoneNumber) => {
            setFormattedPhoneNumber(formattedPhoneNumber)
            setSignInStep(SignInStep.CodeEntry)
          }}
          booking={booking}
          fromBookingStep={fromBookingStep}
          restaurant={restaurant}
        />
      )}
      {signInStep === SignInStep.CodeEntry && (
        <GuestCodeEntry
          formattedPhoneNumber={formattedPhoneNumber}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          confirmPasswordlessSucceeded={(guest) => {
            const newGuestDataToCollect =
              guest?.profileProgress?.incompleteContactFields || []
            if (newGuestDataToCollect.length >= 1) {
              setGuestDataToCollect(newGuestDataToCollect)
              setSignInStep(SignInStep.ContactEntry)
            } else {
              setSignInStep(SignInStep.PhoneEntry)
              setIsAuthenticated(true)
              linkGuestProfile.mutate({
                phoneNumber: formattedPhoneNumber || '',
                restaurantGuid: restaurantGuid
              })
              setLoginStepCount(1)
              setIsLoginModalOpen(false)
              if (targetRoute) {
                navigate(targetRoute)
              }
              if (
                fromModifyButton &&
                setIsUpdateModalOpen &&
                setBookingPreviousState
              ) {
                setIsUpdateModalOpen(true)
                setBookingPreviousState(null)
              } else if (fromCancelButton && setIsCancelModalOpen) {
                setIsCancelModalOpen(true)
              }
            }
          }}
          restaurant={restaurant}
        />
      )}
      {signInStep === SignInStep.ContactEntry && (
        <GuestDataEntry
          guestDataToCollect={guestDataToCollect}
          guestContactCollectionSucceeded={() => {
            setSignInStep(SignInStep.PhoneEntry)
            setIsAuthenticated(true)
            linkGuestProfile.mutate({
              phoneNumber: formattedPhoneNumber || '',
              restaurantGuid: restaurantGuid
            })
            setLoginStepCount(1)
            setIsLoginModalOpen(false)
            if (targetRoute) {
              navigate(targetRoute)
            }
          }}
          booking={booking}
          fromBookingStep={fromBookingStep}
          restaurant={restaurant}
        />
      )}
    </>
  )
}

import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import {
  CalendarTodayIcon,
  GuestIcon,
  InfoIcon,
  ScheduleIcon,
  SparkleIcon
} from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useIntlProps } from 'banquet-runtime-modules'
import cx from 'classnames'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetAvailabilities } from '../../api/availabilities/useGetAvailabilities'
import { BookingType } from '../../api/baseModels'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useReservationInfo } from '../../hooks/useReservationInfo'
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { formatAvailabilities } from '../../utils/formatAvailabilities'
import { ContentWrapper } from '../ContentWrapper'
import { CreditCardFormProvider } from '../CreditCardForm/CreditCardFormProvider'
import { DateTimeError } from '../DateTimeError'
import { Footer } from '../Footer'
import { Frame } from '../Frame/Frame'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { ReservationBookModal } from '../ReservationBookModal/ReservationBookModal'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { TextFooter } from '../TextFooter/TextFooter'
import { UpdateSearchModal } from './UpdateSearchModal'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

type ReservationDetailEntryViewProps = {
  restaurant: RestaurantInfo
}

export const ReservationDetailEntryView = ({
  restaurant
}: ReservationDetailEntryViewProps) => {
  const formatCurrency = useFormatCurrency()
  const { shortUrl } = useRestaurantContext()
  const { setSelectedAvailability } = useFindReserveContext()
  const [openUpdateSearchModal, setOpenUpdateSearchModal] = useState(false)
  const [showExpired, setShowExpired] = useState(false)
  const [openReservationBookModal, setOpenReservationBookModal] =
    useState(false)
  const { language: locale } = useIntlProps()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useQueryParam()
  const updateQueryParams = useUpdateQueryParams()

  const defaultPartySizeIfNoneChosen = Math.min(2, restaurant.maxPartySize)
  const partySizeParam = Number(encodeURI(params.get('partySize') || ''))
  // if user manipulates partySize param to 999999, we adjust to maxPartySize set for restaurant
  // if user manipulates partySize param to 0, we adjust to defaultPartySizeIfNoneChosen
  const partySize = Math.max(
    Math.min(
      partySizeParam || defaultPartySizeIfNoneChosen,
      restaurant.maxPartySize
    ),
    restaurant.minPartySize
  )

  const dateTimeString = params.get('dateTime') || ''
  const isDateTimeValid = DateTime.fromISO(dateTimeString).toUTC().isValid
  const defaultDateTime = DateTime.now().startOf('day').plus({ hours: 12 })
  const utcDateTime = isDateTimeValid
    ? DateTime.fromISO(dateTimeString).toUTC()
    : defaultDateTime.toUTC()

  const startTime = utcDateTime.minus({ hours: 2, minutes: 30 })
  const endTime = utcDateTime.plus({ hours: 2, minutes: 30 })
  const startTimeString = startTime.toString().replace('Z', '+00:00')
  const endTimeString = endTime.toString().replace('Z', '+00:00')

  const {
    blockedReason,
    hasSpecialDateInfo,
    hoursInAdvanced,
    isAfterHours,
    isBlocked,
    isRestaurantClosed,
    shouldShowHoursInAdvance,
    shouldShowInfoPanel,
    shouldShowSpecialHours,
    specialDateDescription,
    specialDateName,
    specialHours
  } = useReservationInfo(restaurant, DateTime.fromISO(dateTimeString))

  const {
    data: availabilities,
    isLoading,
    isError
  } = useGetAvailabilities(
    restaurant.guid,
    startTimeString,
    endTimeString,
    partySize,
    Boolean(partySize)
  )

  const {
    serviceAreaGroupNameToGuid,
    serviceAreaGroupNameToTimes,
    serviceAreaGroupNameToTimeToDepositAmount
  } = formatAvailabilities(availabilities)

  useEffect(() => {
    // if user omits OR manipulates partySize param to be not a number, set default party size
    if (!partySizeParam) {
      updateQueryParams({
        partySize: defaultPartySizeIfNoneChosen.toString()
      })
      return
    }
    if (!isDateTimeValid) {
      updateQueryParams({
        dateTime: defaultDateTime.toISO()
      })
    }
    // if user manipulates partySize param to a number outside of minPartySize to maxPartySize range
    // set to either minPartySize or maxPartySize
    if (partySizeParam !== partySize) {
      updateQueryParams({
        partySize: partySize.toString()
      })
    }
  }, [
    defaultPartySizeIfNoneChosen,
    partySizeParam,
    partySize,
    utcDateTime,
    updateQueryParams,
    isDateTimeValid,
    defaultDateTime
  ])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const paramValue = searchParams.get('expired')

    if (paramValue === 'true') {
      setShowExpired(true)
      window.scrollTo(0, 0)
    }
  }, [location])

  // if online reservations is disabled, redirect user back to findTime page
  // where they'll see a reservations are no longer enabled message.
  useEffect(() => {
    if (shortUrl && !restaurant.onlineReservationsEnabled) {
      const findTimeUrl = `/${shortUrl}/findTime`
      navigate(findTimeUrl, { replace: true })
    }
  }, [navigate, shortUrl, restaurant.onlineReservationsEnabled])

  if (!partySize || !isDateTimeValid) {
    return (
      <Frame restaurant={restaurant}>
        <MerryGoRound />
      </Frame>
    )
  }

  const handleRemoveParam = () => {
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.get('expired')) {
      searchParams.delete('expired')
      const newSearch = searchParams.toString()

      navigate({
        pathname: location.pathname,
        search: newSearch ? `?${newSearch}` : ''
      })
    }
  }

  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant)

  const showPrimaryMessage =
    isBlocked ||
    isRestaurantClosed ||
    shouldShowSpecialHours ||
    isAfterHours ||
    (hasSpecialDateInfo && specialDateName !== '') ||
    (hasSpecialDateInfo && specialDateDescription !== '')

  return (
    <Frame restaurant={restaurant}>
      <>
        {showExpired && (
          <Alert
            variant='error'
            className='w-full mb-4 md:mb-0'
            onDismiss={() => {
              setShowExpired(false)
              handleRemoveParam()
            }}
          >
            You’ve run out of time to book your reservation.
          </Alert>
        )}
      </>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          <div className='flex flex-col gap-4 md:gap-8'>
            <div className='flex flex-col gap-4'>
              <h1 className='type-large text-dark-gray font-semibold md:mt-4'>
                Book a reservation
              </h1>
              <button
                type='button'
                className='focus:shadow-selected hover:bg-darken-4 fcous:bg-darken-4 rounded-lg w-full'
                onClick={() => {
                  setOpenUpdateSearchModal(true)
                }}
              >
                <div className='grid grid-cols-3 shadow-lg rounded-lg py-2'>
                  <div className='flex justify-center gap-2 border-r'>
                    <CalendarTodayIcon aria-label='Selected date' />
                    <span className='font-semibold'>
                      {format(
                        new Date(dateTimeString),
                        Formats.date.medium_without_year,
                        { locale: locale, timeZone: restaurant.timezone }
                      )}
                    </span>
                  </div>
                  <div className='flex justify-center gap-2 border-r'>
                    <ScheduleIcon aria-label='Selected time' />
                    <span className='font-semibold'>
                      {format(new Date(dateTimeString), Formats.time.short, {
                        locale: locale,
                        timeZone: restaurant.timezone
                      })}
                    </span>
                  </div>
                  <div className='flex justify-center gap-2'>
                    <GuestIcon aria-label='Party size' />
                    <span className='font-semibold'>{partySize}</span>
                  </div>
                </div>
              </button>
              {shouldShowInfoPanel && (
                <div className='flex flex-col gap-1'>
                  {isBlocked && (
                    <div className='text-default font-normal type-default'>
                      {blockedReason}
                    </div>
                  )}

                  {shouldShowSpecialHours && (
                    <div className='text-default font-normal type-default'>
                      Special reservation hours for this date:
                      {specialHours.map(({ start, end }, index) => (
                        <React.Fragment key={`${start}${end}`}>
                          {index !== 0 && ' and '}
                          <span className='text-brand-75'>
                            {' '}
                            {start}
                            {start !== end && ` - ${end}`}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  )}

                  {isRestaurantClosed && (
                    <div className='text-default font-normal type-default'>
                      The restaurant is not accepting online reservations for
                      this date.
                    </div>
                  )}

                  {isAfterHours && (
                    <div className='text-default font-normal type-default'>
                      The restaurant is not accepting reservations for this
                      time. Please call the restaurant or choose another date.
                    </div>
                  )}

                  {hasSpecialDateInfo && specialDateName !== '' && (
                    <div className='flex flex-row gap-1.5 items-center'>
                      <SparkleIcon size='sm' accessibility='decorative' />
                      <div className='text-default font-semibold type-default align-center'>
                        {specialDateName}
                      </div>
                    </div>
                  )}

                  {hasSpecialDateInfo && specialDateDescription !== '' && (
                    <div className='text-default font-normal type-default'>
                      <NewLineFormatter text={specialDateDescription} />
                    </div>
                  )}

                  {/* Show divider */}
                  {showPrimaryMessage && shouldShowHoursInAdvance && (
                    <div className='border-b mb-2 pt-2' />
                  )}

                  {shouldShowHoursInAdvance && (
                    <div className='type-caption text-secondary flex gap-1'>
                      <InfoIcon size='xs' accessibility='decorative' />
                      <div className='text-secondary font-normal type-caption pt-0.5'>
                        Online reservations must be made at least{' '}
                        {hoursInAdvanced}{' '}
                        {hoursInAdvanced === 1 ? 'hour' : 'hours'} before the
                        reservation start time.
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isError ||
                (availabilities?.length === 0 && (
                  <DateTimeError subtext='No reservations available near this time. Try selecting a different time slot.' />
                ))}
              {isLoading && (
                <div className='h-16 flex justify-center items-center w-full'>
                  <MerryGoRound />
                </div>
              )}
            </div>

            {availabilities?.length !== 0 && (
              <div className='-mx-4 sm:mx-0'>
                {[...serviceAreaGroupNameToTimes.entries()].map(
                  ([serviceAreaGroupName, times], index, arr) => (
                    <div
                      key={serviceAreaGroupName}
                      className='flex flex-col gap-4'
                    >
                      <h2 className='font-semibold px-4 sm:px-0'>
                        {serviceAreaGroupName}
                      </h2>
                      <div
                        className={cx(
                          'flex gap-2 md:gap-4 border-b pb-4 whitespace-nowrap overflow-x-auto no-scrollbar sm:flex-wrap sm:overflow-x-visible sm:whitespace-normal',
                          { 'mb-4 ': index < arr.length - 1 }
                        )}
                      >
                        {[...times]
                          .sort()
                          .map((timeSlot, timeSlotIndex, timeSlots) => {
                            const deposit =
                              serviceAreaGroupNameToTimeToDepositAmount
                                ?.get(serviceAreaGroupName)
                                ?.get(timeSlot) || null
                            const depositAmount =
                              deposit?.strategy?.actualAmount
                            const formattedTime = format(
                              new Date(timeSlot),
                              Formats.time.short,
                              { locale: locale, timeZone: restaurant.timezone }
                            )
                            return (
                              <div key={timeSlot} className='flex flex-col'>
                                <Button
                                  variant='secondary'
                                  size='sm'
                                  className={cx('w-[100px]', {
                                    'ml-4 sm:ml-0': timeSlotIndex === 0,
                                    'mr-4 sm:mr-0':
                                      timeSlotIndex === timeSlots.length - 1
                                  })}
                                  onClick={() => {
                                    setSelectedAvailability!({
                                      partySize,
                                      deposit,
                                      datetime: timeSlot,
                                      serviceAreaGroupName,
                                      serviceAreaGroupGuid:
                                        serviceAreaGroupNameToGuid.get(
                                          serviceAreaGroupName
                                        ) || ''
                                    })
                                    setOpenReservationBookModal(true)
                                    handleRemoveParam()
                                  }}
                                  testId={`TimeSlot-${serviceAreaGroupName}-${formattedTime}`}
                                >
                                  {formattedTime}
                                </Button>
                                {depositAmount && (
                                  <span className='type-caption text-center mt-0.5'>
                                    {formatCurrency(depositAmount)} Deposit
                                  </span>
                                )}
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            <TextFooter
              restaurant={restaurant}
              bookingType={BookingType.RESERVATION}
            />
          </div>
        </div>
        <div className='flex flex-col relative mt-4 md:w-1/3 lg:w-96'>
          <RestaurantLocation
            phone={restaurant.phoneNumber}
            site={restaurant.websiteUrl}
            address={addressWithoutRestaurantName}
          />
        </div>
      </ContentWrapper>
      <Footer restaurant={restaurant} />

      <>
        {openUpdateSearchModal && (
          <UpdateSearchModal
            restaurant={restaurant}
            onRequestClose={() => {
              setOpenUpdateSearchModal(false)
            }}
          />
        )}
      </>

      <CreditCardFormProvider>
        <ReservationBookModal
          restaurant={restaurant}
          onRequestClose={() => {
            setOpenReservationBookModal(false)
          }}
          isOpen={openReservationBookModal}
        />
      </CreditCardFormProvider>
    </Frame>
  )
}

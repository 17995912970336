import React from 'react'

export const TimeNotFound = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 245 120'
    width={168}
    height={82}
    fill='none'
    {...props}
  >
    <path
      stroke='#E5E5E5'
      strokeLinecap='square'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M172.298 69.376h23.212M172.298 87.003h23.212M172.298 78.19h13.927'
    />
    <path
      stroke='#E5E5E5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M170.889 41.617h22.512l17.945 16.996v51.139'
    />
    <path
      stroke='#E5E5E5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M211.346 58.65h-17.945V42.255'
    />
    <path
      stroke='silver'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M56.036 66.155V18.128c0-2.401.955-4.703 2.656-6.4a9.078 9.078 0 0 1 6.413-2.652h96.715a9.08 9.08 0 0 1 6.413 2.651 9.041 9.041 0 0 1 2.656 6.4v83.789a9.04 9.04 0 0 1-2.656 6.4 9.077 9.077 0 0 1-6.413 2.651H74.03M80.856 16.325V1.827M102.594 16.325V1.827M124.332 16.325V1.827M146.069 16.325V1.827M56.036 33.943h114.853'
    />
    <path
      stroke='#E5E5E5'
      strokeLinecap='square'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M63.53 66.372V41.618h99.865v59.484a2.194 2.194 0 0 1-.643 1.549 2.203 2.203 0 0 1-1.552.641H80.35M88.497 41.618v61.674M113.463 41.618v61.674M138.429 41.618v61.674M163.394 57.037H63.53M163.394 72.455H74.031M163.394 87.874h-81.3'
    />
    <path
      stroke='silver'
      strokeWidth={2.437}
      d='M74.03 110.967h170.297M.673 110.967h37.724'
    />
    <path
      stroke='url(#a)'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M56.036 118.173a26.094 26.094 0 0 0 14.477-4.384 26.022 26.022 0 0 0 9.598-11.672 25.965 25.965 0 0 0 1.483-15.028 25.994 25.994 0 0 0-7.132-13.317 26.075 26.075 0 0 0-13.342-7.118 26.104 26.104 0 0 0-15.056 1.48 26.047 26.047 0 0 0-11.695 9.58 25.974 25.974 0 0 0 3.24 32.841 26.086 26.086 0 0 0 18.427 7.618v0Z'
    />
    <path
      stroke='#E84D15'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.44}
      d='M29.977 92.164h10.424'
    />
    <path
      stroke='#944E5E'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M56.036 66.154v10.404'
    />
    <path
      stroke='url(#b)'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M56.036 97.365v13.939'
    />
    <path
      stroke='#4F4F9B'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M71.671 92.164h10.424'
    />
    <path
      stroke='url(#c)'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='m58.866 89.34 7.594-7.58'
    />
    <path
      stroke='url(#d)'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.437}
      d='M56.036 96.076a3.916 3.916 0 0 0 3.92-3.912 3.916 3.916 0 0 0-3.92-3.913 3.916 3.916 0 0 0-3.92 3.913 3.916 3.916 0 0 0 3.92 3.912Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1={26.76}
        x2={98.274}
        y1={189.697}
        y2={186.585}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset={1} stopColor='#2B4FB9' />
      </linearGradient>
      <linearGradient
        id='b'
        x1={55.974}
        x2={57.349}
        y1={130.471}
        y2={130.466}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset={1} stopColor='#2B4FB9' />
      </linearGradient>
      <linearGradient
        id='c'
        x1={58.397}
        x2={68.817}
        y1={99.76}
        y2={99.307}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#684F84' />
        <stop offset={1} stopColor='#2B4FB9' />
      </linearGradient>
      <linearGradient
        id='d'
        x1={51.632}
        x2={62.39}
        y1={106.835}
        y2={106.367}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset={1} stopColor='#2B4FB9' />
      </linearGradient>
    </defs>
  </svg>
)

import React from 'react'
import { Select } from '@toasttab/buffet-pui-select'
import { ModifySubComponentProps } from './models'
import { PlaceSettingIcon } from '@toasttab/buffet-pui-icons'

interface DiningAreaSelectorProps extends ModifySubComponentProps {
  serviceAreaGroupNameToGuid: Map<string, string>
  disabled: boolean
}

export const DiningAreaSelector = ({
  formik,
  serviceAreaGroupNameToGuid,
  disabled
}: DiningAreaSelectorProps) => {
  return (
    <Select
      value={formik.values.serviceAreaGroupName}
      onChange={(serviceArea) => {
        const serviceAreaGroupGuid = serviceArea
          ? serviceAreaGroupNameToGuid.get(serviceArea)
            ? serviceAreaGroupNameToGuid.get(serviceArea)
            : formik.values.serviceAreaGroupGuid
          : formik.values.serviceAreaGroupGuid
        formik.setFieldValue('serviceAreaGroupName', serviceArea)
        formik.setFieldValue('serviceAreaGroupGuid', serviceAreaGroupGuid)
      }}
      label='Dining Area'
      name='externalServiceArea'
      containerClassName='w-full'
      iconLeft={<PlaceSettingIcon className='-ml-0.5 md:ml-0' />}
      options={[...serviceAreaGroupNameToGuid.keys()]}
      disabled={disabled}
    />
  )
}

import React from 'react'
import { ModifySubComponentProps } from './models'
import { TextInputField } from '@toasttab/buffet-pui-forms'

export const SpecialRequestInputField = ({
  formik
}: ModifySubComponentProps) => {
  return (
    <TextInputField
      containerClassName='w-full'
      label={
        <div className='flex items-baseline'>
          Special requests
          <span className='type-subhead text-gray-75 ml-1 pr-1 font-normal'>
            (optional)
          </span>
        </div>
      }
      name='specialRequests'
      placeholder='Anything you want to let us know?'
      invalid={
        formik.touched.specialRequests && Boolean(formik.errors.specialRequests)
      }
      errorText={
        formik.touched.specialRequests && formik.errors.specialRequests
      }
    />
  )
}

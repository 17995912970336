import React from 'react'

export const TableSeated = () => (
  <svg
    width='249'
    height='120'
    viewBox='0 0 249 120'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M57.929 76.1334V84.1661'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinejoin='round'
    />
    <path
      d='M54.7942 84.1661C52.1368 73.4786 41.3709 77.631 41.3709 77.631C41.3709 85.6637 46.7539 86.4125 46.7539 86.4125'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinejoin='round'
    />
    <path
      d='M56.498 117.726C65.9059 117.726 73.5326 110.107 73.5326 100.708C73.5326 91.309 65.9059 83.6896 56.498 83.6896C47.0901 83.6896 39.4634 91.309 39.4634 100.708C39.4634 110.107 47.0901 117.726 56.498 117.726Z'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinejoin='round'
    />
    <path
      d='M163.271 104.588C167.699 112.417 176.081 117.658 186.029 117.658C200.065 117.658 211.376 105.95 211.376 91.5179H173.219'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M187.8 86.9571L192.638 82.8727'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M187.324 77.9033L184.121 72.4574'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M191.003 79.3329L195.5 75.5208'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M206.13 87.4335L205.312 81.1708'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M178.193 86.9571L176.353 84.3022'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M180.986 78.9244L185.484 83.4173'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M200.747 85.7318L197.204 80.4901'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M0.147644 118.135H248.852'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
    />
    <path
      d='M80.7554 79.1287H177.239'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M175.263 50.1974L166.405 66.3989'
      stroke='#2B4FB9'
      strokeWidth='2.48705'
    />
    <path
      d='M187.119 21.7428L174.037 45.7046C173.151 47.3384 173.764 49.3806 175.399 50.3336C177.035 51.2186 179.079 50.6059 180.033 48.9721L193.115 25.0103C194.001 23.3765 193.388 21.3343 191.752 20.3813C190.049 19.4283 188.005 20.0409 187.119 21.7428Z'
      stroke='#2B4FB9'
      strokeWidth='2.48705'
    />
    <path
      d='M80.0738 23.1042L69.6486 33.1791'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M58.2013 20.4494L67.7407 11.2595C68.9671 10.0341 71.2838 10.5787 72.5103 12.3486L82.9355 27.2567C83.8894 28.6182 83.8213 30.1839 82.8674 31.1369L77.4163 36.3786C76.4624 37.2635 74.827 37.2635 73.5324 36.2424L59.019 25.3507C57.3836 23.9892 56.9748 21.6066 58.2013 20.4494Z'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
    />
    <path
      d='M83.4123 44.4112C83.751 44.4112 84.0256 44.1369 84.0256 43.7986C84.0256 43.4602 83.751 43.1859 83.4123 43.1859C83.0737 43.1859 82.7991 43.4602 82.7991 43.7986C82.7991 44.1369 83.0737 44.4112 83.4123 44.4112Z'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M100.924 65.3778C101.263 65.3778 101.537 65.1035 101.537 64.7651C101.537 64.4268 101.263 64.1525 100.924 64.1525C100.585 64.1525 100.311 64.4268 100.311 64.7651C100.311 65.1035 100.585 65.3778 100.924 65.3778Z'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M88.4545 59.2512C88.7932 59.2512 89.0677 58.9769 89.0677 58.6386C89.0677 58.3002 88.7932 58.0259 88.4545 58.0259C88.1158 58.0259 87.8412 58.3002 87.8412 58.6386C87.8412 58.9769 88.1158 59.2512 88.4545 59.2512Z'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M94.3144 48.904C94.6531 48.904 94.9277 48.6297 94.9277 48.2914C94.9277 47.953 94.6531 47.6787 94.3144 47.6787C93.9758 47.6787 93.7012 47.953 93.7012 48.2914C93.7012 48.6297 93.9758 48.904 94.3144 48.904Z'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M137.378 24.9422C140.05 24.9422 142.216 22.7783 142.216 20.109C142.216 17.4397 140.05 15.2758 137.378 15.2758C134.706 15.2758 132.54 17.4397 132.54 20.109C132.54 22.7783 134.706 24.9422 137.378 24.9422Z'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
    />
    <path
      d='M144.873 10.7148C144.873 10.7148 139.831 10.851 138.605 15.4119'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
    />
    <path
      d='M161.499 25.9634C161.499 25.9634 161.295 14.1866 177.103 3.63525'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M169.948 9.82983C169.948 9.82983 172.742 10.8509 175.195 10.2383'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M166.269 14.7992C166.269 14.7992 168.313 16.2287 171.515 15.4799'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M162.317 21.6066C162.317 21.6066 165.724 22.6958 167.7 22.2192'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M171.175 7.85571C171.175 7.85571 170.153 5.47313 171.515 1.86523'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M165.996 12.3487C165.996 12.3487 163.339 9.89801 164.565 5.8136'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M163.066 19.4283C163.066 19.4283 160 17.9987 160.545 14.527'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M106.511 16.3649L115.165 47.2702'
      stroke='#FF4C00'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M102.832 22.9L109.442 26.576'
      stroke='#FF4C00'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M114.552 25.1464L110.872 31.7495'
      stroke='#FF4C00'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.65 6.01782C100.992 8.74076 99.0845 12.0764 98.1987 15.7523C97.2448 19.4283 97.3811 23.3085 98.4031 26.9164C99.3571 30.5923 101.333 33.9279 103.99 36.5828C106.716 39.2376 110.055 41.1437 113.734 42.0287C116.392 39.3057 118.3 35.9701 119.185 32.2942C120.139 28.6182 120.003 24.738 118.981 21.1301C118.027 17.4542 116.051 14.1186 113.394 11.4637C110.668 8.87691 107.329 6.97085 103.65 6.01782Z'
      stroke='#FF4C00'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M148.28 57.8897L137.651 47.2703'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M133.563 57.7536L147.599 57.209L148.212 43.1178'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M140.513 65.6502L158.978 47.2023C153.118 41.3479 143.579 41.3479 137.719 47.2023C132.677 52.2397 131.927 60.0001 135.607 65.7863'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M139.899 66.2628L164.702 41.4841C155.639 32.4303 140.921 32.4303 131.859 41.4841C125.113 48.2233 123.41 57.9578 126.612 66.2628'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M80.0055 79.1287H176.421'
      stroke='#E5E5E5'
      strokeWidth='2.48705'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
    <path
      d='M178.806 66.2628H77.5527C77.5527 94.6494 99.9702 117.658 128.997 117.658C156.525 117.658 178.806 94.6494 178.806 66.2628Z'
      stroke='#C0C0C0'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M178.806 66.2628H77.5527C77.5527 94.6494 99.9702 117.658 128.997 117.658C156.525 117.658 178.806 94.6494 178.806 66.2628Z'
      stroke='url(#paint0_linear_2003_46643)'
      strokeWidth='2.48705'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_2003_46643'
        x1='84.0521'
        y1='66.2628'
        x2='209.17'
        y2='83.9171'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset='1' stopColor='#2B4FB9' />
      </linearGradient>
    </defs>
  </svg>
)

import React from 'react'
import { DatePicker } from '@toasttab/buffet-pui-date-picker'
import { DateTime } from 'luxon'
import { FormikProps } from 'formik'
import { ReservationUpdateFormProps } from './models'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { Formats } from '@toasttab/buffet-pui-date-utilities'

const oneYearFromToday = DateTime.local().plus({ year: 1 }).toJSDate()

interface ModifyDatePickerProps {
  formik: FormikProps<ReservationUpdateFormProps>
  handleTimeChange: Function
  setHasDateChanged: Function
  disabled: boolean
  locale: Locale
}

export const ModifyDatePicker = ({
  formik,
  handleTimeChange,
  setHasDateChanged,
  disabled,
  locale
}: ModifyDatePickerProps) => {
  return (
    <DatePicker
      label='Date'
      containerClassName='w-full'
      value={new Date(formik.values.date.toString())}
      onChange={(date) => {
        if (date) {
          handleTimeChange(
            date,
            formik.values.datetime !== ''
              ? formik.values.datetime
              : formik.initialValues.datetime,
            formik.setFieldValue
          )
          setHasDateChanged?.(true)
        }
      }}
      fromDate={new Date()}
      toDate={oneYearFromToday}
      formatValue={Formats.date.short}
      disabled={disabled}
      locale={locale}
    />
  )
}

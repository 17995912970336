import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { DepositPolicyInfo } from './DepositPolicyInfo'
import React from 'react'
import { LoginButton } from '../GuestAccount/LoginButton'
import { DividerLineWithOr } from '../GuestAccount/DividerLine'
import { AnonymousBookingButton } from '../GuestAccount/AnonymousBookingButton'

type ReservationAuthMenuProps = {
  loginStepCount: number
  setLoginStepCount: React.Dispatch<React.SetStateAction<number>>
  stepCount: number
  setStepCount: React.Dispatch<React.SetStateAction<number>>
}

export const ReservationAuthMenu = ({
  loginStepCount,
  setLoginStepCount,
  stepCount,
  setStepCount
}: ReservationAuthMenuProps) => {
  return (
    <div>
      <DepositPolicyInfo />
      <ToastBrand size='xxs' strokeColor='text-brand-50' />
      <h3 className='type-large font-semibold my-4'>
        Sign in for express booking.
      </h3>
      <div className='flex flex-col gap-3 mb-6'>
        <p>
          With a Toast account, you can book quicker and easily manage this
          reservation and any future bookings made through Toast.
        </p>
        <p>Don't have an account? No worries, we will help you create one.</p>
      </div>
      <LoginButton
        setLoginStepCount={setLoginStepCount}
        setStepCount={setStepCount}
      />
      <DividerLineWithOr />
      <AnonymousBookingButton
        loginStepCount={loginStepCount}
        setLoginStepCount={setLoginStepCount}
        stepCount={stepCount}
        setStepCount={setStepCount}
      />
    </div>
  )
}

import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

export const AccountCreationLegalText = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const isCalifornia = restaurant.country === 'US' && restaurant.state === 'CA'
  return (
    <div className='flex flex-col gap-3'>
      <p>
        By clicking “Continue”, you are creating a Toast Account. You agree to
        Toast&apos;s{' '}
        <a
          className='text-link hover:text-link-hover'
          target='_blank'
          rel='noreferrer'
          href='https://pos.toasttab.com/terms-of-service#diner-tos'
        >
          Guest Terms of Service
        </a>{' '}
        and acknowledge your information will be processed pursuant to
        Toast&apos;s{' '}
        <a
          className='text-link hover:text-link-hover'
          target='_blank'
          rel='noreferrer'
          href='https://pos.toasttab.com/privacy'
        >
          Privacy Statement
        </a>
        .
        {isCalifornia && (
          <>
            {' '}
            Additional info for California residents available{' '}
            <a
              className='text-link hover:text-link-hover'
              target='_blank'
              rel='noreferrer'
              href='https://pos.toasttab.com/privacy#addendum-a'
            >
              here
            </a>
            .
          </>
        )}
      </p>
    </div>
  )
}

import { Availability, Deposit } from '../api/availabilities/getAvailabilities'

export function formatAvailabilities(
  availabilities: Availability[] | undefined,
  isExistingBookingWithoutDeposit = false
) {
  const serviceAreaGroupNameToGuid = new Map<string, string>()
  const serviceAreaGroupNameToTimes = new Map<string, Set<string>>()
  const serviceAreaGroupNameToTimeToDepositAmount = new Map<
    string,
    Map<string, Deposit | null>
  >()

  if (availabilities) {
    availabilities.forEach((availability) => {
      availability.serviceAreas.forEach((serviceArea) => {
        // if a guest is modifying a reservation on a dining area without a deposit
        // make sure not to return any dining areas or time slots with a deposit
        if (isExistingBookingWithoutDeposit && serviceArea.deposit) {
          return
        }
        // eslint-disable-next-line max-len
        const serviceAreaGroupName = serviceArea.serviceAreaGroup.name
        if (serviceAreaGroupName) {
          serviceAreaGroupNameToGuid.set(
            serviceAreaGroupName,
            serviceArea.serviceAreaGroup.guid
          )

          if (serviceAreaGroupNameToTimes.has(serviceAreaGroupName)) {
            const times = serviceAreaGroupNameToTimes.get(serviceAreaGroupName)!
            times.add(availability.datetime)
          } else {
            serviceAreaGroupNameToTimes.set(
              serviceAreaGroupName,
              new Set([availability.datetime])
            )
          }

          if (
            serviceAreaGroupNameToTimeToDepositAmount.has(serviceAreaGroupName)
          ) {
            const timesToDepositAmount =
              serviceAreaGroupNameToTimeToDepositAmount.get(
                serviceAreaGroupName
              )
            timesToDepositAmount?.set(
              availability.datetime,
              serviceArea.deposit
            )
          } else {
            serviceAreaGroupNameToTimeToDepositAmount.set(
              serviceAreaGroupName,
              new Map([[availability.datetime, serviceArea.deposit]])
            )
          }
        }
      })
    })
  }
  return {
    serviceAreaGroupNameToGuid,
    serviceAreaGroupNameToTimes,
    serviceAreaGroupNameToTimeToDepositAmount
  }
}

import React from 'react'
import './App.css'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { AppContainer } from './components/AppContainer/AppContainer'
import { TranslationProvider } from '@local/translations'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { useIntlProps } from 'banquet-runtime-modules'
import { AuthProvider } from '@toasttab/do-secundo-guest-authentication'
import { baseAuthUrl } from './api/guest/gateway'
import { RestaurantProvider } from './contexts/RestaurantContext'

export const queryClient = new QueryClient()

export function App() {
  const i18n = useIntlProps()
  // When available, load this from Banquet
  const cdnUrl =
    '//cdn.toasttab.com/static/34d9bc8b2e7d99006ecfcd8a8ba5e3dc5c75d126/projects/intgs/translations'

  return (
    <TranslationProvider i18n={i18n.i18next} cdnUrl={cdnUrl}>
      <SnackBarProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider gatewayOrigin={baseAuthUrl}>
            <RestaurantProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path='/restaurants/:restaurantGuid/*'
                    element={<AppContainer />}
                  />
                  <Route path='/:shortUrl/*' element={<AppContainer />} />
                </Routes>
              </BrowserRouter>
            </RestaurantProvider>
            <div id='banquetPortalsContainer' />
          </AuthProvider>
        </QueryClientProvider>
      </SnackBarProvider>
    </TranslationProvider>
  )
}

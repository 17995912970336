import React from 'react'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { DepositStrategyType } from '../../api/deposits/model'
import { Reservation } from '../../api/bookings/getBooking'
import { isPastUtcDate } from '../../utils/isPastUtcDate'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'

type DepositSummaryProps = {
  booking: Reservation
  hasDepositBeenRefunded: boolean
}

export const DepositSummary = ({
  booking,
  hasDepositBeenRefunded
}: DepositSummaryProps) => {
  const formatCurrency = useFormatCurrency()
  if (!booking.depositAmount || !booking.partySize) {
    return null
  }

  const isPerPerson =
    booking.depositBookableConfigSnapshot?.strategy.type ===
    DepositStrategyType.ByPartySize

  const totalAmount = formatCurrency(booking.depositAmount)
  const totalAmountInFull = formatCurrency(booking.depositAmount, {
    trailingZeros: true
  })

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-4 p-4 bg-gray-0 rounded-md'>
        <div>
          <h4 className='type-large font-semibold'>Deposit summary</h4>
          {isPerPerson ? (
            <p className='text-secondary'>
              {formatCurrency(
                Number(booking.depositAmount / booking.partySize)
              )}{' '}
              deposit per person.
            </p>
          ) : (
            <p className='text-secondary'>
              {formatCurrency(booking.depositAmount)} deposit per booking.
            </p>
          )}
        </div>
        <div>
          <div className='flex justify-between'>
            <p>
              Reservation
              {isPerPerson ? ` x ${booking.partySize}` : ' x 1'}
            </p>
            <p>{totalAmount}</p>
          </div>
          <div className='border-b pt-2 mb-2' />
          <div className='flex justify-between'>
            <p>Amount Paid</p>
            <p>{totalAmountInFull}</p>
          </div>
        </div>
      </div>
      {booking.depositRefundableCancellationDatetime &&
        isPastUtcDate(
          new Date(booking.depositRefundableCancellationDatetime)
        ) &&
        !hasDepositBeenRefunded && (
          <div className='flex gap-2 text-secondary type-subhead'>
            <InfoIcon /> Booking is no longer refundable.
          </div>
        )}
    </div>
  )
}

import cx from 'classnames'
import { DatePicker } from '@toasttab/buffet-pui-date-picker'
import { Select } from '@toasttab/buffet-pui-select'
import {
  GuestIcon,
  InfoIcon,
  PrepTimeIcon,
  SparkleIcon
} from '@toasttab/buffet-pui-icons'
import { Form, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import { ReservationTimeSelectionViewFormValues } from '../models'
import { ReservationTimeSelectionViewProps } from './models'
import { useTimeFinderForm } from '../../../hooks/useReservationTimeSelectionViewForm'
import { updateTimeFieldValue } from './helpers'
import { DateTimeError } from '../../DateTimeError'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { Calendar } from '@toasttab/buffet-pui-calendar'
import { useReservationInfo } from '../../../hooks/useReservationInfo'
import { useIsMobile } from '../../../hooks/useIsMobile'
import React, { useEffect, useState } from 'react'
import { useIntlProps } from 'banquet-runtime-modules'
import { Formats } from '@toasttab/buffet-pui-date-utilities'

export function ReservationTimeSelectionViewForm({
  restaurant,
  children,
  showSubmitButton = true,
  showCalendar = false,
  updateTimeFieldOnMount = false
}: ReservationTimeSelectionViewProps) {
  const formik = useFormikContext<ReservationTimeSelectionViewFormValues>()
  const isMobile = useIsMobile()
  const [hasDateChanged, setHasDateChanged] = useState(false)
  const { language: locale } = useIntlProps()

  const {
    hoursData,
    hoursOptions,
    hasSpecialDateInfo,
    hoursInAdvanced,
    isAfterHours,
    isBlocked,
    blockedReason,
    isRestaurantClosed,
    shouldShowHoursInAdvance,
    shouldShowInfoPanel,
    specialDateName
  } = useReservationInfo(restaurant, formik.values.date)

  useEffect(() => {
    if ((updateTimeFieldOnMount || hasDateChanged) && hoursData?.data) {
      updateTimeFieldValue(
        formik,
        hoursData.data,
        restaurant.timezone,
        restaurant.bookingMinHoursInAdvance
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateTimeFieldOnMount,
    hasDateChanged,
    hoursData?.data,
    restaurant.bookingMinHoursInAdvance,
    restaurant.timezone
  ])

  const { partySizes, handleDateChange } = useTimeFinderForm({
    restaurant
  })

  if (!hoursData) {
    return null
  }

  const oneYearFromToday = DateTime.local().plus({ year: 1 }).toJSDate()

  const isDateTimeInvalid =
    isRestaurantClosed || isBlocked || hoursData.isError || isAfterHours

  const isButtonDisabled =
    !formik.isValid ||
    formik.isSubmitting ||
    hoursData.isLoading ||
    isDateTimeInvalid

  const formId = 'ReservationTimeSelectionViewForm'

  const showPrimaryMessage =
    isBlocked ||
    isRestaurantClosed ||
    isAfterHours ||
    (hasSpecialDateInfo && specialDateName !== '')

  const infoPanel = (
    <div className='flex flex-col gap-1 col-span-2'>
      {hasSpecialDateInfo && specialDateName !== '' && (
        <div className='flex flex-row gap-1.5 items-center'>
          <SparkleIcon size='sm' />
          <div className='text-default font-semibold type-default leading-snug align-center'>
            {specialDateName}
          </div>
        </div>
      )}

      {isBlocked && (
        <p className='text-default font-normal type-default leading-snug'>
          {blockedReason}
        </p>
      )}

      {isRestaurantClosed && (
        <div className='text-default font-normal type-default leading-snug'>
          The restaurant is not accepting online reservations for this date.
        </div>
      )}

      {isAfterHours && (
        <div className='text-default font-normal type-default leading-snug'>
          The restaurant is not accepting reservations for this time. Please
          call the restaurant or choose another date.
        </div>
      )}

      {/* Show divider */}
      {showPrimaryMessage && shouldShowHoursInAdvance && (
        <div className='border-b mb-2 pt-2' />
      )}

      {shouldShowHoursInAdvance && (
        <div className='flex flex-row gap-1'>
          <InfoIcon size='xs' />
          <div className='text-secondary font-normal type-caption leading-snug pt-0.5'>
            Online reservations must be made at least {hoursInAdvanced}{' '}
            {hoursInAdvanced === 1 ? 'hour' : 'hours'} before the reservation
            start time.
          </div>
        </div>
      )}
    </div>
  )

  const showCalendarOnDesktop = showCalendar && !isMobile

  return (
    <Form id={formId} className='w-full'>
      <div className='flex flex-col gap-4 items-center'>
        <div
          className={cx('flex w-full justify-center', {
            'flex-col gap-2': !showCalendarOnDesktop
          })}
        >
          <div className='flex flex-col gap-2'>
            {showCalendar ? (
              <Calendar
                mode='single'
                containerClassName='flex justify-center'
                selected={new Date(formik.values.date.toString())}
                onChange={(date) => {
                  handleDateChange(date, formik.setFieldValue)
                  setHasDateChanged(true)
                }}
                fromDate={new Date()}
                defaultMonth={new Date(formik.values.date.toString())}
                toDate={oneYearFromToday}
                disabled={hoursData.isLoading}
              />
            ) : (
              <DatePicker
                label='Date'
                containerClassName='w-full'
                value={new Date(formik.values.date.toString())}
                onChange={(date) => {
                  handleDateChange(date, formik.setFieldValue)
                  setHasDateChanged(true)
                }}
                fromDate={new Date()}
                toDate={oneYearFromToday}
                locale={locale}
                disabled={hoursData.isLoading}
                formatValue={Formats.date.long}
              />
            )}
            {showCalendar && isMobile && <div className='border-b pt-2 mb-2' />}
          </div>

          <div
            className={cx('w-full gap-y-4', {
              'flex flex-col border-l pl-4': showCalendarOnDesktop,
              'grid grid-cols-2 gap-x-2': !showCalendarOnDesktop
            })}
          >
            <Select
              value={isDateTimeInvalid ? '---' : formik.values.partySize}
              onChange={(newValue) => {
                formik.setFieldValue('partySize', Number(newValue))
              }}
              label='Party Size'
              name='partySize'
              iconLeft={<GuestIcon className='-ml-0.5 md:ml-0' />}
              options={partySizes.map((op) => ({
                ...op,
                iconLeft: <GuestIcon className='-ml-0.5 md:ml-0' />
              }))}
              disabled={
                hoursData.isLoading ||
                isRestaurantClosed ||
                isAfterHours ||
                isBlocked
              }
              placeholder={isDateTimeInvalid ? '---' : undefined}
            />
            <Select
              placeholder='---'
              label='Time'
              name='time'
              value={isDateTimeInvalid ? '---' : formik.values.time}
              onChange={(newValue) => formik.setFieldValue('time', newValue)}
              options={hoursOptions.map((op) => ({
                ...op,
                iconLeft: <PrepTimeIcon className='-ml-0.5 md:ml-0' />
              }))}
              iconLeft={<PrepTimeIcon className='-ml-0.5 md:ml-0' />}
              renderItem={(arg) => (
                <li
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...arg.itemProps}
                  key={arg.item.value}
                  className={cx(
                    'px-3 type-default font-normal flex flex-row items-top py-3 md:py-2.5 cursor-pointer text-default hover:bg-darken-4',
                    { 'text-disabled': arg.item.disabled }
                  )}
                >
                  {arg.item.label}
                </li>
              )}
              disabled={hoursData.isLoading || isDateTimeInvalid || isBlocked}
            />
            {shouldShowInfoPanel && infoPanel}
          </div>
        </div>
        {showSubmitButton && (
          <SubmitButton
            form={formId}
            className='w-full'
            disabled={isButtonDisabled}
            type='submit'
            color='primary'
            size='base'
          >
            Find a table
          </SubmitButton>
        )}
        {children}
      </div>
      {hoursData.isError && (
        <DateTimeError subtext='No times available for this date. Please try again later.' />
      )}
    </Form>
  )
}

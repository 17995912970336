import React from 'react'

export const DividerLineWithOr = () => {
  return (
    <div className='w-full text-center border-b border-black border-solid leading-[0.1em] my-4'>
      <span className='text-slate-500 bg-white px-2.5'>OR</span>
    </div>
  )
}

export const DividerLine = () => {
  return (
    <div className='w-full text-center border-b border-black border-solid leading-[0.1em] my-4' />
  )
}

import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetBooking } from '../../api/bookings/useGetBooking'
import { LoadingView } from '../LoadingView/LoadingView'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { CreditCardFormProvider } from '../CreditCardForm/CreditCardFormProvider'
import { ReservationDepositView } from './ReservationDepositView'
import { Frame } from '../Frame/Frame'

type ReservationDepositContainerProps = {
  restaurant: RestaurantInfo
}

export const ReservationDepositContainer = ({
  restaurant
}: ReservationDepositContainerProps) => {
  const { bookingGuid } = useParams()
  const { data: booking } = useGetBooking(bookingGuid || '')

  if (!booking || !restaurant) {
    return <LoadingView />
  }

  return (
    <Frame restaurant={restaurant}>
      <CreditCardFormProvider>
        <ReservationDepositView restaurant={restaurant} booking={booking} />
      </CreditCardFormProvider>
    </Frame>
  )
}

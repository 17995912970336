import React, { FC } from 'react'
import { BookingType } from '../../api/baseModels'
import { BookingStatus, Guest } from '../../api/bookings/getBooking'
import { Reservation as ReservationIcon } from '../../assets/icons/Reservation'
import { TableReady as TableReadyIcon } from '../../assets/icons/TableReady'
import { TableSeated as TableSeatedIcon } from '../../assets/icons/TableSeated'
import { Restaurant as RestaurantIcon } from '../../assets/icons/Restaurant'

export interface Reservation {
  guid: string
  firstName: string
  lastName: string
  partySize: string
  phoneNumber: string
  email: string
  specialRequests: string
  diningPreference: string
  mainGuest: Guest
  expectedStartTime: string
  bookingNotes: string
  bookingType: BookingType
  bookingStatus: string
}

export interface StatusMessageSet {
  mainIcon: FC
  mainMessage: string
  subMessage: string | undefined | React.ReactNode
  displayNotice: boolean
}

export enum BookingStatusMessage {
  ALL_SET = 'All set!',
  TABLE_READY = 'Your table is ready!',
  THANKS = 'Thanks for visiting us!',
  CANCELLED = 'Cancelled',
  WAITLIST_CONFIRM = "You're on the waitlist!",
  WAITLIST_READY = 'Your table is ready!',
  WAITLIST_SEATED = 'Enjoy your meal.',
  WAITLIST_CANCEL = 'You are no longer on the waitlist.',
  WAITLIST_DONE = 'Thanks for dining with us.',
  WAITLIST_CLOSED = 'Waitlist is not available.'
}

export const statusMessageMap: Record<BookingStatus, StatusMessageSet> = {
  [BookingStatus.W_WAITING]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_NOTIFIED]: {
    mainIcon: TableReadyIcon,
    mainMessage: BookingStatusMessage.WAITLIST_READY,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_ARRIVED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_CHECKED_IN]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_LATE_ARRIVAL]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_PARTIALLY_ARRIVED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_CONFIRMED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CONFIRM,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_SEATED]: {
    mainIcon: TableSeatedIcon,
    mainMessage: BookingStatusMessage.WAITLIST_SEATED,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_CANCELLED]: {
    mainIcon: RestaurantIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CANCEL,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_NO_SHOW]: {
    mainIcon: RestaurantIcon,
    mainMessage: BookingStatusMessage.WAITLIST_CANCEL,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.W_DONE]: {
    mainIcon: RestaurantIcon,
    mainMessage: BookingStatusMessage.WAITLIST_DONE,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.R_CREATED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_CONFIRMED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_NOTIFIED]: {
    mainIcon: TableReadyIcon,
    mainMessage: BookingStatusMessage.TABLE_READY,
    subMessage: undefined,
    displayNotice: false
  },
  [BookingStatus.R_ARRIVED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_CHECKED_IN]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_LATE_ARRIVAL]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_PARTIALLY_ARRIVED]: {
    mainIcon: ReservationIcon,
    mainMessage: BookingStatusMessage.ALL_SET,
    subMessage:
      "Your table has been booked. We've sent you an SMS with the details.",
    displayNotice: false
  },
  [BookingStatus.R_SEATED]: {
    mainIcon: TableSeatedIcon,
    mainMessage: BookingStatusMessage.THANKS,
    subMessage: 'Enjoy your meal!',
    displayNotice: false
  },
  [BookingStatus.R_CANCELLED]: {
    mainIcon: RestaurantIcon,
    mainMessage: 'We have cancelled your reservation.',
    subMessage: '',
    displayNotice: false
  },

  [BookingStatus.R_NO_SHOW]: {
    mainIcon: RestaurantIcon,
    mainMessage: BookingStatusMessage.CANCELLED,
    subMessage: 'Sorry to have missed you.',
    displayNotice: false
  },
  [BookingStatus.R_DONE]: {
    mainIcon: RestaurantIcon,
    mainMessage: BookingStatusMessage.THANKS,
    subMessage: 'Hope you enjoyed your visit!',
    displayNotice: false
  }
}

export const cancelledStates = [
  BookingStatus.R_CANCELLED,
  BookingStatus.W_CANCELLED,
  BookingStatus.R_NO_SHOW,
  BookingStatus.W_NO_SHOW
]

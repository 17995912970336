import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'

import { createG2Request } from '../createRequest'

export const getWaitlistOpen = async (restaurantGuid: string) =>
  axios
    .request<NvResponse<boolean>>(
      createG2Request<never>(
        RequestURL[RequestName.GET_WAITLIST_OPEN],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { restaurantGuid }
      )
    )
    .then((response) => response.data.results[0])

import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import axios from 'axios'
import { baseAuthUrl, gatewayPath } from './gateway'
import { updateGuestContactRequest } from './updateGuestContactRequest'
import { useMutation } from 'react-query'
import { RequestType } from '../baseModels'
import { apolloHeaders, authHeaders } from './constants'

export const useUpdateGuestContact = () => {
  const { getAuthenticationHeader } = useAuth()

  const updateGuestContact = async (firstName: string, lastName: string) => {
    const authHeader = await getAuthenticationHeader()
    const response = await axios.request({
      method: RequestType.POST,
      url: `${baseAuthUrl}${gatewayPath}`,
      data: updateGuestContactRequest(firstName, lastName),
      headers: {
        ...authHeaders(authHeader),
        ...apolloHeaders
      },
      params: undefined
    })

    return response.data.data.guest
  }

  return useMutation(
    ({ firstName, lastName }: { firstName: string; lastName: string }) =>
      updateGuestContact(firstName, lastName)
  )
}

import React, { ReactElement } from 'react'
import { Image } from '@toasttab/buffet-pui-image'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'

import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { GuestAccountMenu } from '../GuestAccount/GuestAccountMenu'
import { useShowGuestAccounts } from '../GuestAccount/flags'

export interface FrameProps {
  children: ReactElement<any> | Array<ReactElement<any>>
  restaurant: RestaurantInfo
}

export function Frame({ children, restaurant }: FrameProps) {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const [openLoginModal, setOpenLoginModal] = React.useState(false)
  const [targetRoute, setTargetRoute] = React.useState<string | null>(null)
  const fallbackProfilePicUrl =
    'https://storage.googleapis.com/nv1-booking-images/Toast Placeholder Image.png'

  return (
    <>
      <div className='max-w-6xl mx-auto flex flex-row justify-between md:space-x-2 lg:space-x-3 pl-4 pr-1 py-3.5 md:pl-6 md:pr-3'>
        <div className='flex items-center'>
          <ToastBrand size='xxs' strokeColor='text-brand-50' />
        </div>
        {showGuestAccounts && (
          <GuestAccountMenu
            restaurant={restaurant}
            openLoginModal={openLoginModal}
            setOpenLoginModal={setOpenLoginModal}
            targetRoute={targetRoute}
            setTargetRoute={setTargetRoute}
          />
        )}
      </div>
      <div className='flex justify-center relative text-white h-44 lg:h-96'>
        <Image
          src={restaurant.profilePicUrl || fallbackProfilePicUrl}
          alt='image of restaurant'
          className='w-full object-cover h-44 lg:h-96'
          containerClassName='w-full'
          placeholder={<></>}
        />
        <div className='absolute inset-0 w-full h-full z-0 opacity-48 bg-gradient-to-r from-black left-0' />
        <div className='absolute inset-0 w-full p-4 md:px-6 lg:py-8 max-w-6xl mx-auto flex flex-col justify-end'>
          <h3 className='type-headline-3'>
            {restaurant.name}
            {restaurant.city && restaurant.state && (
              <span className='block type-headline-5'>
                {`${restaurant.city}, ${restaurant.state}`}
              </span>
            )}
          </h3>
          <p className='mt-0.5'>
            {restaurant.primaryCuisine}{' '}
            {restaurant.secondaryCuisine ? (
              <>&bull; {restaurant.secondaryCuisine}</>
            ) : (
              ''
            )}{' '}
          </p>
        </div>
      </div>
      <div className='max-w-6xl mx-auto p-4 md:px-6'>{children}</div>
    </>
  )
}

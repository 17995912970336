import { useQuery } from 'react-query'
import { getWaitlistPreview } from './getWaitlistPreview'

export const useGetWaitlistEstimateForBooking = (
  bookingGuid: string,
  restaurantGuid: string,
  partySize: number
) =>
  useQuery(['estimate', bookingGuid, restaurantGuid, partySize], () =>
    getWaitlistPreview({ restaurantGuid, bookingGuid, partySize })
  )

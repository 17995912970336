export enum DepositStrategyType {
  ByPartySize = 'ByPartySize',
  ByBooking = 'ByBooking'
}

export type ByBooking = {
  type: DepositStrategyType.ByBooking
  actualAmount: number
  amountsByParty: number[]
  defaultAmount: number
}

export type ByPartySize = {
  type: DepositStrategyType.ByPartySize
  actualAmount: number
  amountPerGuest: number
  minPartySize: number
  maxPartySize: number
}

export type DepositStrategy = ByBooking | ByPartySize

export enum DepositStatus {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  WAIVED = 'WAIVED',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED',
  NOT_REFUNDED = 'NOT_REFUNDED'
}

export type DepositBookableConfigSnapshot = {
  guid: string
  depositPolicy: string | null
  cancellationRefundableTimeframe: number
  strategy: {
    type: DepositStrategyType
    amount: number
  }
} | null

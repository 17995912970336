import React from 'react'
import {
  LocationIcon,
  PhoneIcon,
  InternetIcon
} from '@toasttab/buffet-pui-icons'
import cn from 'classnames'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'

import { getDomainFromUrl } from '../../utils/siteFormat'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Button } from '@toasttab/buffet-pui-buttons'

interface RestaurantLocationProps {
  site: string
  phone: string
  className?: string | undefined
  address: string
}

export const getValidUrl = (trimmedSite: string) =>
  trimmedSite.startsWith('http') ? trimmedSite : `//${trimmedSite}`

export function RestaurantLocation({
  address,
  site,
  phone,
  className
}: RestaurantLocationProps) {
  const parsedSite = site ? getDomainFromUrl(site) : ''
  const trimmedSite = site.trim()

  return (
    <div className='md:border-l md:pl-8'>
      <div className='md:hidden visible'>
        <DividingLine />
      </div>

      <div className={cn('flex flex-col space-y-4', className)}>
        <div>
          <p className='type-large font-semibold mb-2'>Address</p>
          <div className='flex space-x-2'>
            <LocationIcon
              className='text-secondary'
              accessibility='decorative'
            />
            <div className='flex flex-col items-start'>
              <p> {address}</p>

              <Button
                as='a'
                href={`https://www.google.com/maps?q=${address}`}
                target='_blank'
                variant='text-link'
                className='-mt-2'
              >
                View map
              </Button>
            </div>
          </div>
        </div>
        {phone ? (
          <div>
            <p className='type-large font-semibold mb-2'>Phone number</p>
            <div className='flex space-x-2'>
              <PhoneIcon
                className='text-secondary'
                accessibility='decorative'
              />
              <div className='flex flex-col items-start'>
                <p>{phoneNumberFormatter(phone)}</p>
                <Button
                  as='a'
                  href={`tel:${phone}`}
                  variant='text-link'
                  className='-mt-2'
                >
                  Call restaurant
                </Button>
              </div>
            </div>
          </div>
        ) : null}
        {trimmedSite && (
          <div>
            <p className='type-large font-semibold mb-2'>Website</p>
            <div className='flex space-x-2'>
              <InternetIcon
                className='text-secondary'
                accessibility='decorative'
              />
              <Button
                as='a'
                variant='text-link'
                target='_blank'
                className='-mt-2 flex flex-col'
                href={getValidUrl(trimmedSite)}
              >
                {parsedSite}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

RestaurantLocation.defaultProps = {
  className: undefined
}

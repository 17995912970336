import React from 'react'

export const Menu = () => (
  <svg
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.04999 7.40002C4.63577 7.40002 4.29999 7.73581 4.29999 8.15002C4.29999 8.56424 4.63577 8.90002 5.04999 8.90002V7.40002ZM15.95 8.90002C16.3642 8.90002 16.7 8.56424 16.7 8.15002C16.7 7.73581 16.3642 7.40002 15.95 7.40002V8.90002ZM8.45001 3.69995C8.0358 3.69995 7.70001 4.03574 7.70001 4.44995C7.70001 4.86416 8.0358 5.19995 8.45001 5.19995V3.69995ZM15.95 5.19995C16.3642 5.19995 16.7 4.86416 16.7 4.44995C16.7 4.03574 16.3642 3.69995 15.95 3.69995V5.19995ZM5.04999 11.1C4.63577 11.1 4.29999 11.4358 4.29999 11.85C4.29999 12.2642 4.63577 12.6 5.04999 12.6V11.1ZM15.95 12.6C16.3642 12.6 16.7 12.2642 16.7 11.85C16.7 11.4358 16.3642 11.1 15.95 11.1V12.6ZM5.04999 14.8C4.63577 14.8 4.29999 15.1358 4.29999 15.55C4.29999 15.9643 4.63577 16.3 5.04999 16.3V14.8ZM12.55 16.3C12.9642 16.3 13.3 15.9643 13.3 15.55C13.3 15.1358 12.9642 14.8 12.55 14.8V16.3ZM5.04999 8.90002H15.95V7.40002H5.04999V8.90002ZM8.45001 5.19995H15.95V3.69995H8.45001V5.19995ZM5.04999 12.6H15.95V11.1H5.04999V12.6ZM5.04999 16.3H12.55V14.8H5.04999V16.3ZM17.55 0.300049H3.54999V1.80005H17.55V0.300049ZM3.54999 0.300049C2.03577 0.300049 0.799988 1.53584 0.799988 3.05005H2.29999C2.29999 2.36426 2.8642 1.80005 3.54999 1.80005V0.300049ZM0.799988 3.05005V17.05H2.29999V3.05005H0.799988ZM0.799988 17.05C0.799988 18.5643 2.03577 19.8 3.54999 19.8V18.3C2.8642 18.3 2.29999 17.7358 2.29999 17.05H0.799988ZM3.54999 19.8H17.55V18.3H3.54999V19.8ZM17.55 19.8C19.0642 19.8 20.3 18.5643 20.3 17.05H18.8C18.8 17.7358 18.2358 18.3 17.55 18.3V19.8ZM20.3 17.05V3.05005H18.8V17.05H20.3ZM20.3 3.05005C20.3 1.41125 19.0392 0.300049 17.55 0.300049V1.80005C18.2608 1.80005 18.8 2.28885 18.8 3.05005H20.3Z'
      fill='white'
    />
  </svg>
)

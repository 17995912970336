import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

type BookingCreationLegalTextProps = {
  isAuthenticated: boolean
  buttonText: string
  restaurant: RestaurantInfo
}

export const BookingCreationLegalText = ({
  isAuthenticated,
  buttonText,
  restaurant
}: BookingCreationLegalTextProps) => {
  const isCalifornia = restaurant.country === 'US' && restaurant.state === 'CA'
  return (
    <>
      {isAuthenticated ? (
        <>
          <p>
            By clicking “{buttonText}”, you will receive emails and automated
            texts from Toast and the restaurant with updates and reminders about
            your booking. Msg frequency varies. Msg & data rates may apply. Text
            “STOP” to opt out at any time. You may also receive marketing
            messages from Toast and restaurants you visit, unless you
            unsubscribe.
          </p>
        </>
      ) : (
        <>
          <p>
            By clicking “{buttonText}”, you agree to Toast&apos;s{' '}
            <a
              className='text-link hover:text-link-hover'
              target='_blank'
              rel='noreferrer'
              href='https://pos.toasttab.com/terms-of-service#diner-tos'
            >
              Guest Terms of Service
            </a>{' '}
            and{' '}
            <a
              className='text-link hover:text-link-hover'
              target='_blank'
              rel='noreferrer'
              href='https://pos.toasttab.com/privacy'
            >
              Privacy Statement
            </a>
            .
            {isCalifornia && (
              <>
                {' '}
                Additional info for California residents available{' '}
                <a
                  className='text-link hover:text-link-hover'
                  target='_blank'
                  rel='noreferrer'
                  href='https://pos.toasttab.com/privacy#addendum-a'
                >
                  here
                </a>
                .
              </>
            )}
          </p>
        </>
      )}
    </>
  )
}

import { PathParams } from '../baseModels'

export const substituteParams = (
  url: string,
  pathParams: PathParams
): string => {
  let newUrl = url

  Object.keys(pathParams).forEach((k) => {
    newUrl = newUrl.replace(`{${k}}`, pathParams[k])
  })

  return newUrl
}

import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import { CalendarTodayIcon, ScheduleIcon } from '@toasttab/buffet-pui-icons'
import { useIntlProps } from 'banquet-runtime-modules'
import React from 'react'
import { Reservation } from '../../api/bookings/getBooking'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFindReserveContext } from '../../contexts/find-reserve-context'

interface ReservationDetailParams {
  restaurant: RestaurantInfo
  booking?: Reservation
}

export const ReservationDetails = ({
  restaurant,
  booking
}: ReservationDetailParams) => {
  const { selectedAvailability: contextualAvailability } =
    useFindReserveContext()
  const selectedAvailability = booking || contextualAvailability
  const { language: locale } = useIntlProps()

  return (
    <div className='flex flex-col gap-2'>
      <h3 className='type-large font-semibold'>{restaurant.name}</h3>
      <p className='font-semibold'>
        Table for {selectedAvailability?.partySize} in{' '}
        {booking?.serviceAreaGroup.name ||
          contextualAvailability?.serviceAreaGroupName}
        .
      </p>
      <div className='flex flex-col gap-2'>
        <div className='flex'>
          <CalendarTodayIcon size='sm' className='mr-1' />
          {format(
            new Date(
              booking?.expectedStartTime || contextualAvailability!.datetime
            ),
            Formats.date.long,
            { locale: locale, timeZone: restaurant.timezone }
          )}{' '}
        </div>
        <div className='flex'>
          <ScheduleIcon size='sm' className='mr-1' />
          {format(
            new Date(
              booking?.expectedStartTime || contextualAvailability!.datetime
            ),
            Formats.time.short,
            { locale: locale, timeZone: restaurant.timezone }
          )}
        </div>
      </div>
    </div>
  )
}

import React, { createContext, useContext, useMemo } from 'react'
import { useFetchRestaurantV2 } from '../components/CreditCardForm/api/useFetchRestaurantV2'
import { useGetRestaurant } from '../api/restaurant/useGetRestaurant'

type RestaurantContextValue = {
  shortUrl: string
}

export const RestaurantContext = createContext<RestaurantContextValue>({
  shortUrl: ''
})

export const useRestaurantContext = () => useContext(RestaurantContext)

export const RestaurantProvider = ({
  children,
  shortUrl
}: {
  children: React.ReactNode
  shortUrl?: string
}) => {
  const { restaurantGuid } = window.TT_GLOBALS
  const { data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })
  const { data } = useFetchRestaurantV2(restaurantGuid)

  const contextValue = useMemo(() => {
    const shortUrlValue =
      shortUrl || data?.shortUrl || restaurant?.toastRestaurantShortName || ''
    return {
      shortUrl: shortUrlValue
    }
  }, [shortUrl, data?.shortUrl, restaurant?.toastRestaurantShortName])

  return (
    <RestaurantContext.Provider value={contextValue}>
      {children}
    </RestaurantContext.Provider>
  )
}

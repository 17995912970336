import React from 'react'
import { BookingType } from '../../api/baseModels'
import { BookingStatus, Reservation } from '../../api/bookings/getBooking'
import { Phone as PhoneIcon } from '../../assets/icons/Phone'
import { Info as InfoIcon } from '../../assets/icons/Info'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useGetWaitlistEstimateForBooking } from '../../api/restaurant/useGetWaitlistEstimateForBooking'
import { getWaitTimeRange } from '../WaitEstimator/WaitEstimator'
import { formatArrayIntoSentence } from '../../utils/formatArrayIntoSentence'
import { parsePhoneNumber } from 'awesome-phonenumber'

export interface WaitlistSubheaderProps {
  booking: Reservation
  restaurant: RestaurantInfo
  isMobile: boolean
}

function getReservationSubheaderMessage(subMessage?: string | React.ReactNode) {
  return <p className='type-default text-secondary text-center'>{subMessage}</p>
}

const WaitlistSubheaderMessage = ({
  booking,
  restaurant,
  isMobile
}: WaitlistSubheaderProps) => {
  const { data } = useGetWaitlistEstimateForBooking(
    booking.guid,
    restaurant.guid,
    booking.partySize
  )

  const parsedPhoneNumber = parsePhoneNumber(
    booking.mainGuest.phoneNumber ?? ''
  )

  switch (booking.bookingStatus) {
    case BookingStatus.W_WAITING:
    case BookingStatus.W_ARRIVED:
    case BookingStatus.W_CONFIRMED:
    case BookingStatus.W_CHECKED_IN:
    case BookingStatus.W_PARTIALLY_ARRIVED:
    case BookingStatus.W_LATE_ARRIVAL: {
      const allLines = data?.partiesAheadByArea.filter(({ serviceAreaGroup }) =>
        booking.requestedServiceAreaGroups
          .map((sag) => sag.guid)
          .includes(serviceAreaGroup)
      )
      const partiesAhead = allLines
        ? Math.min(...allLines.map((line) => line.partiesAhead))
        : 0

      const minServiceAreaGroup = allLines
        ? allLines.sort((area1, area2) => {
            if (area1.estimatedLowerBound === null) return 1
            if (area2.estimatedLowerBound === null) return -1
            return area1.estimatedLowerBound - area2.estimatedLowerBound
          })[0]
        : null

      const estimatedLowerBound = minServiceAreaGroup
        ? minServiceAreaGroup.estimatedLowerBound
        : 0
      const estimatedUpperBound = minServiceAreaGroup
        ? minServiceAreaGroup.estimatedUpperBound
        : 0
      return (
        <div>
          <div className='type-default text-black text-center'>
            Parties ahead of you:{' '}
            <span className='font-semibold'> {partiesAhead}</span>
          </div>
          <p className='type-default text-black text-center'>
            Dining area:{' '}
            <span className='font-semibold'>
              {booking.requestedServiceAreaGroups.length === 0 ||
              (booking.requestedServiceAreaGroups.length !== 1 &&
                booking.requestedServiceAreaGroups.length ===
                  restaurant.serviceAreaGroups.length)
                ? 'Any'
                : formatArrayIntoSentence(
                    booking.requestedServiceAreaGroups.map((sag) => sag.name)
                  )}
            </span>
          </p>
          {restaurant.showWaitlistEstimate && (
            <p className='type-default text-black text-center'>
              Estimated wait:{' '}
              <span className='font-semibold'>
                {getWaitTimeRange(estimatedLowerBound, estimatedUpperBound)}
              </span>
            </p>
          )}
          <p className='type-default text-black text-center'>
            Total parties waiting:{' '}
            <span className='font-semibold'> {data?.totalPartiesWaiting}</span>
          </p>
          <div className='type-default text-secondary type-subhead inline-flex gap-2 align-top mt-2 relative sm leading-relaxed items-center'>
            {isMobile ? <PhoneIcon width={32} height={28} /> : <PhoneIcon />}
            We will send an SMS{' '}
            {booking.mainGuest.phoneNumber
              ? `to ${parsedPhoneNumber.number?.international}`
              : ''}{' '}
            when your table is ready.
          </div>
          <div className='mr-1 ml-1'>
            <div className='p-2 gap-x-8 rounded mt-2 bg-gray-25'>
              <div className='type-caption text-secondary flex gap-2 items-center'>
                {isMobile ? (
                  <InfoIcon width={20} height={24} />
                ) : (
                  <InfoIcon height={24} />
                )}
                Based on table seating availability, smaller parties may be
                seated before you
              </div>
            </div>
          </div>
        </div>
      )
    }
    case BookingStatus.W_NOTIFIED:
      return (
        <div>
          <p className='type-default text-black text-center'>
            Please see the host.
          </p>
          <p className='type-subhead type-default text-black text-center mb-2'>
            {restaurant.waitlistPolicy}
          </p>
        </div>
      )
    case BookingStatus.W_SEATED:
      return (
        <div>
          <p className='type-default text-black text-center mb-4'>
            You are now seated.
          </p>
        </div>
      )
    case BookingStatus.W_DONE:
      return (
        <div>
          <p className='type-default text-black text-center mb-4'>
            {restaurant.loyaltyEnabled &&
              'Join our loyalty program and get rewarded for dining with us in the future.'}
          </p>
        </div>
      )
    default:
      return <></>
  }
}

export interface SubheaderMessageProps {
  isMobile: boolean
  booking?: Reservation
  restaurant?: RestaurantInfo
  subMessage?: string | React.ReactNode
}

export function SubheaderMessage({
  isMobile,
  booking,
  restaurant,
  subMessage
}: SubheaderMessageProps) {
  if (!booking) {
    return null
  }

  if (!restaurant) {
    return null
  }

  if (booking.bookingType === BookingType.RESERVATION) {
    return getReservationSubheaderMessage(subMessage)
  }
  // else if bookingType === WAITLIST
  return WaitlistSubheaderMessage({
    booking,
    restaurant,
    isMobile
  })
}

import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'

export interface CreateWaitlistParams {
  partySize: string
  bookingNotes?: string
  specialOccasion?: string
  requestedServiceAreaGroups: string[]
  guest: Guest
}

export interface Guest {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string // internationalized
}

export interface CreateWaitlistResponse {
  guid: string
}

export const createWaitlist = async (
  restaurantGuid: string,
  params: CreateWaitlistParams
) =>
  axios
    .request<NvResponse<CreateWaitlistResponse>>(
      createG2Request(
        RequestURL[RequestName.CREATE_WAITLIST],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        params
      )
    )
    .then((response) => response.data)

import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetBooking } from '../../api/bookings/useGetBooking'
import { LoadingView } from '../LoadingView/LoadingView'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { ReservationConfirmationView } from './ReservationConfirmationView'
import { Frame } from '../Frame/Frame'

export function ReservationConfirmationContainer() {
  const { bookingGuid } = useParams()
  const { restaurantGuid } = window.TT_GLOBALS
  const { data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })
  const { data: booking } = useGetBooking(bookingGuid || '')

  if (!booking || !restaurant) {
    return <LoadingView />
  }

  return (
    <Frame restaurant={restaurant}>
      <ReservationConfirmationView restaurant={restaurant} booking={booking} />
    </Frame>
  )
}

import { useMutation } from 'react-query'
import { cancelAuthReservation, cancelBooking } from './cancelBooking'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'

export const useCancelBooking = (isAuthenticated: boolean) => {
  const { getAuthenticationHeader } = useAuth()
  const { restaurantGuid } = window.TT_GLOBALS
  return useMutation(({ bookingId }: { bookingId: string }) =>
    isAuthenticated
      ? cancelAuthReservation(
          getAuthenticationHeader,
          bookingId,
          restaurantGuid
        )
      : cancelBooking(bookingId, restaurantGuid)
  )
}

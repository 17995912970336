import { GuestOverviewReservation } from '../../api/guest/useGetAllBookingsForGuestProfile'
import { useFetchRestaurantV2 } from '../CreditCardForm/api/useFetchRestaurantV2'
import { Image } from '@toasttab/buffet-pui-image'
import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import { DateTime } from 'luxon'
import React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'

export const BookingCard = ({
  booking
}: {
  booking: GuestOverviewReservation
}) => {
  const { data: fetchRestaurantV2Data, isLoading: isFetchRestaurantV2Loading } =
    useFetchRestaurantV2(booking.restaurantGuid)
  const { data: restaurant, isLoading: isGetRestaurantLoading } =
    useGetRestaurant(booking.restaurantGuid, {
      slotTime: null
    })
  const fallbackProfilePicUrl =
    'https://storage.googleapis.com/nv1-booking-images/Toast Placeholder Image.png'

  const getLogoUrl = () => {
    let url = fetchRestaurantV2Data?.logoUrls?.xs
    if (url) {
      url = url.replace('dks3o9tl87y4h', 'd2s742iet3d3t1')
      return url
    }
  }

  return (
    <>
      <div className='flex flex-row px-4 py-2 items-center'>
        <Image
          src={getLogoUrl() || fallbackProfilePicUrl}
          alt=''
          fit='contain'
          className='rounded-[12px] max-h-16 max-w-16 border-default'
          containerClassName='w-16 h-16'
          placeholder={''}
        />
        <div className='py-4 ml-4'>
          {isFetchRestaurantV2Loading && <Skeleton className='h-[30px]' />}
          <h3 className='text-md font-semibold mb-1'>{restaurant?.name}</h3>
          {booking.requestedServiceAreaGroups[0] && (
            <p className='text-secondary type-subhead mb-1'>
              Reserved for {booking.requestedServiceAreaGroups[0].name}
            </p>
          )}
          <div className='flex flex-row text-secondary type-subhead'>
            <span className='flex gap-1 mr-4 items-center'>
              <GuestIcon accessibility='decorative' size='xs' />
              {booking.partySize}
            </span>
            <span className='flex gap-1 items-center'>
              <CalendarTodayIcon accessibility='decorative' size='xs' />
              {isGetRestaurantLoading && <Skeleton className='h-[30px]' />}
              {restaurant && (
                <>
                  {DateTime.fromISO(booking.expectedStartTime)
                    .setZone(restaurant.timezone)
                    .toLocaleString({
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    })}{' '}
                  @{' '}
                  {DateTime.fromISO(booking.expectedStartTime)
                    .setZone(restaurant.timezone)
                    .toFormat('h:mm a')}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

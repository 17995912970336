import { useQuery } from 'react-query'
import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { DateTime } from 'luxon'

interface GetDayInfoParams {
  businessDate: string
}

interface DayInfo {
  onlineWaitlistBlocked: boolean
  onlineReservationsBlocked: boolean
  blockedReason: string
  specialDateName: string
  specialDateDescription: string
  scheduleName: string
  scheduleDescription: {
    name: string | null
    description: string | null
  } | null
}

const getDayInfo = (restaurantGuid: string, params: GetDayInfoParams) =>
  axios
    .request<NvResponse<DayInfo>>(
      createG2Request<GetDayInfoParams>(
        RequestURL[RequestName.GET_DAY_INFO],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { businessDate: params.businessDate }
      )
    )
    .then((response) => response.data.results[0])

export const useGetDayInfo = (restaurantGuid: string, date: DateTime) => {
  const businessDate = date.toFormat('yyyy-MM-dd')

  return useQuery(
    ['dayInfo', restaurantGuid, businessDate],
    () => getDayInfo(restaurantGuid, { businessDate: businessDate }),
    { enabled: date.isValid }
  )
}

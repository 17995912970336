import React from 'react'

export const Restaurant = () => (
  <svg
    width='281'
    height='120'
    viewBox='0 0 281 120'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2003_46839)'>
      <path
        d='M64.4024 80.4878H38.0609H40.9146L51.8902 118.171L46.7683 100.829H34.1097L28.4756 118.463L40.9146 80.4878H61.4024L73.6219 117.073'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M187.329 95.1219H181.476'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M216.598 48.8781C216.598 48.8781 210.744 60 210.744 74.6341'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M222.451 74.6342V118.537'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M245.866 95.1219H193.183'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M207.817 95.1219L222.451 103.902'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M237.085 95.1219L222.451 103.902'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M248.793 74.6342C248.793 58.2439 237.012 48.2927 222.451 48.2927C207.89 48.2927 196.11 58.2439 196.11 74.6342H248.793Z'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M225.598 48.8781C225.598 48.8781 231.232 62.9268 231.232 74.6341'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M178.549 22.0975V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M163.915 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M149.281 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M134.646 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M120.012 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M105.378 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M90.7439 21.9512V54.1463'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M76.1097 48.2927H193.183'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M193.183 52.6829C193.183 56.7073 189.89 59.9999 185.866 59.9999C181.841 59.9999 178.549 56.7073 178.549 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M178.549 52.6829C178.549 56.7073 175.256 59.9999 171.232 59.9999C167.207 59.9999 163.915 56.7073 163.915 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M163.915 52.6829C163.915 56.7073 160.622 59.9999 156.598 59.9999C152.573 59.9999 149.281 56.7073 149.281 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M149.281 52.6829C149.281 56.7073 145.988 59.9999 141.963 59.9999C137.939 59.9999 134.646 56.7073 134.646 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M134.646 52.6829C134.646 56.7073 131.354 59.9999 127.329 59.9999C123.305 59.9999 120.012 56.7073 120.012 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M120.012 52.6829C120.012 56.7073 116.72 59.9999 112.695 59.9999C108.671 59.9999 105.378 56.7073 105.378 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M105.378 52.6829C105.378 56.7073 102.085 59.9999 98.061 59.9999C94.0366 59.9999 90.7439 56.7073 90.7439 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M90.7439 52.6829C90.7439 56.7073 87.4512 59.9999 83.4268 59.9999C79.4024 59.9999 76.1097 56.7073 76.1097 52.6829'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M76.1097 21.9512H193.183'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M76.1097 118.537V13.1708H193.183V118.537'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M163.915 21.9512V1.46338H193.183V21.9512'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M155.134 86.3414H96.5975V92.1951H155.134V86.3414Z'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M102.451 92.1951V103.902'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M105.378 86.3415V74.6342H155.134'
        stroke='#E5E5E5'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M90.7439 68.7805V103.902'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M111.232 68.7805C111.232 72 108.598 74.6342 105.378 74.6342C102.159 74.6342 99.5244 72 99.5244 68.7805'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M143.427 68.7805C143.427 72 140.793 74.6342 137.573 74.6342C134.354 74.6342 131.719 72 131.719 68.7805'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M155.134 68.7805H84.8903V103.902H155.134V68.7805Z'
        stroke='#C0C0C0'
        strokeWidth='2.80976'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M280.988 118.537H187.329V68.7805H160.988V118.537H0.012207'
        stroke='url(#paint0_linear_2003_46839)'
        strokeWidth='2.80976'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_2003_46839'
        x1='18.0478'
        y1='68.7805'
        x2='322.377'
        y2='191.867'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4C00' />
        <stop offset='1' stopColor='#2B4FB9' />
      </linearGradient>
      <clipPath id='clip0_2003_46839'>
        <rect
          width='280.976'
          height='120'
          fill='white'
          transform='translate(0.012207)'
        />
      </clipPath>
    </defs>
  </svg>
)

import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'

export interface GetWaitlistPreviewParams {
  restaurantGuid: string
  partySize: number
  bookingGuid: string | null
}

export interface WaitlistPlaceInLine {
  totalPartiesAhead: number
  totalPartiesWaiting: number
  partiesAheadByArea: WaitlistByServiceAreaGroup[]
}

export interface WaitlistByServiceAreaGroup {
  partiesAhead: number
  serviceAreaGroup: string
  minutes: number | null
  estimatedLowerBound: number | null
  estimatedUpperBound: number | null
}

export interface WaitlistPreview {
  minutes: number | null
  partiesAhead: number
}

export const getWaitlistPreview = async ({
  restaurantGuid,
  partySize,
  bookingGuid
}: GetWaitlistPreviewParams) =>
  axios
    .request<NvResponse<WaitlistPlaceInLine>>(
      createG2Request(
        RequestURL[RequestName.GET_WAITLIST_PREVIEW_AND_ESTIMATE],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        { bookingGuid, partySize }
      )
    )
    .then((response) => response.data.results[0])

import React from 'react'
import { TimeNotFound } from '../../assets/icons/TimeNotFound'

export const ReservationError = ({
  children
}: {
  children: React.ReactNode | string
}) => {
  return (
    <div className='w-full flex justify-center flex-col'>
      <div className='mt-2 mb-3 flex justify-center'>
        <TimeNotFound />
      </div>
      <div className='w-full px-4 flex flex-col items-center mb-3'>
        <p className='type-large'>Oh no!</p>
        <p className='type-default text-secondary'>{children}</p>
      </div>
    </div>
  )
}

import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import {
  OnlineReservationHoursInterval,
  getOnlineReservationHours
} from './getOnlineReservationHours'
import { DateTime } from 'luxon'

export const OnlineReservationHoursContext = createContext({
  getOnlineReservationHours
})
export const useGetOnlineReservationHoursContext = () =>
  useContext(OnlineReservationHoursContext)

type Options = {
  onSuccess?: (data: OnlineReservationHoursInterval[]) => void
  enabled?: boolean
}

export const useGetOnlineReservationHours = (
  restaurantGuid: string,
  date: DateTime,
  options?: Options
) => {
  const selectedDate = date.toISODate()

  return useQuery(
    ['onlineReservationHours', restaurantGuid, selectedDate],
    () => getOnlineReservationHours(restaurantGuid, selectedDate),
    {
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      },
      refetchOnWindowFocus: false,
      enabled: options?.enabled
    }
  )
}

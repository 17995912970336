/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { ReservationTimeSelectionViewForm } from '../ReservationTimeSelectionView/ReservationTimeSelectionViewForm/ReservationTimeSelectionViewForm'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { FormikProvider, useFormik } from 'formik'
import {
  ReservationTimeSelectionViewFormValues,
  getInitialFormValues
} from '../ReservationTimeSelectionView/models'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams'
import { useQueryParam } from '../../hooks/useQueryParam'
import { DateTime } from 'luxon'
import { useReservationInfo } from '../../hooks/useReservationInfo'

type UpdateSearchModalProps = {
  onRequestClose: () => void
  restaurant: RestaurantInfo
}

export const UpdateSearchModal = ({
  onRequestClose,
  restaurant
}: UpdateSearchModalProps) => {
  const params = useQueryParam()
  const updateQueryParams = useUpdateQueryParams()

  const partySize = Number(params.get('partySize'))
  const date = DateTime.fromISO(params.get('dateTime') || '')
    .startOf('day')
    .toISO()
  const time = params.get('dateTime') || ''

  const handleClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.setSubmitting(false)
    onRequestClose()
  }

  const initialValues = getInitialFormValues(
    {
      partySize,
      date: DateTime.fromISO(date),
      time
    },
    restaurant
  )
  const onSubmit = ({
    partySize,
    time
  }: ReservationTimeSelectionViewFormValues) => {
    updateQueryParams({
      partySize: partySize.toString(),
      dateTime: encodeURIComponent(time)
    })
    handleClose()
  }
  const formik = useFormik({
    initialValues,
    onSubmit
  })

  const { isAfterHours, isBlocked, isRestaurantClosed } = useReservationInfo(
    restaurant,
    formik.values.date
  )

  const isButtonDisabled = isAfterHours || isBlocked || isRestaurantClosed

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen
        parentSelector={() =>
          document.getElementById('banquetPortalsContainer')
        }
        overflowBehavior='body'
        onRequestClose={handleClose}
        size='xxl'
      >
        <Modal.Header>Update search</Modal.Header>
        <Modal.Body className='flex flex-col gap-4 items-center'>
          <ReservationTimeSelectionViewForm
            restaurant={restaurant}
            showSubmitButton={false}
            showCalendar
          />
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            form='ReservationTimeSelectionViewForm'
            size='base'
            className='w-full'
            disabled={isButtonDisabled}
          >
            Update
          </SubmitButton>
        </Modal.Footer>
      </Modal>
    </FormikProvider>
  )
}

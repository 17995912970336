import React from 'react'
import { payDeposit } from './utilities/api'
import { exceptionLog } from './logging'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useCreditCardForm } from './CreditCardFormProvider'
import { useFetchRestaurantV2 } from './api/useFetchRestaurantV2'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

function getIframeSrc(shortUrl: string, amexAccepted: boolean) {
  const hostname = ['tables.toasttab.com', 'booking.toasttab.com'].includes(
    window.location.hostname
  )
    ? 'www.toasttab.com'
    : 'preprod.eng.toasttab.com'
  const iframeSrc = `https://${hostname}/${shortUrl}/v3/ccform2`
  const iframeUrl = new URL(`${iframeSrc}?color=%23183da3`)

  if (amexAccepted) {
    iframeUrl.searchParams.set('amexAccepted', 'true')
  }

  return iframeUrl.toString()
}

export default function CreditCardForm(props: {
  email: string
  restaurant: RestaurantInfo
  onSuccess: () => void
  checkGuid: string
  orderGuid: string
}) {
  const { email, restaurant, onSuccess, checkGuid, orderGuid } = props
  const [height, setHeight] = React.useState(218)
  const { shortUrl } = useRestaurantContext()

  const { ccData, setCcData, setPaymentLoading, setPaymentError } =
    useCreditCardForm()

  const { data: fetchRestaurantV2Data } = useFetchRestaurantV2(restaurant.guid)

  const receiveMessage = React.useCallback(
    (event: any) => {
      if (event.data) {
        let data =
          typeof event.data === 'string'
            ? JSON.parse(event.data)
            : JSON.stringify(event.data)
        if (data && (data.name === 'CC_VALID' || data.name === 'CC_INVALID')) {
          setCcData(data.data)
        } else if (data && data.name === 'IFRAME_HEIGHT') {
          setHeight(data.data)
        }
      }
    },
    [setCcData]
  )

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage, false)

    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setPaymentLoading(true)
    setPaymentError(null)
    payDeposit(restaurant.guid, email, checkGuid, orderGuid, ccData)
      .then(() => {
        onSuccess()
        setPaymentLoading(false)
      })
      .catch((err) => {
        if ('message' in err) {
          exceptionLog(Error(err.message))
          setPaymentError(err.message)
        } else {
          setPaymentError('Something went wrong')
        }
        setPaymentLoading(false)
      })
  }

  return (
    <form id='paymentForm' onSubmit={handleSubmit}>
      <iframe
        className='w-full'
        src={getIframeSrc(
          shortUrl,
          fetchRestaurantV2Data?.creditCardConfig?.amexAccepted
        )}
        height={`${height}px`}
        title='check-credit-card-input'
        sandbox='allow-forms allow-scripts allow-same-origin'
      />
    </form>
  )
}

export function formatArrayIntoSentence(
  items: string[],
  conjunction = 'or'
): string {
  const length = items.length

  if (length === 0) {
    return ''
  }

  if (length === 1) {
    return items[0]
  }

  if (length === 2) {
    return `${items[0]} ${conjunction} ${items[1]}`
  }

  const lastItem = items[length - 1]
  const remainingItems = items.slice(0, length - 1)
  const formattedItems = remainingItems.join(', ')

  return `${formattedItems} ${conjunction} ${lastItem}`
}

/* eslint-disable camelcase */
import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../../baseModels'
import { createG2Request } from '../../createRequest'

export interface OnlineReservationHoursInterval {
  start: string
  end: string
  isOverride: boolean
}

export interface OnlineReservationHoursParams {
  selectedDate: string
}

export const getOnlineReservationHours = async (
  restaurantGuid: string,
  selectedDate: string
): Promise<OnlineReservationHoursInterval[]> =>
  axios
    .request<NvResponse<OnlineReservationHoursInterval>>(
      createG2Request<OnlineReservationHoursParams>(
        RequestURL[RequestName.GET_ONLINE_RESERVATION_HOURS],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { restaurantGuid },
        { selectedDate }
      )
    )
    .then((response) => response.data.results)

import * as Yup from 'yup'
import 'yup-phone'
import { phoneNumberValidationOverride } from '../../utils/phoneNumberCustomValidation'

const validationSchema = Yup.object({
  firstName: Yup.string().required(
    'A first name is required to book a reservation'
  ),
  lastName: Yup.string().required(
    'A last name is required to book a reservation'
  ),
  phoneNumber: phoneNumberValidationOverride,
  email: Yup.string()
    .email('Please enter a valid email or leave blank.')
    .required('An email address is required to book a reservation')
})

export default validationSchema

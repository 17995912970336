import React, { useEffect, useState } from 'react'
import { formatTime } from './utils'

type TimeLeft = {
  hours: number
  minutes: number
  seconds: number
}

type CountdownProps = {
  dateInUtcString: string
  restaurantGuid: string
  onExpiration?: () => void
}

export const Countdown = ({
  dateInUtcString,
  restaurantGuid,
  onExpiration
}: CountdownProps) => {
  const calculateTimeLeft = () => {
    const difference =
      new Date(dateInUtcString).getTime() - new Date().getTime()

    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    }
  }

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())
  const [isExpired, setIsExpired] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (
      !isExpired &&
      timeLeft.hours <= 0 &&
      timeLeft.minutes <= 0 &&
      timeLeft.seconds <= 0
    ) {
      onExpiration?.()
      setIsExpired(true)
    }
  }, [onExpiration, restaurantGuid, timeLeft, isExpired])

  const { hours, minutes, seconds } = timeLeft

  const showHours = hours !== 0

  return (
    <span className='text-error font-semibold'>
      {showHours ? `${formatTime(hours)}:` : undefined}
      {formatTime(minutes)}:{formatTime(seconds)}
    </span>
  )
}

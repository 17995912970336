import React, { createContext, ReactElement, useContext, useState } from 'react'
import { NvResponse } from '../baseModels'
import {
  createReservation,
  CreateReservationParams,
  CreateReservationResponse
} from './createReservation'
import {
  createWaitlist,
  CreateWaitlistParams,
  CreateWaitlistResponse
} from './createWaitlist'
import { getBooking, Reservation } from './getBooking'
import {
  UpdateReservationParams,
  UpdateReservationResponse,
  updateReservation
} from './updateReservation'
import {
  UpdateWaitlistParams,
  UpdateWaitlistResponse,
  updateWaitlist
} from './updateWaitlist'

export enum BookingStateStatus {
  IDLE = 'Idle',
  LOADING = 'Loading',
  LOADED = 'Loaded',
  ERROR = 'Error'
}

export interface BookingState {
  booking: Reservation | null
  status: BookingStateStatus
}

export interface BookingContextState {
  createReservation: (
    restaurantGuid: string,
    params: CreateReservationParams
  ) => Promise<NvResponse<CreateReservationResponse>>
  createWaitlist: (
    restaurantGuid: string,
    params: CreateWaitlistParams
  ) => Promise<NvResponse<CreateWaitlistResponse>>
  getBooking: (
    reservationGuid: string,
    restaurantGuid: string
  ) => Promise<Reservation>
  updateReservation: (
    params: UpdateReservationParams,
    bookingId: string
  ) => Promise<NvResponse<UpdateReservationResponse>>
  updateWaitlist: (
    params: UpdateWaitlistParams,
    bookingId: string
  ) => Promise<string | UpdateWaitlistResponse>
  setBooking: (reservation: Reservation | null) => void
  booking: Reservation | null
}

export const BookingContext = createContext<BookingContextState>({
  createReservation,
  createWaitlist,
  getBooking,
  updateReservation,
  updateWaitlist,
  setBooking: () => {},
  booking: null
})
export const useBookingContext = () => useContext(BookingContext)

export interface BookingProviderProps {
  children: ReactElement<any> | Array<ReactElement<any>>
}

export function BookingProvider({ children }: BookingProviderProps) {
  const [booking, setBooking] = useState<Reservation | null>(null)

  return (
    <BookingContext.Provider
      value={{
        createReservation,
        createWaitlist,
        getBooking,
        updateReservation,
        updateWaitlist,
        setBooking,
        booking
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}
